import { Fragment, useEffect, useState } from 'react';
import ThemeSwitcher from 'react-css-vars';
import * as themes from '../Themes';
import firebase from 'firebase/app';
import 'firebase/auth';
import { withSize } from 'react-sizeme';
import EssayLoadingState from '../LoadingState/EssayLoadingState';
import {
  ChatIcon,
  DotsVerticalIcon,
  EyeIcon,
  HeartIcon,
  ReplyIcon,
  SwitchHorizontalIcon,
} from '@heroicons/react/solid';
import { Menu, Transition } from '@headlessui/react';
import TwitterBirdIcon from '../Icons/twitter';
import MediumLogo from '../Icons/medium';
import LinkedInLogo from '../Icons/linkedin';

var twitter = require('twitter-text');

const getDate = (date) => {
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  const newDate = new Date(date).toLocaleDateString(undefined, options);
  return newDate;
};

function EssayCard({
  typeshare = null,
  isScheduled = false,
  click = null,
  featured = false,
  hideIcons = false,
  preview = false,
  type = null,
  socialBlog,
  creatorSlug,
  creatorID,
  userMatch = false,
  id = null,
  edit = false,
  border = false,
}) {
  const [ts, setTS] = useState([]);
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState(null);
  const [blogMeta, setBlogMeta] = useState({});

  useEffect(() => {
    if (typeshare) {
      setTS(typeshare);
      getMeta();
    } else {
      creatorSlug && creatorID && userMatch && id ? getTypeshare() : null;
    }
  }, [typeshare, id, creatorSlug, creatorID]);

  const getMeta = () => {
    firebase
      .database()
      .ref('gallery/' + creatorSlug + '/' + creatorID)
      .child('meta')
      .once('value')
      .then((snap) => {
        if (snap.exists()) {
          setBlogMeta(snap.val());
        } else {
          firebase
            .database()
            .ref('gallery/' + creatorSlug + '/' + creatorID)
            .once('value')
            .then((blogMetaRef2) => {
              if (blogMetaRef2.exists()) {
                const creatorMeta = {
                  twitterURL: blogMetaRef2.val.twitterURL,
                  displayName: blogMetaRef2.val.displayName,
                  image: blogMetaRef2.val.image,
                  bio: blogMetaRef2.val.bio,
                  slug: blogMetaRef2.val.slug,
                  coverImage: '',
                  verified: false,
                };
                setBlogMeta(creatorMeta);
              } else {
              }
            });
        }
      })
      .then(() => {
        getGlobalTheme();
      });
  };

  const getGlobalTheme = () => {
    firebase
      .database()
      .ref('gallery/' + creatorSlug + '/' + creatorID)
      .child('theme')
      .child('style')
      .once('value')
      .then((snap) => {
        if (snap.exists()) {
          //setTheme to null to use the essay specific styling (AKA the theme when the essay was published)
          setTheme(snap.val());
        } else {
          setTheme(null);
        }
      })
      .then(() => {
        setLoading(false);
      });
  };

  const getTypeshare = () => {
    firebase
      .database()
      .ref('gallery/' + creatorSlug + '/' + creatorID)
      .child('typeshares')
      .child(id)
      .once('value')
      .then((snap) => {
        setTS(snap);
        getMeta();
      });
  };

  /*     const gradColor = `from-${themes.colorTemplates[0].background.replace("bg-", "")}` */

  function getHourTime(date) {
    const newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const autoLinkTweet = (tweet) => {
    if (tweet) {
      var tweetElement = twitter.default.autoLink(tweet);

      var regexpAt = /@/g;
      var matchAt = regexpAt.exec(tweet);

      while (matchAt != null) {
        tweetElement = tweetElement.replaceAll(
          matchAt[0],
          `<a>${matchAt[0]}</a>`,
        );
        matchAt = regexpAt.exec(tweet);
      }

      return tweetElement;
    } else {
      return null;
    }
  };

  return loading ? (
    <EssayLoadingState />
  ) : (
    <button
      id={`${ts.id}`}
      onClick={click}
      style={{
        boxShadow: '0px 0px 34px -2px rgba(0, 0, 0, 0.025)',
        background:
          theme === null
            ? ts.val.style?.customTheme
              ? ts.val.style?.customTheme.background
              : null
            : theme.customTheme.default
            ? theme.customTheme.background
            : null,
      }}
      className={` ${
        preview === false
          ? ts.val.tweetArray
            ? 'flex'
            : ts.val.visible
            ? 'flex'
            : 'hidden'
          : 'flex'
      } ${
        theme !== null
          ? theme.customTheme.default
            ? null
            : themes.colorTemplates[theme.colorID - 1].background
          : ts.val.style?.customTheme
          ? null
          : ts.val.style
          ? themes.colorTemplates[0].background
          : themes.colorTemplates[0].background
      } ${
        socialBlog ? 'h-full min-h-full rounded-2xl' : 'rounded-2xl'
      }  relative w-full flex-col items-start justify-center ${
        preview === false
          ? 'transform duration-200 hover:scale-105 active:scale-100'
          : null
      } ${edit ? 'cursor-move' : 'cursor-pointer'} ${
        border && 'border-2 border-gray-100'
      } overflow-hidden font-sans`}
    >
      <div
        className={`${
          ts.val.imported && !socialBlog
            ? hideIcons
              ? 'hidden'
              : 'flex'
            : 'hidden'
        } absolute top-4 right-4 z-10 flex flex-row items-center justify-center space-x-2`}
      >
        <div
          style={{
            color:
              theme !== null
                ? theme.customTheme.default
                  ? theme.customTheme.author
                  : null
                : ts.val.style?.customTheme
                ? ts.val.style?.customTheme.title
                : null,
          }}
          className={`rounded-full text-gray-500 transition-all  duration-200 ${
            theme !== null
              ? theme.customTheme.default
                ? null
                : themes.colorTemplates[theme.colorID - 1].author
              : ts.val.style?.customTheme
              ? null
              : themes.colorTemplates[0].title
          }`}
        >
          <SwitchHorizontalIcon className="h-4 w-4" />
        </div>
      </div>

      <div
        className={`p-5 ${
          theme !== null
            ? themes.fontPairings[theme.fontID - 1].title
            : ts.val.style
            ? themes.fontPairings[1].title
            : themes.fontPairings[1].title
        } flex  h-full flex-col overflow-y-scroll`}
      >
        <div
          className={`${
            theme !== null
              ? theme.customTheme.default
                ? 'Custom'
                : themes.colorTemplates[theme.colorID - 1].name
              : ts.val.style?.customTheme
              ? 'Custom'
              : themes.colorTemplates[0].name
          } group flex flex-row items-center justify-start space-x-2`}
        >
          <img
            className={
              'h-8 w-8 transform rounded-full bg-gradient-to-br from-indigo-400 via-pink-500 to-orange-600 transition-all duration-200 group-active:scale-95'
            }
            src={blogMeta.image}
          />
          <div className="flex flex-col items-start justify-start space-y-0">
            <p className={`font-sans text-sm font-medium`}>
              {blogMeta.displayName}
            </p>
            <p className="font-sans text-xs font-medium opacity-50">
              {featured
                ? 'Most Recent Essay'
                : ts.val.publishedAt
                ? ts.val.publishedAt
                  ? getDate(ts.val.publishedAt?.unix)
                  : null
                : null}
            </p>
          </div>
        </div>

        <div
          className={`absolute left-1/2 top-1/2 z-10 flex w-4/5 -translate-x-1/2 -translate-y-1/2 transform items-center justify-center rounded-xl bg-gray-900 bg-opacity-50 p-4 text-center font-semibold text-white backdrop-blur-sm backdrop-filter ${
            isScheduled ? 'flex' : 'hidden'
          }`}
        >
          <p>Coming Soon...</p>
        </div>

        {ts.val.preview?.title ? (
          <div
            className={`mt-5 flex flex-col ${
              isScheduled ? 'blur-sm filter' : null
            }`}
          >
            <div
              style={{
                color:
                  theme !== null
                    ? theme.customTheme.default
                      ? theme.customTheme.title
                      : null
                    : ts.val.style?.customTheme
                    ? ts.val.style?.customTheme.title
                    : null,
              }}
              className={`text-left text-xl leading-tight ${
                theme !== null
                  ? theme.customTheme.default
                    ? null
                    : themes.colorTemplates[theme.colorID - 1].title
                  : ts.val.style?.customTheme
                  ? null
                  : themes.colorTemplates[0].title
              } ${
                theme !== null
                  ? themes.fontPairings[theme.fontID - 1].title
                  : themes.fontPairings[1].title
              } mb-2 font-bold`}
              dangerouslySetInnerHTML={{ __html: ts.val.preview.title }}
            />

            {ts.val.preview ? (
              <div
                className={`${
                  theme !== null
                    ? theme.customTheme.default
                      ? 'Custom'
                      : themes.colorTemplates[theme.colorID - 1].name
                    : ts.val.style?.customTheme
                    ? 'Custom'
                    : themes.colorTemplates[0].name
                } ${
                  theme !== null
                    ? themes.fontPairings[theme.fontID - 1].name
                    : themes.fontPairings[1].name
                } prose overflow-ellipsis text-left  text-sm tracking-normal`}
              >
                <p>
                  {ts.val.preview.subtitle
                    ? ts.val.preview.subtitle.length > 0
                      ? ts.val.preview.subtitle.substring(0, 90)
                      : null
                    : null}
                </p>
              </div>
            ) : null}
          </div>
        ) : ts.val.content?.title ? (
          <div
            className={`mt-5 flex flex-col ${
              isScheduled ? 'blur-sm filter' : null
            }`}
          >
            <div
              style={{
                color:
                  theme !== null
                    ? theme.customTheme.default
                      ? theme.customTheme.title
                      : null
                    : ts.val.style?.customTheme
                    ? ts.val.style?.customTheme.title
                    : null,
              }}
              className={`text-left text-xl leading-tight ${
                theme !== null
                  ? theme.customTheme.default
                    ? null
                    : themes.colorTemplates[theme.colorID - 1].title
                  : ts.val.style?.customTheme
                  ? null
                  : themes.colorTemplates[0].title
              } ${
                theme !== null
                  ? themes.fontPairings[theme.fontID - 1].title
                  : themes.fontPairings[1].title
              } mb-2 font-bold`}
              dangerouslySetInnerHTML={{ __html: ts.val.content.title }}
            />

            {ts.val.preview ? (
              <div
                className={`${
                  theme !== null
                    ? theme.customTheme.default
                      ? 'Custom'
                      : themes.colorTemplates[theme.colorID - 1].name
                    : ts.val.style?.customTheme
                    ? 'Custom'
                    : themes.colorTemplates[0].name
                } ${
                  theme !== null
                    ? themes.fontPairings[theme.fontID - 1].name
                    : themes.fontPairings[1].name
                } prose overflow-ellipsis text-left  text-sm tracking-normal`}
              >
                <p>
                  {ts.val.preview.subtitle
                    ? ts.val.preview.subtitle.length > 0
                      ? ts.val.preview.subtitle.substring(0, 90)
                      : null
                    : null}
                </p>
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className={`mt-5 flex flex-col space-y-2 text-sm ${
              isScheduled ? 'blur-sm filter' : null
            }`}
          >
            {ts.val.tweetArray ? (
              <div
                className={`${
                  theme !== null
                    ? theme.customTheme.default
                      ? 'Custom'
                      : themes.colorTemplates[theme.colorID - 1].name
                    : ts.val.style?.customTheme
                    ? 'Custom'
                    : themes.colorTemplates[0].name
                } ${
                  theme !== null
                    ? themes.fontPairings[theme.fontID - 1].name
                    : themes.fontPairings[1].name
                } prose tweet-text-new overflow-ellipsis whitespace-pre-wrap text-left tracking-normal`}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: autoLinkTweet(ts.val.tweetArray[0]),
                  }}
                ></p>
              </div>
            ) : ts.val.threadMeta?.tweetArray ? (
              <div
                className={`${
                  theme !== null
                    ? theme.customTheme.default
                      ? 'Custom'
                      : themes.colorTemplates[theme.colorID - 1].name
                    : ts.val.style?.customTheme
                    ? 'Custom'
                    : themes.colorTemplates[0].name
                } ${
                  theme !== null
                    ? themes.fontPairings[theme.fontID - 1].name
                    : themes.fontPairings[1].name
                } prose tweet-text-new overflow-ellipsis whitespace-pre-wrap text-left tracking-normal`}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: autoLinkTweet(ts.val.threadMeta.tweetArray[0]),
                  }}
                ></p>
              </div>
            ) : ts.val.integrations?.linkedin?.customText ? (
              <div
                className={`${
                  theme !== null
                    ? theme.customTheme.default
                      ? 'Custom'
                      : themes.colorTemplates[theme.colorID - 1].name
                    : ts.val.style?.customTheme
                    ? 'Custom'
                    : themes.colorTemplates[0].name
                } ${
                  theme !== null
                    ? themes.fontPairings[theme.fontID - 1].name
                    : themes.fontPairings[1].name
                } prose overflow-ellipsis whitespace-pre-wrap text-left tracking-normal`}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${ts.val.integrations?.linkedin?.customText.substring(
                      0,
                      180,
                    )}...`,
                  }}
                ></p>
              </div>
            ) : (
              <div
                className={`${
                  theme !== null
                    ? theme.customTheme.default
                      ? 'Custom'
                      : themes.colorTemplates[theme.colorID - 1].name
                    : ts.val.style?.customTheme
                    ? 'Custom'
                    : themes.colorTemplates[0].name
                } ${
                  theme !== null
                    ? themes.fontPairings[theme.fontID - 1].name
                    : themes.fontPairings[1].name
                } prose overflow-ellipsis whitespace-pre-wrap text-left tracking-normal`}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${ts.val.content?.body.substring(0, 180)}...`,
                  }}
                ></p>
              </div>
            )}
          </div>
        )}
      </div>

      {type === 'scheduled' && ts.val.sharedTo ? (
        <div className="flex w-full flex-row items-center justify-between px-5 pb-5 ">
          <div
            style={{
              color:
                theme !== null
                  ? theme.customTheme.default
                    ? theme.customTheme.author
                    : null
                  : ts.val.style?.customTheme
                  ? ts.val.style?.customTheme.title
                  : null,
            }}
            className={`flex flex-row items-center justify-start space-x-2  text-gray-500 ${
              theme !== null
                ? theme.customTheme.default
                  ? null
                  : themes.colorTemplates[theme.colorID - 1].author
                : ts.val.style?.customTheme
                ? null
                : themes.colorTemplates[0].title
            }`}
          >
            {ts.val.sharedTo.twitter
              ? ts.val.sharedTo.twitter.shared && (
                  <TwitterBirdIcon className="h-5 w-5" />
                )
              : null}
            {ts.val.sharedTo.linkedin
              ? ts.val.sharedTo.linkedin.shared && (
                  <LinkedInLogo className="h-4 w-4 pb-0.5" />
                )
              : null}

            {ts.val.sharedTo.medium
              ? ts.val.sharedTo.medium.shared && (
                  <MediumLogo className="h-5 w-5" />
                )
              : null}
          </div>
        </div>
      ) : (ts.val.preview?.published && !socialBlog) ||
        (ts.val.tweetArray && !socialBlog) ? (
        hideIcons ? null : (
          <div className="flex w-full flex-row items-center justify-between px-5 pb-5 ">
            <div
              style={{
                color:
                  theme !== null
                    ? theme.customTheme.default
                      ? theme.customTheme.author
                      : null
                    : ts.val.style?.customTheme
                    ? ts.val.style?.customTheme.title
                    : null,
              }}
              className={`flex flex-row items-center justify-start space-x-2  text-gray-500 ${
                theme !== null
                  ? theme.customTheme.default
                    ? null
                    : themes.colorTemplates[theme.colorID - 1].author
                  : ts.val.style?.customTheme
                  ? null
                  : themes.colorTemplates[0].title
              }`}
            >
              {ts.val.preview?.published.twitter || ts.val.tweetArray ? (
                <TwitterBirdIcon className="h-5 w-5" />
              ) : null}
              {ts.val.preview?.published.linkedin && (
                <LinkedInLogo className="h-4 w-4 pb-0.5" />
              )}
              {ts.val.preview?.published.medium && (
                <MediumLogo className="h-5 w-5" />
              )}
            </div>
          </div>
        )
      ) : null}

      {theme !== null && theme.customTheme.default ? (
        <ThemeSwitcher
          theme={{
            text: theme.customTheme.css.text,
            quoteBG: theme.customTheme.css.blockquoteBG,
            quoteText: theme.customTheme.css.blockquoteText,
            highlight: theme.customTheme.css.highlight,
          }}
          elementId={`${ts.id}`}
        />
      ) : ts.val.style?.customTheme ? (
        <ThemeSwitcher
          theme={{
            text:
              theme !== null
                ? theme.customTheme.default
                  ? 'Custom'
                  : themes.colorTemplates[theme.colorID - 1].name
                : ts.val.style?.customTheme.css.text,
            quoteBG: ts.val.style?.customTheme.css.blockquoteBG,
            quoteText: ts.val.style?.customTheme.css.blockquoteText,
            highlight: ts.val.style?.customTheme.css.highlight,
          }}
          elementId={`${ts.id}`}
        />
      ) : null}

      {/* 

            {ts.val.style?.customTheme ?

                <div style={{ background: `linear-gradient(180deg, ${ts.val.style?.customTheme.background}10 0%, ${ts.val.style?.customTheme.background} 100%)` }} className={`absolute w-full h-24 z-10 bottom-0 rounded-b-2xl`} /> :


                <div className={`absolute w-full h-24 z-10 bottom-0 rounded-b-2xl bg-gradient-to-t ${gradColor}`} />
            } */}

      {type === 'scheduled' ? (
        <div
          className={
            'absolute top-2 right-2 z-30 flex flex-row space-x-1 rounded-full bg-orange-500 bg-opacity-10 p-1 pr-1.5 text-orange-500'
          }
        >
          <svg width="20" height="20" fill="none" viewBox="0 0 24 24">
            <circle
              cx="12"
              cy="12"
              r="7.25"
              stroke="currentColor"
              strokeWidth="1.5"
            ></circle>
            <path
              stroke="currentColor"
              strokeWidth="1.5"
              d="M12 8V12L14 14"
            ></path>
          </svg>
          <p className={'mt-0.5 text-xs font-medium'}>
            {getHourTime(ts.val.publishedAt?.unix)}
          </p>
        </div>
      ) : null}
    </button>
  );
}

export default withSize({ monitorHeight: true })(EssayCard);
