import { ShoppingCartIcon } from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  CreditCardIcon,
  LightningBoltIcon,
  PencilAltIcon,
  PlusCircleIcon,
} from '@heroicons/react/solid';
import router from 'next/router';
import { useEffect, useState } from 'react';
import Button from '../Button';
import SpinnerIcon from '../Icons/spinner';
import AlertBanner from '../Alert';
import { client } from '../../utils/http';
import axios from 'axios';

const proMonthlyPrice = 4900;
const proYearlyPrice = 39900;
const proMonthlyPriceID =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'price_1K3gRwIzYbcXDXFIFMPBvaTG'
    : 'price_1LGo1SIzYbcXDXFIi27ksPzn';
const proYearlyPriceID =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'price_1K3gRwIzYbcXDXFIoWJ0vuNm'
    : 'price_1JxXMiIzYbcXDXFIAuBAkYKx';
const premierPriceID =
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
    ? 'price_1LGnzUIzYbcXDXFIvsuZFDEQ'
    : 'price_1LGnyRIzYbcXDXFIKGUBtIiC';

export default function Checkout({
  cartItems,
  existingItems,
  billingPeriod,
  setPassCalulating,
  customerID,
  subscriptionID,
  replaceItems = false,
  inModal = false,
  checkout,
  setCheckout,
  hasCanceledAddons = false,
  productName = null,
  currentPriceID,
  createSub = false,
  status,
  checkoutComplete = null,
  header = null,
}) {
  const [last4, setLast4] = useState(null);
  const [cardBrand, setCardBrand] = useState(null);

  const [totalDetails, setTotalDetails] = useState(false);
  const [periodDetails, setPeriodDetails] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [billingDate, setBillingDate] = useState(null);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [hasReccuring, setHasReccuring] = useState(false);
  const [charging, setCharging] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [prorationDate, setProrationDate] = useState(null);
  const [prorateAmount, setProrateAmount] = useState(null);
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [oneTimeOnly, setOneTimeOnly] = useState(false);

  const [credit, setCredit] = useState(false);

  const [failed, setFailed] = useState(false);

  useEffect(() => {
    //check if all cart items item.price.type === one-time
    if (cartItems.length > 0) {
      let oneTime = true;
      cartItems.forEach((item) => {
        if (item.price.type === 'recurring') {
          oneTime = false;
        }
      });
      setOneTimeOnly(oneTime);
    }

    console.log(cartItems, 'CART ITEMS');
  }, [cartItems]);

  useEffect(() => {
    customerID ? getLastFour() : null;
  }, [customerID, subscriptionID]);

  useEffect(() => {
    customerID &&
    (subscriptionID || createSub) &&
    (cartItems.length > 0 || replaceItems)
      ? getProration()
      : null;
  }, [
    customerID,
    subscriptionID,
    billingPeriod,
    cartItems,
    replaceItems,
    createSub,
  ]);

  useEffect(() => {
    if (billingPeriod === 'month') {
      var newTotal =
        cartItems.reduce((acc, item) => acc + item.price.amount, 0) / 100;
      existingItems.length > 0
        ? (newTotal +=
            existingItems.reduce((acc, item) => acc + item.amount, 0) / 100)
        : null;
      //add pro price to new total
      replaceItems ? (newTotal += proMonthlyPrice / 100) : null;
      var formattedPrice = newTotal.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      setSubtotal(formattedPrice);
    } else {
      var newTotal =
        cartItems.reduce((acc, item) => acc + item.price.amount, 0) / 100;
      existingItems.length > 0
        ? (newTotal +=
            existingItems.reduce((acc, item) => acc + item.amount, 0) / 100)
        : null;
      //add pro price to new total
      replaceItems ? (newTotal += proYearlyPrice / 100) : null;
      var formattedPrice = newTotal.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      setSubtotal(formattedPrice);
    }

    if (cartItems.length === 0) {
      setTotalDetails(false);
    }
  }, [cartItems]);

  useEffect(() => {
    if (invoiceItems.length === 0) {
      setTotalDetails(false);
    }
  }, [invoiceItems]);

  async function getProration() {
    setCalculating(true);
    setPassCalulating(true);
    var prices = [];
    var oneTimePrices = [];
    var replace = [];
    cartItems.forEach((item) => {
      if (item.price.type === 'recurring') {
        if (billingPeriod === 'month') {
          if (replaceItems) {
            //switch to monthly from yearly
            var format1 = {
              old: item.price.year.id,
              new: item.price.month.id,
            };
            replace.push(format1);
          }
          prices.push(item.price.month.id);
        } else {
          if (replaceItems) {
            //switch to yearly from monthly
            var format2 = {
              old: item.price.month.id,
              new: item.price.year.id,
            };
            replace.push(format2);
          }
          prices.push(item.price.year.id);
        }
      } else {
        //no need to prorate because this is a one time purchase, just add to the total
        oneTimePrices.push(
          process.env.NEXT_PUBLIC_APP_ENV === 'production'
            ? item.price.priceID.prod
            : item.price.priceID.dev,
        );
      }
    });

    if (replaceItems) {
      if (billingPeriod === 'month') {
        if (
          productName.toLocaleLowerCase().includes('pro') ||
          productName.toLocaleLowerCase().includes('premier')
        ) {
          var format3 = {
            old: currentPriceID,
            new: proMonthlyPriceID,
          };
          replace.push(format3);
          prices.push(proMonthlyPriceID);
        } else if (
          productName.toLocaleLowerCase().includes('hobby') ||
          productName.toLocaleLowerCase().includes('expert')
        ) {
          //remove hobby, and add pro
          var format5 = {
            old: currentPriceID,
            new: proMonthlyPriceID,
          };
          replace.push(format5);
          prices.push(proMonthlyPriceID);
        } else if (productName.toLocaleLowerCase().includes('free')) {
          prices.push(proMonthlyPriceID);
        }
      } else {
        if (
          productName.toLocaleLowerCase().includes('pro') ||
          productName.toLocaleLowerCase().includes('premier')
        ) {
          var format4 = {
            old: currentPriceID,
            new: premierPriceID,
          };
          replace.push(format4);
          prices.push(premierPriceID);
        } else if (
          productName.toLocaleLowerCase().includes('hobby') ||
          productName.toLocaleLowerCase().includes('expert')
        ) {
          var format6 = {
            old: currentPriceID,
            new: premierPriceID,
          };
          replace.push(format6);
          prices.push(premierPriceID);
        } else if (productName.toLocaleLowerCase().includes('free')) {
          prices.push(premierPriceID);
        }
      }
    }

    if (prices.length > 0) {
      setHasReccuring(true);
    } else {
      setHasReccuring(false);
    }

    try {
      const { data } = await client.post('/api/stripe/read/proration', {
        customerID,
        subscriptionID,
        prices,
        oneTimePrices,
        replace,
        isReplace: replaceItems,
        period: billingPeriod,
        createSub,
      });
      if (!data.success) {
        throw new Error(data.message);
      }
      setProrationDate(data.proration_date);
      setInvoiceItems(data.invoiceItems);

      var nextPaymentDate = new Date(data.date);

      var renewDate = data.nextRenewal;
      var renewalDateUnix = new Date(renewDate * 1000);
      var renewalDate = renewalDateUnix.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      setBillingDate(renewalDate);

      //if data.total is negative
      if (data.total < 0) {
        setCredit(true);
      } else {
        setCredit(false);
      }

      var nextDate = data.date;
      var nextPaymentDate = new Date(nextDate * 1000);

      if (
        data.oneTimeOnly ||
        nextPaymentDate.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        }) ===
          new Date().toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
          })
      ) {
        var formattedTotal = (data.total / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        setTotal(formattedTotal);
      } else {
        setTotal('$0');
      }

      if (data.prorateBalance !== 0) {
        setProrateAmount(data.prorateBalance);
      }

      setCalculating(false);
      setPassCalulating(false);

      return true;
    } catch (err) {
      setCalculating(false);
      setPassCalulating(false);
      setFailed(true);
      return false;
    }
  }

  async function chargePremier() {
    setCharging(true);

    var prices = [];
    var oneTimePrices = [];
    var replace = [];
    cartItems.forEach((item) => {
      if (item.price.type === 'recurring') {
        if (billingPeriod === 'month') {
          if (replaceItems) {
            //switch to monthly from yearly
            var format1 = {
              old: item.price.year.id,
              new: item.price.month.id,
            };
            replace.push(format1);
          }
          prices.push(item.price.month.id);
        } else {
          if (replaceItems) {
            //switch to yearly from monthly
            var format2 = {
              old: item.price.month.id,
              new: item.price.year.id,
            };
            replace.push(format2);
          }
          prices.push(item.price.year.id);
        }
      } else {
        //no need to prorate because this is a one time purchase, just add to the total
        oneTimePrices.push(
          process.env.NEXT_PUBLIC_APP_ENV === 'production'
            ? item.price.priceID.prod
            : item.price.priceID.dev,
        );
      }
    });

    if (replaceItems) {
      if (billingPeriod === 'month') {
        //changing to monthly from yearly
        if (
          productName.toLocaleLowerCase().includes('pro') ||
          productName.toLocaleLowerCase().includes('premier')
        ) {
          var format3 = {
            old: currentPriceID,
            new: proMonthlyPriceID,
          };
          replace.push(format3);
          prices.push(proMonthlyPriceID);
        } else if (
          productName.toLocaleLowerCase().includes('hobby') ||
          productName.toLocaleLowerCase().includes('expert')
        ) {
          //remove hobby, and add pro
          var format5 = {
            old: currentPriceID,
            new: proMonthlyPriceID,
          };
          replace.push(format5);
          prices.push(proMonthlyPriceID);
        } else if (productName.toLocaleLowerCase().includes('free')) {
          prices.push(proMonthlyPriceID);
        }
      } else {
        if (
          productName.toLocaleLowerCase().includes('pro') ||
          productName.toLocaleLowerCase().includes('premier')
        ) {
          var format4 = {
            old: currentPriceID,
            new: premierPriceID,
          };
          replace.push(format4);
          prices.push(premierPriceID);
        } else if (
          productName.toLocaleLowerCase().includes('hobby') ||
          productName.toLocaleLowerCase().includes('expert')
        ) {
          var format6 = {
            old: currentPriceID,
            new: premierPriceID,
          };
          replace.push(format6);
          prices.push(premierPriceID);
        } else if (productName.toLocaleLowerCase().includes('free')) {
          prices.push(premierPriceID);
        }
      }
    }

    try {
      const { data } = await client.post('/api/stripe/update/pro-store', {
        subscriptionID,
        prices,
        oneTimePrices,
        customerID,
        prorationDate,
        replace,
        isReplace: replaceItems,
        createSub,
        selectedCard,
      });
      if (!data.success) {
        alert(data.error || 'Error. Please try again.');
        throw new Error(data.error || 'unknown error');
      }
      setCharging(false);
      setCheckout('loading');
      checkoutComplete && checkoutComplete(true);
      return true;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        alert(err.response?.data?.error || 'Error. Please try again.');
      }
      setCharging(false);
      setCalculating(false);
      setCheckout('error');
      return false;
    }
  }

  async function openBillingPortal(type) {
    console.log('open billing portal', router.asPath);

    try {
      const { data } = await client.post(
        '/api/create-customer-portal-session',
        {
          customerID: customerID,
          returnLink: router.asPath,
        },
      );
      if (data.success === true) {
        type === 'card'
          ? router.push(
              status === 'active' && subscriptionID
                ? `${data.url}/subscriptions/${subscriptionID}/update-payment-method/changePaymentMethodFromHome`
                : `${data.url}/payment-methods`,
            )
          : router.push(`${data.url}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getLastFour() {
    try {
      const { data } = await client.post('/api/stripe/read/last4', {
        customerID,
        subscriptionID,
      });
      if (data.success) {
        setCards(data.data);
        //loop through cards and find the card id where default is true
        var defaultCard = data.data.find((card) => card.default === true);
        if (defaultCard) {
          setSelectedCard(defaultCard.id);
        } else {
          //if no default card, set to first card
          if (data.data.length > 0) {
            setSelectedCard(data.data[0].id);
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function changeCard(card) {
    setSelectedCard(card);
    try {
      const { data } = await client.post('/api/stripe/update/default-card', {
        customerID,
        card,
      });
      return data.success;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  return (
    <div className="flex flex-col space-y-4">
      <div
        className={`inline-block w-full ${
          inModal ? null : 'border border-gray-200 border-opacity-50'
        } transform rounded-2xl bg-[#FFFEFC] text-left align-middle antialiased transition-all`}
      >
        <div className="flex w-full flex-row items-center justify-center p-2">
          <div className="flex flex-row items-center justify-center space-x-1 pt-2 text-gray-900">
            {checkout === 'loading' ? (
              <SpinnerIcon className={'h-4 w-4 animate-spin'} />
            ) : checkout === 'done' ? (
              <CheckCircleIcon className="h-4 w-4 text-green-500" />
            ) : (
              <ShoppingCartIcon className="h-4 w-4" />
            )}

            <h1 className="text-center font-sans text-base font-semibold tracking-tight text-gray-900">
              {checkout === 'loading'
                ? 'Upgrading your account...'
                : checkout === 'done'
                ? 'Success!'
                : replaceItems
                ? productName === 'Hobby' || header === 'pro'
                  ? `Upgrade to ${
                      billingPeriod === 'month' ? `Pro` : `Premier`
                    }`
                  : 'Switch Plan'
                : 'Your Cart'}
            </h1>
          </div>
        </div>

        {checkout === 'loading' ? (
          <div className="px-4 pb-4">
            <div className=" flex flex-col items-center justify-center space-y-4 rounded-xl bg-gray-100 p-8 text-center text-sm font-medium text-gray-600">
              <SpinnerIcon className="h-8 w-8 animate-spin" />
              <p>This may take a few moments...</p>
            </div>
          </div>
        ) : checkout === 'done' ? (
          <div className="px-4 pb-4">
            <div className=" flex flex-col items-center justify-center space-y-4 rounded-xl bg-gray-100 p-8 text-center text-sm font-medium text-green-600">
              <CheckCircleIcon className="h-8 w-8" />
              <p>Success! Your account has been upgraded!</p>
            </div>
          </div>
        ) : cartItems.length > 0 || replaceItems ? (
          <div>
            <div className="mt-2 flex w-full flex-col space-y-4 px-4 pb-4">
              <div className="flex flex-col items-center justify-between space-y-3 p-3">
                {replaceItems && (
                  <div className="flex w-full flex-row items-center justify-between rounded-xl">
                    <div className="flex flex-row items-center space-x-1">
                      <div className={`rounded-xl bg-blue-500 p-2`}>
                        <LightningBoltIcon className={'h-4 w-4 text-white'} />
                      </div>

                      <div className="text-sm font-medium text-gray-900">
                        <p className="text-sm font-semibold">
                          {billingPeriod === 'month' ? `Pro` : `Premier`}
                        </p>
                        {/* <p className='text-gray-500 text-xs'>What you'll pay {billingPeriod}ly starting {billingDate}</p> */}
                      </div>
                    </div>

                    <p className="text-sm font-semibold">
                      {billingPeriod === 'month'
                        ? `$${proMonthlyPrice / 100}/mo`
                        : `$${proYearlyPrice / 100}/yr`}
                    </p>
                  </div>
                )}

                {cartItems.length > 0 &&
                  cartItems.map((item) => (
                    <div className="flex w-full flex-row items-center justify-between rounded-xl">
                      <div className="flex flex-row items-center space-x-2">
                        {item.meta.logo ? (
                          <img
                            className="h-8 w-8"
                            src={item.meta.logo}
                            alt={item.meta.title}
                          />
                        ) : (
                          <div className="flex items-center justify-center rounded-lg border border-gray-100 bg-white p-2 text-xl shadow-sm">
                            {item.meta.icon}
                          </div>
                        )}
                        <div className="flex flex-col space-y-0.5 text-sm font-medium text-gray-900">
                          <p className="text-sm font-medium tracking-tightish">
                            {item.meta.title}
                          </p>
                          <p className="text-xs text-gray-500">
                            {item.meta.productType}
                          </p>
                          {/* <p className='text-gray-500 text-xs'>What you'll pay {billingPeriod}ly starting {billingDate}</p> */}
                        </div>
                      </div>

                      <p className="text-base font-medium">
                        {item.price.type === 'recurring'
                          ? billingPeriod === 'month'
                            ? `$${item.price.amount / 100}/mo`
                            : `$${item.price.amount / 100}/yr`
                          : `$${item.price.amount / 100}`}
                      </p>
                    </div>
                  ))}
              </div>

              {hasReccuring && <div className="h-[1px] w-full bg-gray-100" />}

              {hasReccuring && (
                <div className="flex flex-col space-y-2">
                  <div className="flex flex-row items-center justify-between px-4 font-medium text-gray-900">
                    <div className="text-sm font-medium text-gray-900">
                      <p className="text-sm font-medium">
                        Total {billingPeriod}ly price starting {billingDate}{' '}
                      </p>
                      <button
                        onClick={() => setPeriodDetails(!periodDetails)}
                        className="flex flex-row items-center justify-center text-xs font-medium text-blue-500"
                      >
                        <p>{periodDetails ? 'Hide' : 'View'} Details</p>
                        <ChevronDownIcon
                          className={`${
                            periodDetails && 'rotate-180'
                          } h-3 w-3 transform`}
                        />
                      </button>
                    </div>

                    <p>{subtotal}</p>
                  </div>

                  {periodDetails && (
                    <div className="px-4">
                      <div className="flex-col space-y-1 rounded-xl bg-gray-50 p-3 ">
                        {replaceItems && (
                          <div className="flex flex-row items-center justify-between space-x-3 text-sm text-gray-600">
                            <div className="flex flex-row items-center justify-center space-x-1">
                              <p className="">
                                {billingPeriod === 'month' ? `Pro` : `Premier`}
                              </p>
                            </div>

                            {billingPeriod === 'month'
                              ? (proMonthlyPrice / 100).toLocaleString(
                                  'en-US',
                                  { style: 'currency', currency: 'USD' },
                                )
                              : (proYearlyPrice / 100).toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                          </div>
                        )}

                        {cartItems.map(
                          (item) =>
                            item.price.type === 'recurring' && (
                              <div className="flex flex-row items-center justify-between space-x-3 text-sm text-gray-600">
                                <div className="flex flex-row items-center justify-center space-x-1">
                                  <p className="">{item.meta.name}</p>
                                </div>

                                {calculating ? (
                                  <SpinnerIcon
                                    className={'h-4 w-4 animate-spin'}
                                  />
                                ) : (
                                  <p>
                                    {(billingPeriod === 'month'
                                      ? item.price.amount / 100
                                      : item.price.amount / 100
                                    ).toLocaleString('en-US', {
                                      style: 'currency',
                                      currency: 'USD',
                                    })}
                                  </p>
                                )}
                              </div>
                            ),
                        )}

                        {existingItems.map((item) => (
                          <div className="flex flex-row items-center justify-between text-sm text-gray-600">
                            <div className="flex flex-row items-center justify-center space-x-1">
                              <p className="">{item.name}</p>
                            </div>

                            {calculating ? (
                              <SpinnerIcon className={'h-4 w-4 animate-spin'} />
                            ) : (
                              <p>
                                {(item.amount / 100).toLocaleString('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                })}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div className="h-[1px] w-full bg-gray-100" />

              <div className="flex w-full flex-col space-y-2">
                <div className="flex flex-row items-center justify-between px-4 text-base font-medium text-gray-900">
                  <div className="text-base font-medium text-gray-900">
                    <p className="">Total (due today) </p>
                    {!oneTimeOnly && invoiceItems.length > 0 && (
                      <button
                        onClick={() => setTotalDetails(!totalDetails)}
                        className="flex flex-row items-center justify-center text-xs font-medium text-blue-500"
                      >
                        <p>{totalDetails ? 'Hide' : 'View'} Details</p>
                        <ChevronDownIcon
                          className={`${
                            totalDetails && 'rotate-180'
                          } h-3 w-3 transform`}
                        />
                      </button>
                    )}
                  </div>

                  {calculating ? (
                    <SpinnerIcon className={'h-4 w-4 animate-spin'} />
                  ) : (
                    <p>{credit ? '$0' : total}</p>
                  )}
                </div>

                {totalDetails && (
                  <div className="px-4">
                    <div className="flex-col space-y-1 rounded-xl bg-gray-50 p-3 ">
                      {prorateAmount && (
                        <div className="flex flex-row items-center justify-between space-x-3 text-sm text-gray-600">
                          <div className="flex flex-row items-center justify-center space-x-1">
                            <p className="">Credit balance</p>
                          </div>

                          {calculating ? (
                            <SpinnerIcon className={'h-4 w-4 animate-spin'} />
                          ) : (
                            <p className=" whitespace-nowrap">
                              {(prorateAmount / 100).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </p>
                          )}
                        </div>
                      )}

                      {invoiceItems.map((item) => (
                        <div className="flex flex-row items-center justify-between space-x-3 text-sm text-gray-600">
                          <div className="flex flex-row items-center justify-center space-x-1">
                            <p className="">{item.description}</p>
                          </div>

                          {calculating ? (
                            <SpinnerIcon className={'h-4 w-4 animate-spin'} />
                          ) : (
                            <p className=" whitespace-nowrap">
                              {(item.amount / 100).toLocaleString('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              })}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* {hasReccuring &&
                            <div className='flex flex-row justify-between items-center px-4 text-sm text-gray-500'>
                                <div className='flex flex-row space-x-1 items-center justify-center'>
                                    <p className=''>What you'll pay {billingPeriod}ly starting {billingDate}</p>

                                </div>
                                {calculating ? <SpinnerIcon className={"w-4 h-4 animate-spin"} /> : <p>{subtotal}</p>}

                            </div>
                        } */}
              </div>

              <div className="w-full px-4">
                {cards.length === 0 ? (
                  <Button
                    click={() => openBillingPortal('card')}
                    width={'full'}
                    size={'mini'}
                    variant={'secondary'}
                  >
                    Add Payment Method
                  </Button>
                ) : (
                  <div className="w-full overflow-hidden rounded-xl border border-gray-100">
                    <div className="space-y-2 p-3">
                      {cards.map((card, accountIdx) => (
                        <div className="w-full" key={accountIdx}>
                          <div className="flex w-full flex-row items-center justify-between">
                            <div className="flex w-full flex-row items-center justify-center space-x-2 text-sm font-medium text-gray-600">
                              {card.brand === 'visa' && (
                                <div
                                  className={`${
                                    accountIdx !== 0 && 'mt-2'
                                  } rounded-md border border-gray-100 bg-white px-1.5 py-2 shadow-sm`}
                                >
                                  <img
                                    className="h-2 w-auto object-fill"
                                    src={
                                      '/icons/Visa_Brandmark_Blue_RGB_2021.png'
                                    }
                                  />
                                </div>
                              )}

                              {card.brand === 'mastercard' && (
                                <div className="rounded-md border border-gray-300 bg-gray-200 px-1.5 py-1">
                                  <img
                                    className="h-3 w-auto object-fill"
                                    src={'/icons/mc_symbol.svg'}
                                  />
                                </div>
                              )}

                              {card.brand === 'amex' && (
                                <div className="rounded-md border border-gray-300 bg-gray-200 px-1 py-0">
                                  <img
                                    className="h-5 w-auto object-fill"
                                    src={'/icons/Amex_logo_color.svg'}
                                  />
                                </div>
                              )}

                              {card.brand !== 'amex' &&
                                card.brand !== 'mastercard' &&
                                card.brand !== 'visa' && (
                                  <CreditCardIcon className="h-5 w-auto object-fill" />
                                )}

                              <div
                                className={` ${
                                  accountIdx === 0
                                    ? null
                                    : 'border-t border-gray-100 pt-2'
                                }   flex w-full flex-col`}
                              >
                                <p className="text-sm font-medium text-gray-900">
                                  Ending in {card.last4}
                                </p>
                                <p className="text-xs text-gray-500">
                                  Expiry {card.expiry}
                                </p>
                              </div>
                            </div>

                            {cards.length === 1 ? (
                              <button
                                disabled={
                                  !card.last4 || calculating || charging
                                    ? true
                                    : false
                                }
                                onClick={() => openBillingPortal('card')}
                                className="roudned-full rounded-full p-2 text-gray-600 duration-200 hover:bg-gray-200"
                              >
                                <PencilAltIcon className="h-4 w-4" />
                              </button>
                            ) : (
                              <div className="ml-3 flex h-5 items-center">
                                <input
                                  id={`card-${card.id}`}
                                  aria-describedby={`card-${card.id}-description`}
                                  name="card"
                                  type="radio"
                                  defaultChecked={card.default}
                                  value={card.id}
                                  onChange={() => changeCard(card.id)}
                                  className="h-4 w-4 cursor-pointer border-gray-300 text-gray-900 focus:ring-gray-800"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>

                    <button
                      onClick={() => openBillingPortal('card')}
                      className="flex w-full flex-row items-center justify-center  space-x-2 border-t border-gray-100 p-3 text-gray-700 duration-200 hover:bg-gray-100"
                    >
                      <PlusCircleIcon className="h-4 w-4" />
                      <p className="text-sm font-semibold">New Card</p>
                    </button>
                  </div>
                )}
              </div>

              {!oneTimeOnly && (
                <p className="px-4 text-xs text-gray-400">
                  Your subscription will automatically renew. You can manage
                  your subscription and cancel anytime in your account settings.
                </p>
              )}
            </div>

            {hasCanceledAddons && (
              <div className="mb-4 w-full px-4">
                <AlertBanner
                  type={'warn'}
                  title={
                    'Any canceled add-ons will be permanently removed. Before changing your billing period, please renew any add-ons you wish to keep.'
                  }
                />
              </div>
            )}

            {status === 'past_due' && (
              <div className="mb-4 w-full px-4">
                <AlertBanner
                  type={'error'}
                  title={
                    'Payment failed. Please fix your subscription by paying the outstanding invoice in your account settings.'
                  }
                />
              </div>
            )}

            <div className="flex w-full items-end justify-end px-8 pb-4">
              <Button
                variant={'primary'}
                click={() =>
                  status === 'past_due'
                    ? router.push('/settings/account')
                    : chargePremier()
                }
                disabled={
                  !selectedCard || calculating || charging || failed
                    ? true
                    : false
                }
                loading={calculating || charging}
                size={'small'}
                width={'full'}
              >
                {status === 'past_due'
                  ? 'Fix Subscription'
                  : oneTimeOnly
                  ? 'Confirm & Pay Now'
                  : 'Confirm & Update Subscription'}
              </Button>
            </div>
          </div>
        ) : (
          <div className="px-4 pb-4">
            <div className=" flex flex-row items-center justify-center space-x-2 rounded-xl bg-gray-100 p-3 text-center text-sm font-medium text-gray-600">
              <PlusCircleIcon className="h-4 w-4" />
              <p>Add some add-ons to your cart</p>
            </div>
          </div>
        )}
      </div>
      {failed && (
        <div className="flex w-full items-center justify-center px-4 pb-4">
          <AlertBanner
            type={'error'}
            title={'Something went wrong. Please contact support.'}
          />
        </div>
      )}

      <div
        className={`flex flex-row items-center justify-center space-x-3 border-t border-gray-100 bg-gray-50 ${
          inModal ? 'p-4' : null
        }`}
      >
        <img src={'/icons/climate.svg'} className="h-5 w-5" />
        <p className="text-xs text-gray-700">
          1% of this purchase helps remove CO₂ from the atmosphere.
        </p>
      </div>
    </div>
  );
}
