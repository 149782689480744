import {
  ChevronRightIcon,
  LockClosedIcon,
  LoginIcon,
  PlusIcon,
} from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import Button from '../Button';
import SpinnerIcon from '../Icons/spinner';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import TemplatePreviewModal from '../NewModals/TemplatePreview';
import OnboardingPopover from '../OnboardingPopover';

const tourTemplate = {
  data: {
    content: {
      V2Editor: true,
      bodyText:
        '<p>Interested in:</p><p></p><ul><li><p>{Topic A}</p></li><li><p>{Topic B}</p></li><li><p>{Topic C}</p></li></ul><p></p><p>I\'ll be writing about those on my <span data-type="mention" class="mention" data-id="typeshare_co">@typeshare_co</span> Social Blog.</p><p></p><p>Follow along here: {{SOCIAL_BLOG_URL}}</p>',
      titleText: '',
    },
    settings: {
      activePreview: 'twitter',
      altText: '',
      imageSize: 'Large',
      tweetText: '',
      twitterFormat: 'thread',
    },
  },
  meta: {
    creator_name: 'Typeshare',
    creator_photo:
      'https://pbs.twimg.com/profile_images/1517549946106437633/HmxlCL2z_400x400.jpg',
    creator: 'typeshare',
    description:
      'The perfect template to get you started on your writing journey.',
    example: '',
    icon: '🧑‍🏫',
    slug: 'tour-template',
    title: 'Tour Template',
    type: 'tweet',
    uses: 0,
  },
};

export const TemplateCard = ({
  tourType,
  showTourPop = false,
  closeTour = null,
  showTour = false,
  name,
  slug,
  landing = false,
  type,
  color,
  icon,
  description,
  locked,
  creator_name,
  creator_photo,
  uses,
  example,
  template,
  hasPricing,
  modal,
  packModal,
  packView,
  showPack,
  packSlug = null,
  openLoginModal,
  isLoggedIn,
  createTypeshare,
  setPlanModalOpen,
}) => {
  const [pricing, setPricing] = useState(false);
  const [templatePreviewModal, setTemplatePreviewModal] = useState(false);

  const [tourPopOpen, setTourPopOpen] = useState(false);

  useEffect(() => {
    if (showTour && showTourPop) {
      setTourPopOpen(true);
    }
  }, [showTour, showTourPop]);

  const closePop = () => {
    setTourPopOpen(false);
    closeTour && closeTour();
  };

  const baseRef = firebase.database().ref('templates2');
  const router = useRouter();

  useEffect(() => {
    if (packSlug) {
      //get the pack meta
      baseRef
        .child(`packs`)
        .child('packs')
        .child(packSlug)
        .child('price')
        .once('value', (snap) => {
          if (snap.exists()) {
            setPricing(snap.val());
          }
        });
    }
  }, [packSlug]);

  return (
    <div
      key={slug}
      className={`flex flex-col overflow-hidden rounded-xl ${
        landing
          ? 'h-64 bg-white shadow-lg shadow-slate-900/5 ring-1 ring-slate-500/10 duration-300  hover:scale-105'
          : 'border border-gray-100'
      } ${modal || packModal ? 'min-w-max sm:min-w-0 ' : null}`}
    >
      <TemplatePreviewModal
        open={templatePreviewModal}
        setOpen={setTemplatePreviewModal}
        slug={slug}
        openTemplate={() => createTypeshare(template)}
      />
      <div className="flex flex-1 flex-col justify-between space-y-4 bg-white p-5 ">
        <div className="flex flex-col space-y-4">
          <div className="flex w-full flex-row items-center justify-between gap-2">
            <p className="text-3xl">{icon}</p>

            <p
              className={`truncate whitespace-nowrap rounded-lg px-2.5 py-1 text-xs font-semibold uppercase ${
                type === 'atomic-essay'
                  ? 'bg-orange-500 bg-opacity-10 text-orange-500'
                  : type === 'tweet'
                  ? 'bg-fuchsia-500 bg-opacity-10 text-fuchsia-500'
                  : type === 'thread'
                  ? 'bg-blue-500 bg-opacity-10 text-blue-500'
                  : type === 'linkedin-post'
                  ? 'bg-indigo-500 bg-opacity-10 text-indigo-500'
                  : 'bg-yellow-500 bg-opacity-10 text-yellow-600'
              }`}
            >
              {type === 'atomic-essay'
                ? 'Atomic Essay'
                : type === 'tweet'
                ? 'Tweet'
                : type === 'thread'
                ? 'Thread'
                : type === 'linkedin-post'
                ? 'LinkedIn Post'
                : 'Subatomic Essay'}
            </p>

            {/*   <Tooltip
                            html={(
                                <div className="text-gray-600 flex flex-row space-x-3 justify-start items-center font-semibold uppercase bg-white font-mono antialiased m-0 text-xs p-0 rounded-md w-full">
                                    {type === "atomic-essay" ? "Atomic Essay" : type === "tweet" ? "Standalone Tweet" : type === "thread" ? "Twitter Thread" : type === "linkedin-post" ? "LinkedIn Post" : "Subatomic Essay"}
                                </div>
                            )}
                            theme="light"
                            size='small'
                            position="top"
                            arrow={true}
                            effect={'solid'}
                            trigger="mouseenter"
                            offset={2}
                        >
                           {type === "atomic-essay" ? <EssayIconColor /> : type === "tweet" ? <TweetIconColor /> : type === "thread" ? <ThreadIconColor /> : type === "linkedin-post" ? <LinkedInIconColor /> : <SubatomicIconColor />}
                        </Tooltip> */}
          </div>

          <div>
            <h2
              className={`text-lg font-semibold leading-snug tracking-tight text-gray-900  ${
                modal || packModal ? 'max-w-[200px] sm:max-w-none' : null
              }`}
            >
              {name}
            </h2>
            <p
              className={`mt-1 text-sm tracking-tight text-gray-500 ${
                modal || packModal ? 'max-w-[200px] sm:max-w-none' : null
              } `}
            >
              {description}
            </p>
          </div>
        </div>

        {!isLoggedIn && !landing ? (
          <Button
            click={() => {
              openLoginModal();
            }}
            size={'mini'}
            width={'full'}
            prefix={<LoginIcon className="-mr-2 h-4 w-4" />}
            text={undefined}
            tip={undefined}
            toggleEnabled={undefined}
            setToggleEnabled={undefined}
            tipSub={undefined}
            variant={undefined}
            disabled={undefined}
            rounded={undefined}
            icon={undefined}
            mobile={undefined}
            loading={undefined}
            color={undefined}
            suffix={undefined}
            backgroundStyle={undefined}
            textStyle={undefined}
            customText={undefined}
          >
            Login to Use
          </Button>
        ) : (
          <div className="flex flex-col space-y-4">
            {(hasPricing || pricing) && locked && packSlug ? (
              <div
                className={` ${
                  packView
                    ? router.pathname === '/templates/packs/[pack]'
                      ? 'hidden'
                      : `${landing ? 'hidden' : 'flex'} sm:hidden`
                    : `${landing ? 'hidden' : 'flex'}`
                }`}
              >
                <Button
                  click={() =>
                    router.push({
                      pathname: '/templates/packs/[pack]',
                      query: { pack: packSlug },
                    })
                  }
                  size={'mini'}
                  width={'full'}
                  /* color={!locked ? type === "atomic-essay" ? "bg-orange-500" : type === "tweet" ? "bg-red-500" : type === "thread" ? "bg-blue-500" : type === "linkedin-post" ? "bg-indigo-500" : "bg-yellow-500" : null} */ prefix={
                    locked && <LockClosedIcon className="-mr-2 h-4 w-4" />
                  }
                  text={undefined}
                  tip={undefined}
                  toggleEnabled={undefined}
                  setToggleEnabled={undefined}
                  tipSub={undefined}
                  variant={undefined}
                  disabled={undefined}
                  rounded={undefined}
                  icon={undefined}
                  mobile={undefined}
                  loading={undefined}
                  color={undefined}
                  suffix={undefined}
                  backgroundStyle={undefined}
                  textStyle={undefined}
                  customText={undefined}
                >
                  <p className="font-sans">Unlock Now</p>
                </Button>
              </div>
            ) : (
              <OnboardingPopover
                close={() => closePop()}
                show={showTour}
                step={3}
                totalSteps={8}
                title={
                  tourType === 'ship30'
                    ? "Click 'Use' to create a new post with the first template in your Ship30 pack!"
                    : "Click the 'Use' button to create a new post with this template."
                }
                placement={'bottom'}
                isOpen={tourPopOpen}
                setIsOpen={setTourPopOpen}
              >
                <div
                  className={`${landing ? 'hidden' : 'flex'} flex-row ${
                    packView ? 'xs:flex-col' : 'xs:flex-row'
                  } items-center justify-center gap-2 sm:flex-row lg:flex-col xl:flex-row`}
                >
                  <Button
                    click={() =>
                      locked
                        ? setPlanModalOpen(true)
                        : createTypeshare(template)
                    }
                    size={'mini'}
                    width={'full'}
                    variant={!locked && 'primary'}
                    /* color={!locked ? type === "atomic-essay" ? "bg-orange-500" : type === "tweet" ? "bg-red-500" : type === "thread" ? "bg-blue-500" : type === "linkedin-post" ? "bg-indigo-500" : "bg-yellow-500" : null} */ prefix={
                      locked && <LockClosedIcon className="-mr-2 h-4 w-4" />
                    }
                    text={undefined}
                    tip={undefined}
                    toggleEnabled={undefined}
                    setToggleEnabled={undefined}
                    tipSub={undefined}
                    disabled={undefined}
                    rounded={undefined}
                    icon={undefined}
                    mobile={undefined}
                    loading={undefined}
                    color={undefined}
                    suffix={undefined}
                    backgroundStyle={undefined}
                    textStyle={undefined}
                    customText={undefined}
                  >
                    <p className="font-sans">
                      {locked
                        ? 'Unlock Now'
                        : modal || packModal
                        ? 'Use'
                        : 'Use Template'}
                    </p>
                  </Button>
                </div>
              </OnboardingPopover>
            )}

            <div
              className={`flex w-full flex-row flex-wrap items-center justify-between gap-1`}
            >
              <div className="flex flex-row space-x-1">
                <img src={creator_photo} className="h-5 w-5 rounded-full" />
                <p className="text-sm font-semibold tracking-tight text-gray-500">
                  {creator_name}
                </p>
              </div>

              {!locked && isLoggedIn && !landing ? (
                <button
                  onClick={() => setTemplatePreviewModal(true)}
                  className="flex flex-row items-center justify-center space-x-0 text-sm font-medium text-blue-500 duration-150 hover:text-blue-700"
                >
                  <p>Preview</p>
                  <ChevronRightIcon className="h-4 w-4" />
                </button>
              ) : null}

              {/*  <Tooltip
                            html={(
                                <div className="text-gray-600 flex flex-row space-x-3 justify-start items-center font-semibold uppercase bg-white font-mono antialiased m-0 text-xs p-0 rounded-md w-full">
                                    <p>{uses > 999 ? (Math.round((uses / 100)) / 10) + "K" : uses} uses</p>
                                </div>
                            )}
                            theme="light"
                            size='small'
                            position="top"
                            arrow={true}
                            effect={'solid'}
                            trigger="mouseenter"
                            offset={2}
                        >
                            <div className="flex flex-row space-x-0.5 items-center justify-center text-gray-500 text-sm font-medium">
                                <DuplicateIcon className="w-4 h-4" />
                                <p>
                                    {uses > 999 ? (Math.round((uses / 100)) / 10) + "K" : uses}
                                </p>
                            </div>
                        </Tooltip> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function CategoryResults({
  tourType = null,
  showTourPop = false,
  closeTour = null,
  tour = false,
  isLoggedIn = true,
  openLoginModal,
  data,
  templates,
  createTypeshare,
  packView = false,
  modal = false,
  packModal = false,
  packUnlocks = null,
  setPlanModalOpen,
  unlocksAt,
  productName = 'Free Plan',
  createBlankTypeshare,
  hasPricing = false,
  locked,
}) {
  return templates && templates.length > 0 ? (
    <div
      className={` ${
        (packView && !packModal) || modal
          ? packView
            ? 'grid grid-cols-1 gap-2 xs:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'
            : 'flex w-full flex-row items-stretch gap-4 overflow-x-auto px-4 pb-2 no-scrollbar sm:grid sm:w-auto sm:grid-cols-1 sm:flex-col sm:gap-4 sm:space-y-0.5 sm:overflow-hidden sm:px-0 sm:pb-0 sm:show-scrollbar md:grid-cols-2 lg:grid-cols-3'
          : packModal
          ? 'flex w-full flex-row items-stretch gap-4 overflow-x-auto px-4 pb-2 no-scrollbar sm:grid sm:w-auto sm:grid-cols-1 sm:flex-col sm:gap-4 sm:space-y-0.5 sm:overflow-hidden sm:px-0 sm:pb-0 sm:show-scrollbar md:grid-cols-2 lg:grid-cols-2'
          : 'grid grid-cols-1 items-stretch gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
      }`}
    >
      {data && (
        <button
          onClick={() => createBlankTypeshare(data.meta.slug)}
          className={`border bg-gray-100 ${
            modal || packModal ? 'min-w-max sm:min-w-min' : null
          } flex flex-col items-center justify-center space-y-2 rounded-xl border-gray-200 p-4 py-10 text-gray-500 duration-200 hover:bg-gray-200`}
        >
          <PlusIcon className="h-6 w-6" />
          <p className="whitespace-nowrap text-sm font-medium sm:whitespace-normal">
            Create a blank {data.meta.title}
          </p>
        </button>
      )}

      {tour && tourType === null ? (
        <TemplateCard
          showTourPop={showTourPop}
          closeTour={closeTour}
          showTour={true}
          tourType={tourType}
          setPlanModalOpen={setPlanModalOpen}
          createTypeshare={createTypeshare}
          openLoginModal={openLoginModal}
          isLoggedIn={isLoggedIn}
          packView={packView}
          modal={modal}
          packModal={packModal}
          hasPricing={hasPricing}
          template={tourTemplate}
          name={tourTemplate.meta.title}
          slug={tourTemplate.meta.slug}
          type={tourTemplate.meta.type}
          color={'#f5f5f5'}
          icon={tourTemplate.meta.icon}
          description={tourTemplate.meta.description}
          locked={false}
          creator_name={tourTemplate.meta.creator_name}
          creator_photo={tourTemplate.meta.creator_photo}
          uses={tourTemplate.meta.uses}
          example={tourTemplate.meta.example}
          showPack={undefined}
        />
      ) : null}

      {templates.map((template, index) => {
        const isUnlocked = locked
          ? !locked
          : template
          ? template.meta.pack
            ? template.meta.pack === 'foundations'
              ? productName === 'Free Plan' || productName === 'Hobby'
                ? false
                : true
              : packUnlocks
              ? packUnlocks[template.meta.pack]?.unlocked
                ? true
                : false
              : false
            : false
          : false;
        const unlocksAt = template
          ? packUnlocks
            ? packUnlocks[template.meta.pack]?.date
            : null
          : null;

        return (
          <TemplateCard
            showTourPop={showTourPop}
            closeTour={closeTour}
            tourType={tourType}
            showTour={template.meta.slug === 'day-0-introduce-yourself'}
            setPlanModalOpen={setPlanModalOpen}
            createTypeshare={createTypeshare}
            openLoginModal={openLoginModal}
            isLoggedIn={isLoggedIn}
            packSlug={template.meta.pack}
            packView={packView}
            modal={modal}
            packModal={packModal}
            hasPricing={hasPricing}
            template={template}
            name={template.meta.title}
            slug={template.meta.slug}
            type={template.meta.type}
            color={template.meta.color}
            icon={template.meta.icon}
            description={template.meta.description}
            locked={!isUnlocked}
            creator_name={template.meta.creator_name}
            creator_photo={template.meta.creator_photo}
            uses={template.meta.uses}
            example={template.meta.example}
            showPack={undefined}
          />
        );
      })}
    </div>
  ) : (
    <div className="flex w-full items-center justify-center pt-12">
      <SpinnerIcon className={'h-6 w-6 animate-spin'} />
    </div>
  );
}
