export const EssayIconColor = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill={'#ffedd5'}
    />
    <path
      d="M13.5001 16H18.5001H13.5001ZM13.5001 19.3333H18.5001H13.5001ZM20.1667 23.5H11.8334C11.3914 23.5 10.9675 23.3244 10.6549 23.0118C10.3423 22.6993 10.1667 22.2754 10.1667 21.8333V10.1667C10.1667 9.72464 10.3423 9.30072 10.6549 8.98816C10.9675 8.67559 11.3914 8.5 11.8334 8.5H16.4884C16.7094 8.50005 16.9213 8.58788 17.0776 8.74417L21.5892 13.2558C21.7455 13.4121 21.8334 13.624 21.8334 13.845V21.8333C21.8334 22.2754 21.6578 22.6993 21.3453 23.0118C21.0327 23.3244 20.6088 23.5 20.1667 23.5Z"
      fill="white"
      stroke="#f97316"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={'#f97316'}
      stroke-width="1.5"
    />
  </svg>
);

export const TweetIconColor = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill="#fae8ff"
    />
    <path
      d="M16 21.8333L23.5 23.5L16 8.5L8.5 23.5L16 21.8333ZM16 21.8333V15.1667V21.8333Z"
      fill="white"
    />
    <path
      d="M16 21.8333V15.1667M16 21.8333L23.5 23.5L16 8.5L8.5 23.5L16 21.8333Z"
      stroke="#d946ef"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={'#d946ef'}
      stroke-width="1.5"
    />
  </svg>
);

export const ThreadIconColor = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill="#dbeafe"
    />
    <rect x="10" y="12" width="12" height="3" fill="white" />
    <path
      d="M21.8333 15.1667C22.2754 15.1667 22.6993 15.3423 23.0118 15.6548C23.3244 15.9674 23.5 16.3913 23.5 16.8333V21.8333C23.5 22.2754 23.3244 22.6993 23.0118 23.0118C22.6993 23.3244 22.2754 23.5 21.8333 23.5H10.1667C9.72464 23.5 9.30072 23.3244 8.98816 23.0118C8.67559 22.6993 8.5 22.2754 8.5 21.8333V16.8333C8.5 16.3913 8.67559 15.9674 8.98816 15.6548C9.30072 15.3423 9.72464 15.1667 10.1667 15.1667H21.8333ZM11.8333 11.8333V10.1667C11.8333 9.72464 12.0089 9.30072 12.3215 8.98816C12.634 8.67559 13.058 8.5 13.5 8.5H18.5C18.942 8.5 19.3659 8.67559 19.6785 8.98816C19.9911 9.30072 20.1667 9.72464 20.1667 10.1667V11.8333H11.8333ZM20.1667 11.8333H11.8333H20.1667Z"
      fill="white"
    />
    <path
      d="M21.8333 15.1667H10.1667M21.8333 15.1667C22.2754 15.1667 22.6993 15.3423 23.0118 15.6548C23.3244 15.9674 23.5 16.3913 23.5 16.8333V21.8333C23.5 22.2754 23.3244 22.6993 23.0118 23.0118C22.6993 23.3244 22.2754 23.5 21.8333 23.5H10.1667C9.72464 23.5 9.30072 23.3244 8.98816 23.0118C8.67559 22.6993 8.5 22.2754 8.5 21.8333V16.8333C8.5 16.3913 8.67559 15.9674 8.98816 15.6548C9.30072 15.3423 9.72464 15.1667 10.1667 15.1667M21.8333 15.1667V13.5C21.8333 13.058 21.6577 12.634 21.3452 12.3215C21.0326 12.0089 20.6087 11.8333 20.1667 11.8333M10.1667 15.1667V13.5C10.1667 13.058 10.3423 12.634 10.6548 12.3215C10.9674 12.0089 11.3913 11.8333 11.8333 11.8333M20.1667 11.8333V10.1667C20.1667 9.72464 19.9911 9.30072 19.6785 8.98816C19.366 8.67559 18.942 8.5 18.5 8.5H13.5C13.058 8.5 12.634 8.67559 12.3215 8.98816C12.0089 9.30072 11.8333 9.72464 11.8333 10.1667V11.8333M20.1667 11.8333H11.8333"
      stroke="#3b82f6"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke="#3b82f6"
      stroke-width="1.5"
    />
  </svg>
);

export const LinkedInIconColor = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill="#e0e7ff"
    />
    <path
      d="M19.3333 11.0001V9.33341C19.3333 8.89139 19.1577 8.46746 18.8452 8.1549C18.5326 7.84234 18.1087 7.66675 17.6667 7.66675H14.3333C13.8913 7.66675 13.4674 7.84234 13.1548 8.1549C12.8423 8.46746 12.6667 8.89139 12.6667 9.33341V11.0001H19.3333ZM10.1667 22.6667H21.8333C22.2754 22.6667 22.6993 22.4912 23.0118 22.1786C23.3244 21.866 23.5 21.4421 23.5 21.0001V12.6667C23.5 12.2247 23.3244 11.8008 23.0118 11.4882C22.6993 11.1757 22.2754 11.0001 21.8333 11.0001H10.1667C9.72464 11.0001 9.30072 11.1757 8.98816 11.4882C8.67559 11.8008 8.5 12.2247 8.5 12.6667V21.0001C8.5 21.4421 8.67559 21.866 8.98816 22.1786C9.30072 22.4912 9.72464 22.6667 10.1667 22.6667Z"
      fill="white"
    />
    <path
      d="M23.5 17.0459C21.1171 18.0094 18.5704 18.5032 16 18.5001C13.3475 18.5001 10.8167 17.9834 8.5 17.0459M16 16.0001H16.0083M19.3333 11.0001V9.33341C19.3333 8.89139 19.1577 8.46746 18.8452 8.1549C18.5326 7.84234 18.1087 7.66675 17.6667 7.66675H14.3333C13.8913 7.66675 13.4674 7.84234 13.1548 8.1549C12.8423 8.46746 12.6667 8.89139 12.6667 9.33341V11.0001H19.3333ZM10.1667 22.6667H21.8333C22.2754 22.6667 22.6993 22.4912 23.0118 22.1786C23.3244 21.866 23.5 21.4421 23.5 21.0001V12.6667C23.5 12.2247 23.3244 11.8008 23.0118 11.4882C22.6993 11.1757 22.2754 11.0001 21.8333 11.0001H10.1667C9.72464 11.0001 9.30072 11.1757 8.98816 11.4882C8.67559 11.8008 8.5 12.2247 8.5 12.6667V21.0001C8.5 21.4421 8.67559 21.866 8.98816 22.1786C9.30072 22.4912 9.72464 22.6667 10.1667 22.6667Z"
      stroke="#4f46e5"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke="#4f46e5"
      stroke-width="1.5"
    />
  </svg>
);

export const SubatomicIconColor = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill={'#fefce8'}
    />
    <path
      d="M13.5001 16H18.5001H13.5001ZM13.5001 19.3333H18.5001H13.5001ZM20.1667 23.5H11.8334C11.3914 23.5 10.9675 23.3244 10.6549 23.0118C10.3423 22.6993 10.1667 22.2754 10.1667 21.8333V10.1667C10.1667 9.72464 10.3423 9.30072 10.6549 8.98816C10.9675 8.67559 11.3914 8.5 11.8334 8.5H16.4884C16.7094 8.50005 16.9213 8.58788 17.0776 8.74417L21.5892 13.2558C21.7455 13.4121 21.8334 13.624 21.8334 13.845V21.8333C21.8334 22.2754 21.6578 22.6993 21.3453 23.0118C21.0327 23.3244 20.6088 23.5 20.1667 23.5Z"
      fill="white"
      stroke="#eab308"
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={'#eab308'}
      stroke-width="1.5"
    />
  </svg>
);

export const EssayIconSecondary = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill={active ? '#ffedd5' : '#F4F4F5'}
    />
    <path
      d="M13.5001 16H18.5001H13.5001ZM13.5001 19.3333H18.5001H13.5001ZM20.1667 23.5H11.8334C11.3914 23.5 10.9675 23.3244 10.6549 23.0118C10.3423 22.6993 10.1667 22.2754 10.1667 21.8333V10.1667C10.1667 9.72464 10.3423 9.30072 10.6549 8.98816C10.9675 8.67559 11.3914 8.5 11.8334 8.5H16.4884C16.7094 8.50005 16.9213 8.58788 17.0776 8.74417L21.5892 13.2558C21.7455 13.4121 21.8334 13.624 21.8334 13.845V21.8333C21.8334 22.2754 21.6578 22.6993 21.3453 23.0118C21.0327 23.3244 20.6088 23.5 20.1667 23.5Z"
      fill="white"
      stroke={active ? '#f97316' : '#A1A1AA'}
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={active ? '#fff' : '#A1A1AA'}
      stroke-width="1.5"
    />
  </svg>
);

export const TweetIconSecondary = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill={active ? '#fee2e2' : '#F4F4F5'}
    />
    <path
      d="M16 21.8333L23.5 23.5L16 8.5L8.5 23.5L16 21.8333ZM16 21.8333V15.1667V21.8333Z"
      fill="white"
    />
    <path
      d="M16 21.8333V15.1667M16 21.8333L23.5 23.5L16 8.5L8.5 23.5L16 21.8333Z"
      stroke={active ? '#ef4444' : '#A1A1AA'}
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={active ? '#fff' : '#A1A1AA'}
      stroke-width="1.5"
    />
  </svg>
);

export const ThreadIconSecondary = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill={active ? '#dbeafe' : '#F4F4F5'}
    />
    <rect x="10" y="12" width="12" height="3" fill="white" />
    <path
      d="M21.8333 15.1667C22.2754 15.1667 22.6993 15.3423 23.0118 15.6548C23.3244 15.9674 23.5 16.3913 23.5 16.8333V21.8333C23.5 22.2754 23.3244 22.6993 23.0118 23.0118C22.6993 23.3244 22.2754 23.5 21.8333 23.5H10.1667C9.72464 23.5 9.30072 23.3244 8.98816 23.0118C8.67559 22.6993 8.5 22.2754 8.5 21.8333V16.8333C8.5 16.3913 8.67559 15.9674 8.98816 15.6548C9.30072 15.3423 9.72464 15.1667 10.1667 15.1667H21.8333ZM11.8333 11.8333V10.1667C11.8333 9.72464 12.0089 9.30072 12.3215 8.98816C12.634 8.67559 13.058 8.5 13.5 8.5H18.5C18.942 8.5 19.3659 8.67559 19.6785 8.98816C19.9911 9.30072 20.1667 9.72464 20.1667 10.1667V11.8333H11.8333ZM20.1667 11.8333H11.8333H20.1667Z"
      fill="white"
    />
    <path
      d="M21.8333 15.1667H10.1667M21.8333 15.1667C22.2754 15.1667 22.6993 15.3423 23.0118 15.6548C23.3244 15.9674 23.5 16.3913 23.5 16.8333V21.8333C23.5 22.2754 23.3244 22.6993 23.0118 23.0118C22.6993 23.3244 22.2754 23.5 21.8333 23.5H10.1667C9.72464 23.5 9.30072 23.3244 8.98816 23.0118C8.67559 22.6993 8.5 22.2754 8.5 21.8333V16.8333C8.5 16.3913 8.67559 15.9674 8.98816 15.6548C9.30072 15.3423 9.72464 15.1667 10.1667 15.1667M21.8333 15.1667V13.5C21.8333 13.058 21.6577 12.634 21.3452 12.3215C21.0326 12.0089 20.6087 11.8333 20.1667 11.8333M10.1667 15.1667V13.5C10.1667 13.058 10.3423 12.634 10.6548 12.3215C10.9674 12.0089 11.3913 11.8333 11.8333 11.8333M20.1667 11.8333V10.1667C20.1667 9.72464 19.9911 9.30072 19.6785 8.98816C19.366 8.67559 18.942 8.5 18.5 8.5H13.5C13.058 8.5 12.634 8.67559 12.3215 8.98816C12.0089 9.30072 11.8333 9.72464 11.8333 10.1667V11.8333M20.1667 11.8333H11.8333"
      stroke={active ? '#3b82f6' : '#A1A1AA'}
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={active ? '#fff' : '#A1A1AA'}
      stroke-width="1.5"
    />
  </svg>
);

export const LinkedInIconSecondary = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill={active ? '#e0e7ff' : '#F4F4F5'}
    />
    <path
      d="M19.3333 11.0001V9.33341C19.3333 8.89139 19.1577 8.46746 18.8452 8.1549C18.5326 7.84234 18.1087 7.66675 17.6667 7.66675H14.3333C13.8913 7.66675 13.4674 7.84234 13.1548 8.1549C12.8423 8.46746 12.6667 8.89139 12.6667 9.33341V11.0001H19.3333ZM10.1667 22.6667H21.8333C22.2754 22.6667 22.6993 22.4912 23.0118 22.1786C23.3244 21.866 23.5 21.4421 23.5 21.0001V12.6667C23.5 12.2247 23.3244 11.8008 23.0118 11.4882C22.6993 11.1757 22.2754 11.0001 21.8333 11.0001H10.1667C9.72464 11.0001 9.30072 11.1757 8.98816 11.4882C8.67559 11.8008 8.5 12.2247 8.5 12.6667V21.0001C8.5 21.4421 8.67559 21.866 8.98816 22.1786C9.30072 22.4912 9.72464 22.6667 10.1667 22.6667Z"
      fill="white"
    />
    <path
      d="M23.5 17.0459C21.1171 18.0094 18.5704 18.5032 16 18.5001C13.3475 18.5001 10.8167 17.9834 8.5 17.0459M16 16.0001H16.0083M19.3333 11.0001V9.33341C19.3333 8.89139 19.1577 8.46746 18.8452 8.1549C18.5326 7.84234 18.1087 7.66675 17.6667 7.66675H14.3333C13.8913 7.66675 13.4674 7.84234 13.1548 8.1549C12.8423 8.46746 12.6667 8.89139 12.6667 9.33341V11.0001H19.3333ZM10.1667 22.6667H21.8333C22.2754 22.6667 22.6993 22.4912 23.0118 22.1786C23.3244 21.866 23.5 21.4421 23.5 21.0001V12.6667C23.5 12.2247 23.3244 11.8008 23.0118 11.4882C22.6993 11.1757 22.2754 11.0001 21.8333 11.0001H10.1667C9.72464 11.0001 9.30072 11.1757 8.98816 11.4882C8.67559 11.8008 8.5 12.2247 8.5 12.6667V21.0001C8.5 21.4421 8.67559 21.866 8.98816 22.1786C9.30072 22.4912 9.72464 22.6667 10.1667 22.6667Z"
      stroke={active ? '#4f46e5' : '#A1A1AA'}
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={active ? '#fff' : '#A1A1AA'}
      stroke-width="1.5"
    />
  </svg>
);

export const SubatomicIconSecondary = ({ active, className }) => (
  <svg
    className={className ? className : 'h-8 w-8'}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      fill={active ? '#ffedd5' : '#F4F4F5'}
    />
    <rect x="10" y="12" width="12" height="3" fill="white" />
    <path
      d="M21.8333 15.1667C22.2754 15.1667 22.6993 15.3423 23.0118 15.6548C23.3244 15.9674 23.5 16.3913 23.5 16.8333V21.8333C23.5 22.2754 23.3244 22.6993 23.0118 23.0118C22.6993 23.3244 22.2754 23.5 21.8333 23.5H10.1667C9.72464 23.5 9.30072 23.3244 8.98816 23.0118C8.67559 22.6993 8.5 22.2754 8.5 21.8333V16.8333C8.5 16.3913 8.67559 15.9674 8.98816 15.6548C9.30072 15.3423 9.72464 15.1667 10.1667 15.1667H21.8333ZM11.8333 11.8333V10.1667C11.8333 9.72464 12.0089 9.30072 12.3215 8.98816C12.634 8.67559 13.058 8.5 13.5 8.5H18.5C18.942 8.5 19.3659 8.67559 19.6785 8.98816C19.9911 9.30072 20.1667 9.72464 20.1667 10.1667V11.8333H11.8333ZM20.1667 11.8333H11.8333H20.1667Z"
      fill="white"
    />
    <path
      d="M21.8333 15.1667H10.1667M21.8333 15.1667C22.2754 15.1667 22.6993 15.3423 23.0118 15.6548C23.3244 15.9674 23.5 16.3913 23.5 16.8333V21.8333C23.5 22.2754 23.3244 22.6993 23.0118 23.0118C22.6993 23.3244 22.2754 23.5 21.8333 23.5H10.1667C9.72464 23.5 9.30072 23.3244 8.98816 23.0118C8.67559 22.6993 8.5 22.2754 8.5 21.8333V16.8333C8.5 16.3913 8.67559 15.9674 8.98816 15.6548C9.30072 15.3423 9.72464 15.1667 10.1667 15.1667M21.8333 15.1667V13.5C21.8333 13.058 21.6577 12.634 21.3452 12.3215C21.0326 12.0089 20.6087 11.8333 20.1667 11.8333M10.1667 15.1667V13.5C10.1667 13.058 10.3423 12.634 10.6548 12.3215C10.9674 12.0089 11.3913 11.8333 11.8333 11.8333M20.1667 11.8333V10.1667C20.1667 9.72464 19.9911 9.30072 19.6785 8.98816C19.366 8.67559 18.942 8.5 18.5 8.5H13.5C13.058 8.5 12.634 8.67559 12.3215 8.98816C12.0089 9.30072 11.8333 9.72464 11.8333 10.1667V11.8333M20.1667 11.8333H11.8333"
      stroke={active ? '#f97316' : '#A1A1AA'}
      stroke-width="1.5"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="0.75"
      y="0.75"
      width="30.5"
      height="30.5"
      rx="9.25"
      stroke={active ? '#fff' : '#A1A1AA'}
      stroke-width="1.5"
    />
  </svg>
);
