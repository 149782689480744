export default function EssayIconDetailed({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="15.5"
        fill="url(#paint0_linear_435:1110)"
      />
      <path
        d="M11.75 23.25H20.25C21.3546 23.25 22.25 22.3546 22.25 21.25V13L18 8.75H11.75C10.6454 8.75 9.75 9.64543 9.75 10.75V21.25C9.75 22.3546 10.6454 23.25 11.75 23.25Z"
        fill="url(#paint1_linear_435:1110)"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 13.25H17.75V9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="8.875"
        y1="2"
        x2="8.875"
        y2="30"
        stroke="white"
        stroke-opacity="0.25"
        strokeWidth="0.25"
      />
      <line
        x1="23.125"
        y1="2"
        x2="23.125"
        y2="30"
        stroke="white"
        stroke-opacity="0.25"
        strokeWidth="0.25"
      />
      <line
        x1="2"
        y1="7.875"
        x2="30"
        y2="7.875"
        stroke="white"
        stroke-opacity="0.25"
        strokeWidth="0.25"
      />
      <line
        x1="2"
        y1="24.125"
        x2="30"
        y2="24.125"
        stroke="white"
        stroke-opacity="0.25"
        strokeWidth="0.25"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="white" />
      <defs>
        <linearGradient
          id="paint0_linear_435:1110"
          x1="16"
          y1="0"
          x2="16"
          y2="32"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FB923C" />
          <stop offset="1" stopColor="#FF7300" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_435:1110"
          x1="16"
          y1="8.75"
          x2="16"
          y2="23.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.57" />
          <stop offset="1" stopColor="white" stopOpacity="0.19" />
        </linearGradient>
      </defs>
    </svg>
  );
}
