import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import EssayCard from '../../components/Cards/essayCard';
import EmptyStateSection from '../EmptyState';
import EssayIconDetailed from '../Icons/essay-icon-detailed';
import ConfirmHideThread from '../Modal/confirm-hide-thread';

import Button from '../Button';

import { sortPosts } from '../../utils/library';
import SpinnerIcon from '../Icons/spinner';

var twitter = require('twitter-text');

export default function Published({ tab, setTab, STRIPE_PK }) {
  const router = useRouter();

  const [filter, setFilter] = useState('imageEssay');
  const [typeshares, setTypeshares] = useState([]);
  const [publishedThreads, setPublishedThreads] = useState([]);

  const [twitterHandle, setTwitterHandle] = useState('');
  const [twitterName, setTwitterName] = useState('');
  const [twitterPhotoUrl, setTwitterPhotoUrl] = useState('');

  const [userID, setUserID] = useState('');
  const [slug, setSlug] = useState('');

  const [posts, setPosts] = useState([]);
  const [next, setNext] = useState(null);

  const [confirmHideThreaModalOpen, setConfirmHideThreaModalOpen] =
    useState(false);
  const [activeTID, setActiveTID] = useState('');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        getGalleryUser(firebase.auth().currentUser.uid);
        getMeta(firebase.auth().currentUser.uid);
        setUserID(firebase.auth().currentUser.uid);
      } else {
        //            setOnboarding(true)
      }
    });
    return function cleanup() {
      if (firebase.auth().currentUser) {
        const refClean = firebase
          .database()
          .ref('users/' + firebase.auth().currentUser.uid + '/plan');
        refClean.off('value');
      }
    };
  }, []);

  function getMeta(userID) {
    var ref = firebase.database().ref('users/' + userID + '/meta/twitter');
    ref.once(
      'value',
      function (snapshot) {
        if (snapshot.exists()) {
          if (snapshot.hasChild('handle')) {
            setTwitterHandle(snapshot.val().handle);
          } else {
            var ref2 = firebase.database().ref('users/' + userID + '/gallery');
            ref2.once('value', function (snapshot2) {
              if (snapshot2.exists()) {
                getTwitterHandle(userID, snapshot2.val().username);
              }
            });
          }
          if (snapshot.hasChild('displayName')) {
            setTwitterName(snapshot.val().displayName);
          }
          if (snapshot.hasChild('photo')) {
            const newImage = snapshot.val().photo;
            if (snapshot.val().photo.includes('googleusercontent')) {
              setTwitterPhotoUrl(newImage.replace('=s96-c', ''));
            } else if (snapshot.val().photo.includes('twimg')) {
              setTwitterPhotoUrl(newImage.replace('_normal', ''));
            } else {
              setTwitterPhotoUrl(snapshot.val().photo);
            }
          }
        } else {
          //Cant share to Twitter, so dont show that part in the publish modal
        }
      },
      function (errorObject) {},
    );
  }

  function getTwitterHandle(uid, slug) {
    var ref = firebase
      .database()
      .ref('gallery/' + slug)
      .child(uid);
    ref.once(
      'value',
      function (snapshot) {
        if (snapshot.exists()) {
          setTwitterHandle(snapshot.val().twitterURL);
        } else {
        }
      },
      function (errorObject) {},
    );
  }

  function getThreads(uid, slug) {
    var ref = firebase
      .database()
      .ref('gallery/' + slug.toLocaleLowerCase() + '/' + uid + '/threads/')
      .orderByChild('publishedAt/unix');
    ref.once('value', (querySnapshot) => {
      if (querySnapshot.exists()) {
        var threadsTemp = [];
        querySnapshot.forEach((doc) => {
          if (doc.hasChild('publishedAt')) {
            const publishedTime = doc.val().publishedAt.unix;
            const currentTime = Date.now();

            if (publishedTime < currentTime) {
              threadsTemp.push(doc);
            }
          }
        });
        setPublishedThreads(threadsTemp.reverse());
      }
    });
  }

  const getDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const newDate = new Date(date).toLocaleDateString(undefined, options);
    return newDate;
  };

  function updatePostList(uid, username) {
    var ref = firebase
      .database()
      .ref(
        'gallery/' + username.toLocaleLowerCase() + '/' + uid + '/typeshares/',
      )
      .orderByChild('publishedAt/unix');
    ref.once('value', (querySnapshot) => {
      var typesharesTemp = [];
      querySnapshot.forEach((doc) => {
        //Check if content exists - this should stop an timestamp/visible only Typeshare from erroring the page

        if (doc.hasChild('content')) {
          if (doc.hasChild('publishedAt')) {
            const publishedTime = doc.val().publishedAt.unix;
            const currentTime = Date.now();

            if (publishedTime < currentTime) {
              doc.val().visible ? typesharesTemp.push(doc) : null;
            }
          } else {
            //for early essays without published timestamps
            doc.val().visible ? typesharesTemp.push(doc) : null;
          }
        }
      });
      setTypeshares(typesharesTemp.reverse());
    });
  }

  function getGalleryUser(uid) {
    var ref = firebase
      .database()
      .ref('users/' + uid + '/gallery/')
      .child('username');
    ref.on('value', (querySnapshot) => {
      if (querySnapshot.exists()) {
        setSlug(querySnapshot.val());

        fetchPosts(uid, querySnapshot.val());
        /* 
                                updatePostList(uid, querySnapshot.val())
                                getThreads(uid, querySnapshot.val()) */
      } else {
      }
    });
  }

  const autoLinkTweet = (tweet) => {
    var tweetElement = twitter.default.autoLink(tweet);

    var regexpAt = /@/g;
    var matchAt = regexpAt.exec(tweet);

    while (matchAt != null) {
      tweetElement = tweetElement.replaceAll(
        matchAt[0],
        `<span class='text-blue-400'>${matchAt[0]}</span>`,
      );
      matchAt = regexpAt.exec(tweet);
    }

    return tweetElement;
  };

  const hideThread = () => {
    firebase
      .database()
      .ref('gallery/' + slug + '/' + userID)
      .child('threads')
      .child(activeTID)
      .remove()
      .then(() => {
        firebase
          .database()
          .ref('users/' + userID)
          .child('threads')
          .child(activeTID)
          .child('published')
          .remove()
          .then(() => {
            getThreads(userID, slug);
            setConfirmHideThreaModalOpen(false);
          });
      });
  };

  const openHideModal = (event, threadID) => {
    event.stopPropagation();
    setConfirmHideThreaModalOpen(true);
    setActiveTID(threadID);
  };

  async function fetchPosts(uid, slug) {
    const data = await sortPosts(uid, slug, next);
    setPosts(data.posts);
    setNext(data.next);
    setLoading(false);
  }

  return (
    <>
      <ConfirmHideThread
        open={confirmHideThreaModalOpen}
        setOpen={setConfirmHideThreaModalOpen}
        deleteConfirmed={hideThread}
        keep={() => setConfirmHideThreaModalOpen(false)}
      />

      <div className={'space-y-8 antialiased'}>
        {loading ? (
          <div className="flex w-full items-center justify-center p-16">
            <SpinnerIcon className={'h-8 w-8 animate-spin text-gray-500'} />
          </div>
        ) : posts.length === 0 ? (
          <div
            className={`grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
          >
            <div className={'col-span-4 w-full'}>
              <EmptyStateSection
                STRIPE_PK={STRIPE_PK}
                icon={<EssayIconDetailed className={'h-12 w-12'} />}
                title={'Start by creating a post'}
                text={
                  'Creating your first post is easy. Just hit the Create button.'
                }
              />
            </div>
          </div>
        ) : (
          <div
            className={
              'flex w-full flex-col items-center justify-center space-y-8'
            }
          >
            <div
              className={`grid w-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}
            >
              {posts.map((typeshare, i) => (
                <EssayCard
                  creatorID={userID}
                  creatorSlug={slug}
                  typeshare={typeshare}
                  click={() => {
                    router.push({
                      pathname: '/editor/[pid]',
                      query: { pid: typeshare.id },
                    });
                  }}
                />
              ))}
            </div>
            <div
              className={
                'my-4 flex w-full flex-col items-center justify-center space-y-2 border-t border-gray-200 pt-4 pb-4 text-sm font-medium text-gray-500'
              }
            >
              {next ? (
                <p>Showing your {posts.length} most recent published posts</p>
              ) : (
                <p>Showing all your published posts</p>
              )}
              <Button
                variant={'outline'}
                size={'mini'}
                click={() => fetchPosts(userID, slug)}
                disabled={!next}
              >
                Load More
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
