/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useRef, useState } from 'react';

import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import { LockClosedIcon, PlusIcon, XIcon } from '@heroicons/react/solid';
import Button from '../Button';
import CategoriesTabs from '../TemplatesPage/CategoriesTabs';
import CategoryResults from '../TemplatesPage/CategoryResults';
import PackHorizontalCards from '../TemplatesPage/PackHorizontalCards';
import { SinglePack } from '../TemplatesPage/TemplatePacks';

import { getActivePlan } from '../../utils/helpers';
import UpgradePlanModal from '../Plans/UpgradePlanModal';

import { cio } from '../../utils/customer-io';
import OnboardingPopover from '../OnboardingPopover';
import { client } from '../../utils/http';

export default function CreateNewModal({
  open,
  setOpen,
  STRIPE_PK,
  tour = false,
  setTour = null,
  tourType = null,
}) {
  const router = useRouter();

  const [activeTab, setActiveTab] = useState('atomic-essay');
  const [activePack, setActivePack] = useState(null);

  const [productName, setProductName] = useState('Free Plan');
  const cancelButtonRef = useRef();

  const [userID, setUserID] = useState(null);
  const [hasCustomTheme, setHasCustomTheme] = useState(false);
  const [customTheme, setCustomTheme] = useState(false);
  const [creatingPost, setCreatingPost] = useState(false);
  const [creatingThread, setCreatingThread] = useState(false);
  const [loading, setLoading] = useState(true);

  const [editionPacks, setEditions] = useState(null);

  const [connectUserTwitter, setConnectTwitter] = useState(false);
  const [reconnectUserTwitter, setReconnectTwitter] = useState(false);

  const [planModalOpen, setPlanModalOpen] = useState(false);

  const [templates, setTemplates] = useState(null);
  const [activeType, setActiveType] = useState(null);

  const [errorMSG, setErrorMSG] = useState('');

  const [unlockedPacks, setUnlockedPacks] = useState(null);

  const [templatePackSelectorOpen, setTemplatePackSelectorOpen] =
    useState(false);

  const [tourStage, setTourStage] = useState(0);

  const [typeshareContentDraft, setTypeshareContentDraft] = useState({
    content: {
      author: '',
      footer: '',
    },
    style: {
      background: false,
      title: true,
      author: true,
      footer: true,
      colorID: 1,
      fontID: 1,
      shapeID: 1,
      widthID: 3,
      fontSizeID: 3,
      footerID: 1,
      layoutID: 4,
    },
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setUserID(firebase.auth().currentUser.uid);
        getUserTheme(firebase.auth().currentUser.uid);
        getActivePlan(firebase.auth().currentUser.uid, function (plan) {
          setProductName(plan.name);
        });
      } else {
      }
    });
  }, []);

  useEffect(() => {
    if (tourType && editionPacks && unlockedPacks && templates) {
      if (
        tourType === 'ship30' &&
        Object.keys(unlockedPacks).includes('ship-30-for-30')
      ) {
        setActivePack('ship-30-for-30');
      }
    }
  }, [tourType, editionPacks, unlockedPacks, templates]);

  useEffect(() => {
    userID && open && productName ? getSchedule(userID) : null;
  }, [userID, open, productName]);

  const getSchedule = async (userID) => {
    var tmpUnlockedPacks = {};

    var ref = await firebase
      .database()
      .ref('users/' + userID)
      .child('templatePackSchedule')
      .child('edition-1')
      .once('value');
    tmpUnlockedPacks = ref.val();

    var ref2 = await firebase
      .database()
      .ref('users/' + userID)
      .child('templatePackSchedule')
      .child('oneTime')
      .once('value');
    tmpUnlockedPacks = { ...tmpUnlockedPacks, ...ref2.val() };

    setUnlockedPacks(tmpUnlockedPacks);
  };

  const checkoutSuccess = () => {
    userID && getSchedule(userID);
  };

  useEffect(() => {
    setCreatingPost(false);
    setCreatingThread(false);
  }, [router.asPath]);

  useEffect(() => {
    setPlanModalOpen(false);
  }, [open]);

  const getUserTheme = (uid) => {
    const dbRef = firebase.database().ref(`users/${uid}`);

    dbRef
      .child('gallery')
      .child('username')
      .once('value')
      .then((gallerySnap) => {
        if (gallerySnap.exists()) {
          firebase
            .database()
            .ref('gallery')
            .child(gallerySnap.val())
            .child(uid)
            .child('meta')
            .child('displayName')
            .once('value')
            .then((gallerySnap2) => {
              var displayName = '';

              if (gallerySnap2.exists()) {
                displayName = gallerySnap2.val();

                var typeshareURL = `typeshare.co/${gallerySnap.val()}`;

                dbRef
                  .child('theme')
                  .once('value')
                  .then((snapshot) => {
                    if (snapshot.exists()) {
                      dbRef
                        .child('theme/style')
                        .once('value')
                        .then((snapshot2) => {
                          var hasCT = snapshot2.hasChild('customTheme');
                          setHasCustomTheme(hasCT);
                          setCustomTheme(snapshot.val().style.customTheme);
                          setTypeshareContentDraft({
                            content: {
                              V2Editor: true,
                              titleText: '',
                              bodyText: '',
                              author: snapshot.hasChild('content/author')
                                ? snapshot.val().content.author
                                : displayName,
                              footer: snapshot.hasChild('content/footer')
                                ? snapshot.val().content.footer
                                : 'Made with Typeshare',
                              footerURL: snapshot.hasChild('content/footerURL')
                                ? snapshot.val().content.footerURL
                                : typeshareURL,
                              sidebarText: snapshot.hasChild(
                                'content/sidebarText',
                              )
                                ? snapshot.val().content.sidebarText
                                : displayName,
                            },
                          });
                          setLoading(false);
                        });
                    } else {
                      setTypeshareContentDraft({
                        content: {
                          V2Editor: true,
                          titleText: '',
                          bodyText: '',
                          author: displayName,
                          footer: 'Made with Typeshare',
                          footerURL: snapshot.hasChild('content/footerURL')
                            ? snapshot.val().content.footerURL
                            : typeshareURL,
                          sidebarText: snapshot.hasChild('content/sidebarText')
                            ? snapshot.val().content.sidebarText
                            : displayName,
                        },
                        timestamp: {
                          unix: new Date().getTime(),
                        },
                      });
                      setLoading(false);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                firebase
                  .database()
                  .ref('gallery')
                  .child(gallerySnap.val())
                  .child(uid)
                  .child('displayName')
                  .once('value')
                  .then((gallerySnap3) => {
                    if (gallerySnap3.exists()) {
                      displayName = gallerySnap3.val();
                    } else {
                    }

                    var typeshareURL = `typeshare.co/${gallerySnap.val()}`;

                    dbRef
                      .child('theme')
                      .once('value')
                      .then((snapshot) => {
                        if (snapshot.exists()) {
                          dbRef
                            .child('theme/style')
                            .once('value')
                            .then((snapshot2) => {
                              var hasCT = snapshot2.hasChild('customTheme');
                              setHasCustomTheme(hasCT);
                              setCustomTheme(snapshot.val().style.customTheme);
                              setTypeshareContentDraft({
                                content: {
                                  V2Editor: true,
                                  titleText: '',
                                  bodyText: '',
                                  author: snapshot.hasChild('content/author')
                                    ? snapshot.val().content.author
                                    : displayName,
                                  footer: snapshot.hasChild('content/footer')
                                    ? snapshot.val().content.footer
                                    : 'Made with Typeshare',
                                  footerURL: snapshot.hasChild(
                                    'content/footerURL',
                                  )
                                    ? snapshot.val().content.footerURL
                                    : typeshareURL,
                                  sidebarText: snapshot.hasChild(
                                    'content/sidebarText',
                                  )
                                    ? snapshot.val().content.sidebarText
                                    : displayName,
                                },
                              });
                              setLoading(false);
                            });
                        } else {
                          setTypeshareContentDraft({
                            content: {
                              V2Editor: true,
                              titleText: '',
                              bodyText: '',
                              author: displayName,
                              footer: 'Made with Typeshare',
                              footerURL: snapshot.hasChild('content/footerURL')
                                ? snapshot.val().content.footerURL
                                : typeshareURL,
                              sidebarText: snapshot.hasChild(
                                'content/sidebarText',
                              )
                                ? snapshot.val().content.sidebarText
                                : displayName,
                            },
                            timestamp: {
                              unix: new Date().getTime(),
                            },
                          });
                          setLoading(false);
                        }
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  });
              }
            });
        }
      });
  };

  const createTypeshareBlank = (typeshareContent, type = 'essay') => {
    setCreatingPost(true);
    firebase.analytics().logEvent('create_typeshare', { type: 'blank' });
    firebase
      .database()
      .ref('users/' + userID + '/typeshares/')
      .push(typeshareContent)
      .then((snap) => {
        firebase
          .database()
          .ref('users/' + userID + '/typeshares/')
          .child(snap.key)
          .update({
            timestamp: {
              unix: new Date().getTime(),
            },
            settings: {
              twitterFormat: type === 'thread' ? 'thread' : null,
              activePreview: type === 'thread' ? 'twitter' : null,
            },
          })
          .then(() => {
            router.push({
              pathname: '/editor/[pid]',
              query: { pid: snap.key },
            });
          });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getEditions();
  }, []);

  async function getEditions() {
    try {
      const { data } = await client.post('/api/library/get-editions');
      if (data.success) {
        setEditions(data.editions);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    setTemplates(null);
    activeTab && setActivePack(null);
    activeTab && getTemplatesByType(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setTemplates(null);
    activePack && setActiveTab(null);
    activePack && getTemplatesByPack(activePack);
  }, [activePack]);

  async function getTemplatesByType(type) {
    try {
      const { data } = await client.post('/api/library/get-templates', {
        type,
      });
      if (data.success) {
        setTemplates(data.templates);
        setActiveType(data.type);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getTemplatesByPack(pack) {
    const baseRef = firebase.database().ref('templates2');
    const packRef = await baseRef
      .child('packs')
      .child('packs')
      .child(pack)
      .once('value');

    var packVal = packRef.val();

    const templateIDs = packRef.hasChild('templates')
      ? Object.keys(packVal.templates)
      : null;

    const templates = templateIDs
      ? await Promise.all(
          templateIDs.map(async (templateID) => {
            const ref = baseRef.child('templates').child(templateID);
            const snapshot = await ref.once('value');

            const type = snapshot.val().meta.type;

            const formatTitleRef = await baseRef
              .child('types')
              .child(type)
              .child('meta')
              .child('title')
              .once('value');

            const formatTitle = formatTitleRef.val();

            const template = {
              ...snapshot.val(),
              meta: {
                ...snapshot.val().meta,
                formatTitle,
              },
            };

            return template;
          }),
        )
      : [];

    //get creator's name and photo from firebase and update templates
    const creators = await Promise.all(
      templates.map(async (template) => {
        const ref = baseRef.child('creators').child(template.meta.creator);
        const snapshot = await ref.once('value');
        return snapshot.val();
      }),
    );

    templates.forEach((template, index) => {
      template.meta.creator_name = creators[index].name;
      template.meta.creator_photo = creators[index].photo;
    });

    //get creator's name and photo from firebase and update pack
    const creatorRef = await baseRef
      .child('creators')
      .child(packVal.meta.creator)
      .once('value');
    packVal.meta.creator_name = creatorRef.val().name;
    packVal.meta.creator_photo = creatorRef.val().photo;

    setTemplates(templates);
  }

  async function incrementCount(slug, pack) {
    try {
      await client.post('/api/templates/increment', { slug, pack });
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  const createTypeshare = async (templateMeta) => {
    if (!userID) {
      // setLoginModalOpen(true)
    } else {
      //get the template data from firebase

      const slug = templateMeta.meta.slug;

      const templateRef = await firebase
        .database()
        .ref('templates2')
        .child('templates')
        .child(slug)
        .once('value');
      const template = templateRef.val();

      const metaTitle = template.meta.title;
      const data = template.data;
      const pack = template.meta?.pack ? template.meta.pack : null;

      firebase
        .analytics()
        .logEvent('create_typeshare', { type: 'template', name: metaTitle });
      await incrementCount(slug, pack);

      firebase
        .database()
        .ref('users/' + userID + '/typeshares/')
        .push(data)
        .then((snap) => {
          firebase
            .database()
            .ref('users/' + userID + '/typeshares/')
            .child(snap.key)
            .update({
              timestamp: {
                unix: new Date().getTime(),
              },
            })
            .then(() => {
              cio.trackEvent(userID, {
                name: 'create_post',
                data: {
                  type: 'template',
                  name: metaTitle,
                  created_at: Math.floor(Date.now() / 1000),
                  id: snap.key,
                },
              });

              {
                tour
                  ? router.push({
                      pathname: '/editor/[pid]',
                      query: {
                        pid: snap.key,
                        tour: true,
                      },
                    })
                  : router.push({
                      pathname: '/editor/[pid]',
                      query: {
                        pid: snap.key,
                      },
                    });
              }
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const createBlankTypeshare = async (type) => {
    if (!userID) {
      // setLoginModalOpen(true)
    } else {
      firebase.analytics().logEvent('create_typeshare', { type: 'blank' });

      firebase
        .database()
        .ref('users/' + userID + '/typeshares')
        .push({
          content: {
            V2Editor: true,
            bodyText: '',
            titleText: '',
          },
        })
        .then((snap) => {
          firebase
            .database()
            .ref('users/' + userID + '/typeshares/')
            .child(snap.key)
            .update({
              timestamp: {
                unix: new Date().getTime(),
              },
              settings: {
                twitterFormat:
                  type === 'atomic-essay' || type === 'subatomic-essay'
                    ? 'image'
                    : 'thread',
                activePreview:
                  type === 'twitter' ||
                  type === 'atomic-essay' ||
                  type === 'subatomic-essay'
                    ? 'twitter'
                    : type === 'linkedin-post'
                    ? 'linkedin'
                    : 'twitter',
              },
            })
            .then(() => {
              router.push({
                pathname: '/editor/[pid]',
                query: { pid: snap.key },
              });
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const closeTour = () => {
    setTourStage(null);
    setTimeout(() => {
      setTour(false);
    }, 200);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 overflow-y-auto"
          onClose={tour ? () => null : setOpen}
        >
          <UpgradePlanModal
            STRIPE_PK={STRIPE_PK}
            isOpen={planModalOpen}
            setIsOpen={setPlanModalOpen}
          />

          <div className="flex min-h-screen items-end text-center font-sans antialiased sm:block sm:items-center sm:justify-center sm:p-0">
            <Transition.Child
              show={open}
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden align-bottom sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              show={open}
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="sm:opacity-0 translate-y-[600px] sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="sm:opacity-0 translate-y-[800px] sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block w-full transform overflow-hidden rounded-t-2xl bg-white pb-16 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-6xl sm:rounded-2xl sm:pb-0 sm:align-middle">
                <div className="sticky top-0 z-10 hidden w-full sm:flex">
                  <button
                    onClick={() => setOpen(false)}
                    className="absolute top-2 right-2 rounded-full bg-gray-100 p-2 text-gray-500 duration-200 hover:bg-gray-200 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                {/*           <div className='w-full items-center p-6 flex flex-row justify-between border-b border-gray-100'>

                                <p className='font-sanse text-base font-semibold text-gray-800'>Choose a Starting Point</p>

                                <button onClick={() => setOpen(false)} ref={cancelButtonRef} className={" text-gray-500 transform rounded-full p-1.5 bg-gray-200 duration-200 hover:scale-105 active:scale-100 hover:text-gray-900 outline-none hover:outline-none focus:outline-none"}>
                                    <XIcon className='w-4 h-4' />
                                </button>

                            </div> */}

                <div className="flex h-full flex-col justify-start space-x-0 sm:flex-row">
                  <div
                    className={`w-full border-gray-200 border-opacity-50 bg-gray-50 sm:w-64 sm:border-r md:w-72 ${
                      tour && 'z-20'
                    } relative flex flex-row items-center justify-between sm:max-h-[700px] sm:min-h-[700px] sm:flex-col sm:items-stretch`}
                  >
                    <div
                      className={` w-full md:w-auto  md:pb-0 ${
                        !tour && 'overflow-y-scroll'
                      }`}
                    >
                      {/*   <p className='font-sans tracking-tight text-base font-semibold text-gray-800 mb-4'>Choose a Starting Point</p> */}
                      {/*   <div className="mt-1 relative rounded-md">
                                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </div>
                                            <input
                                                type="search"
                                                name="search"
                                                id="search"
                                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 border-gray-100 bg-gray-50 rounded-md"
                                                placeholder="Search..."
                                            />
                                        </div> */}
                      {/* <div className='w-full bg-gray-100 h-[1px] rounded-full my-4' /> */}

                      {/* <div className='flex md:flex-col md:space-y-1 flex-row space-x-1 md:space-x-0 w-full'>

                                            <button onClick={() => setActiveTab("essays")} className={`w-full  ${activeTab === "essays" ? "bg-blue-50 text-blue-500" : "hover:text-gray-800 hover:bg-gray-100 text-gray-600"} rounded-lg whitespace-nowrap px-4 text-left font-medium py-2 duration-200`}>Atomic Essays</button>
                                            <button onClick={() => setActiveTab("subatomic")} className={`w-full  ${activeTab === "subatomic" ? "bg-blue-50 text-blue-500" : "hover:text-gray-800 hover:bg-gray-100 text-gray-600"} rounded-lg whitespace-nowrap px-4 text-left font-medium py-2 duration-200`}>Subatomic Essays</button>
                                            <button onClick={() => setActiveTab("threads")} className={`w-full  ${activeTab === "threads" ? "bg-blue-50 text-blue-500" : "hover:text-gray-800 hover:bg-gray-100 text-gray-600"} rounded-lg whitespace-nowrap px-4 text-left font-medium py-2 duration-200`}>Twitter Threads</button>
  

                                        </div> */}
                      <div className="flex w-full p-4 sm:hidden">
                        <div className="flex w-full flex-row items-center justify-between border-b border-gray-100 pb-4">
                          <div className="flex flex-col space-y-0">
                            <p className="text-base font-semibold tracking-tightish text-gray-900">
                              Create New Post
                            </p>
                            <p className="text-xs text-gray-500">
                              Select a template or create a blank post
                            </p>
                          </div>

                          <button
                            onClick={() => setOpen(false)}
                            className="rounded-full bg-gray-100 p-2 text-gray-500 duration-200 hover:bg-gray-200 focus:outline-none"
                          >
                            <XIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </div>

                      <div className="flex w-full flex-col">
                        <div>
                          <p className="sticky top-0 z-10 border-gray-200 border-opacity-50 bg-gray-50 bg-opacity-50 px-4 text-xs font-medium uppercase text-gray-600 backdrop-blur-md backdrop-filter sm:border-b sm:px-6 sm:pt-4 sm:pb-3 sm:text-[13px] sm:normal-case sm:text-gray-900 ">
                            Browse by Type
                          </p>

                          <div className="pt-2 pb-0 sm:px-4 sm:pb-2">
                            <CategoriesTabs
                              modal={true}
                              activeTab={activeTab}
                              click={(tab) => setActiveTab(tab)}
                            />
                          </div>
                        </div>
                        <div className="mt-4 sm:mt-0">
                          <p className="sticky top-0 z-10 border-gray-200 border-opacity-50 bg-gray-50 bg-opacity-50 px-4 text-xs font-medium uppercase text-gray-600 backdrop-blur-md backdrop-filter sm:border-b sm:px-6 sm:pt-4 sm:pb-3 sm:text-[13px] sm:normal-case sm:text-gray-900">
                            Browse by Pack
                          </p>

                          <div className="py-2 sm:px-4">
                            <div className="flex w-full flex-row items-start space-x-1 overflow-auto px-4 pb-2 no-scrollbar sm:flex-col sm:space-y-0.5 sm:space-x-0 sm:px-0 sm:pb-8 sm:show-scrollbar">
                              {editionPacks &&
                                editionPacks.map((edition, i) => {
                                  const packsHere = edition.packs.map(
                                    (pack, index) => {
                                      //check to see if the date in unlockedPacks.(pack.meta.slug) is less than the date today in the format of YYYY-MM-DD
                                      const isUnlocked = unlockedPacks
                                        ? unlockedPacks[pack.meta.slug]
                                            ?.unlocked
                                          ? true
                                          : false
                                        : false;

                                      return (
                                        /*   i !== 0 && */
                                        <button
                                          key={pack.meta.slug}
                                          onClick={() =>
                                            setActivePack(pack.meta.slug)
                                          }
                                          className={`flex w-full transform flex-row items-center justify-center space-x-1 rounded-xl border px-2  py-1 duration-100 active:scale-[.98] sm:items-center sm:justify-between  sm:p-2 ${
                                            activePack === pack.meta.slug
                                              ? ' border-gray-200 border-opacity-50 bg-white'
                                              : ' border-transparent hover:bg-gray-200 hover:bg-opacity-50'
                                          }`}
                                        >
                                          <div className="flex w-full flex-row items-center justify-start space-x-2 overflow-hidden">
                                            <p className="text-2xl">
                                              {pack.meta.icon}
                                            </p>
                                            <div className="flex w-full flex-col items-start justify-start  -space-y-0.5 overflow-hidden text-left  ">
                                              <span className="whitespace-nowrap text-sm font-medium">
                                                {pack.meta.title}
                                              </span>
                                              <p className="hidden w-full truncate text-xs text-gray-500 sm:block">
                                                {pack.meta.description}
                                              </p>
                                            </div>
                                          </div>
                                          {!isUnlocked && (
                                            <LockClosedIcon className="h-[14px] w-[14px] text-gray-900" />
                                          )}
                                        </button>
                                      );
                                    },
                                  );
                                  return (
                                    <div
                                      key={edition.edition.slug}
                                      className="flex w-full flex-row sm:flex-col"
                                    >
                                      <p
                                        className={`mb-1 hidden px-2 font-mono text-xs uppercase text-gray-500 sm:flex ${
                                          i !== 0 ? 'mt-4' : 'mt-1'
                                        }`}
                                      >
                                        {edition.edition.title}
                                      </p>
                                      {packsHere}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sticky bottom-0 z-10 hidden w-auto bg-gray-50 bg-opacity-50 px-4 py-4 backdrop-blur-md backdrop-filter sm:flex md:w-full md:px-4 md:py-4">
                        <OnboardingPopover
                          close={() => closeTour()}
                          //offset={[0, 40]}
                          type={'tip'}
                          nextText={'Save me from the blank page! 🙏'}
                          next={() => setTourStage(tourStage + 1)}
                          show={tour}
                          step={2}
                          totalSteps={8}
                          title={
                            'You can create a blank post - but staring at a blank page is never easy!'
                          }
                          placement={'top'}
                          isOpen={tourStage === 0}
                          setIsOpen={setTourStage}
                          className={'w-full'}
                          disableClick={true}
                        >
                          <Button
                            click={() =>
                              !tour &&
                              createTypeshareBlank(typeshareContentDraft)
                            }
                            width={'full'}
                            size={'mini'}
                            prefix={<PlusIcon className="-mr-2 h-5 w-5" />}
                          >
                            <p className="font-sans">New Blank Post</p>
                          </Button>
                        </OnboardingPopover>
                      </div>
                    </div>
                  </div>

                  <div className="w-full items-start justify-start overflow-auto bg-white pt-4 sm:max-h-[700px] sm:min-h-[700px] sm:flex-1 sm:p-10">
                    <div className="mb-4 flex flex-row items-center justify-between">
                      <div className="flex flex-col space-y-0 px-4 sm:px-0">
                        <h2 className="text-base font-semibold tracking-tight text-gray-900 sm:text-lg">
                          {activeTab === 'atomic-essay'
                            ? 'Atomic Essay Templates'
                            : activeTab === 'tweet'
                            ? 'Tweet Templates'
                            : activeTab === 'thread'
                            ? 'Thread Templates'
                            : activeTab === 'linkedin-post'
                            ? 'LinkedIn Post Templates'
                            : activeTab === 'subatomic-essay'
                            ? 'Subatomic Essay Templates'
                            : //find the pack name from the slug in the first index of the array of editions
                              editionPacks
                                .find((edition) =>
                                  edition.packs.find(
                                    (pack) => pack.meta.slug === activePack,
                                  ),
                                )
                                .packs.find(
                                  (pack) => pack.meta.slug === activePack,
                                ).meta.title + ' - Template Pack'}
                        </h2>
                        {activeTab === 'atomic-essay' ||
                        activeTab === 'tweet' ||
                        activeTab === 'thread' ||
                        activeTab === 'linkedin-post' ||
                        activeTab === 'subatomic-essay' ? null : (
                          <p className="flex text-xs text-gray-500 sm:hidden">
                            {
                              editionPacks
                                .find((edition) =>
                                  edition.packs.find(
                                    (pack) => pack.meta.slug === activePack,
                                  ),
                                )
                                .packs.find(
                                  (pack) => pack.meta.slug === activePack,
                                ).meta.description
                            }
                          </p>
                        )}
                      </div>
                    </div>

                    {activeTab && (
                      <div>
                        <CategoryResults
                          showTourPop={tourStage === 1}
                          tour={tour}
                          closeTour={() => closeTour()}
                          productName={productName}
                          createBlankTypeshare={(type) =>
                            createBlankTypeshare(type)
                          }
                          setPlanModalOpen={
                            !unlockedPacks && productName !== 'Free Plan'
                              ? setTemplatePackSelectorOpen
                              : setPlanModalOpen
                          }
                          packUnlocks={unlockedPacks}
                          data={activeType}
                          modal={true}
                          createTypeshare={(template) =>
                            createTypeshare(template)
                          }
                          templates={templates}
                        />
                      </div>
                    )}
                    {activePack &&
                      //find the pack from the slug in the first index of the array of editions and map the editions pack
                      editionPacks
                        .find((edition) =>
                          edition.packs.find(
                            (pack) => pack.meta.slug === activePack,
                          ),
                        )
                        .packs.map((pack) => {
                          pack = pack.meta.slug === activePack ? pack : null;

                          const isUnlocked = pack
                            ? unlockedPacks
                              ? unlockedPacks[pack.meta.slug]?.unlocked
                                ? true
                                : false
                              : false
                            : false;
                          const unlocksAt = pack
                            ? unlockedPacks
                              ? unlockedPacks[pack.meta.slug]?.date
                              : null
                            : null;

                          return pack ? (
                            pack.meta.type === 'format' ? (
                              <div
                                className={`grid w-full grid-cols-6 gap-4 overflow-x-auto sm:overflow-hidden lg:grid-cols-10 ${
                                  !isUnlocked ? 'pb-0' : 'pb-8'
                                }  sm:pb-0`}
                              >
                                <div
                                  className={`col-span-6 hidden items-start sm:flex md:col-span-4 lg:col-span-4`}
                                >
                                  <SinglePack
                                    theme={'light'}
                                    STRIPE_PK={STRIPE_PK}
                                    pack={pack}
                                    slug={pack.meta.slug}
                                    title={pack.meta.title}
                                    icon={pack.meta.icon}
                                    description={pack.meta.description}
                                    locked={!isUnlocked}
                                    creator_name={pack.meta.creator_name}
                                    creator_photo={pack.meta.creator_photo}
                                    uses={pack.meta.uses}
                                    unlocksAt={unlocksAt}
                                    showView={false}
                                    plan={productName}
                                    showBuy={pack.price ? true : false}
                                  />
                                </div>

                                <div className=" col-span-6 w-full">
                                  <PackHorizontalCards
                                    upgradeClicked={setPlanModalOpen}
                                    locked={!isUnlocked}
                                    unlocksAt={unlocksAt}
                                    modal={true}
                                    templates={templates}
                                    createTypeshare={(template) =>
                                      createTypeshare(template)
                                    }
                                    plan={productName}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={`grid w-full grid-cols-6 gap-4 overflow-x-auto sm:overflow-hidden lg:grid-cols-10 ${
                                  !isUnlocked ? 'pb-0' : 'pb-8'
                                }  sm:pb-0`}
                              >
                                <div
                                  className={`col-span-6 hidden items-start sm:flex md:col-span-4 lg:col-span-4`}
                                >
                                  <SinglePack
                                    theme={'dark'}
                                    STRIPE_PK={STRIPE_PK}
                                    pack={pack}
                                    slug={pack.meta.slug}
                                    title={pack.meta.title}
                                    icon={pack.meta.icon}
                                    description={pack.meta.description}
                                    locked={!isUnlocked}
                                    creator_name={pack.meta.creator_name}
                                    creator_photo={pack.meta.creator_photo}
                                    uses={pack.meta.uses}
                                    showView={
                                      pack?.price && !isUnlocked ? true : false
                                    }
                                    price={pack?.price}
                                    showBuy={pack.price ? true : false}
                                    passSuccess={() => checkoutSuccess()}
                                  />
                                </div>

                                <div className="col-span-6">
                                  <CategoryResults
                                    tourType={tourType}
                                    showTourPop={tourStage === 1}
                                    tour={tour}
                                    closeTour={() => closeTour()}
                                    hasPricing={pack?.price}
                                    packUnlocks={unlockedPacks}
                                    createBlankTypeshare={(type) =>
                                      createBlankTypeshare(type)
                                    }
                                    setPlanModalOpen={setPlanModalOpen}
                                    createTypeshare={(template) =>
                                      createTypeshare(template)
                                    }
                                    packView={true}
                                    packModal={true}
                                    templates={templates}
                                  />
                                </div>
                              </div>
                            )
                          ) : null;
                        })}

                    {/* {editionPacks && editionPacks.map((edition, index) => {

                                        return (
                                            index !== 0 &&
                                    

                                                <TemplatePacks packs={edition.packs} modal={true} />


                                          

                                        )
                                    })
                                    } */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
