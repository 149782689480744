import Head from 'next/head';
import { useEffect, useState } from 'react';
import 'tailwindcss/tailwind.css';
import '../styles/tailwind/before.css';
import '../styles/globals.css';
import '../styles/tailwind/fonts.css';
import '../styles/tailwind/after.css';

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { useRouter } from 'next/router';
import AcademyLayout from '../components/Academy/layout';
import NavContainerSidebar from '../components/NavContainerSidebar';

import * as Sentry from '@sentry/browser';

import { cio } from '../utils/customer-io';
import { getActivePlan } from '../utils/helpers';

function MyApp({ Component, pageProps }) {
  const STRIPE_PK = pageProps?.STRIPE_PK;

  const [productName, setProductName] = useState(null);
  const [useTransition, setUseTransition] = useState(false);
  const [userID, setUserID] = useState(null);
  const [isShip30, setShip30] = useState(false);

  const router = useRouter();

  async function linkCustomerID(userID, customerID) {
    try {
      const { data } = await client.post('/api/stripe-customer-metadata', {
        userID,
        customerID,
      });
      return data;
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        return err.response.data;
      }
      return {};
    }
  }

  const noHeader = [
    '/editor/[slug]',
    '/thread/[slug]',
    '/new-thread-editor/[slug]',
    '/welcome',
    '/pro',
    '/plan',
    '/',
    '/[slug]/posts/[post]',
    '/[slug]/threads/[thread]',
    '/new-landing',
    '/new-nav',
    '/new-sidebar',
    '/new-editor/[slug]',
    '/new-editor-test/[slug]',
    '/library/test',
    '/connect/ship30',
    '/[slug]/essay-images/[user]/[id]',
  ];

  /*   const justBanner = [
      '/thread/[slug]',
    ] */

  const transparentHeader = [
    '/integrations',
    '/library',
    '/[slug]/collections',
    '/[slug]/essays',
    '/[slug]/threads',
    '/theme',
    '/pro',
    '/analytics',
    '/[slug]/collections/[collection]',
    '/notifications',
    '/templates',
    '/ship30',
  ];

  async function getActiveProduct(userID) {
    var ref = firebase.database().ref('users/' + userID + '/plan');
    ref.on(
      'value',
      async function (snapshot) {
        if (snapshot.exists()) {
          if (snapshot.hasChild('productName')) {
            var c = snapshot.hasChild('confirmed');
            setProductName(snapshot.val().productName);
            setUseTransition(true);
            if (snapshot.hasChild('stripe')) {
              var hasLinkedID = snapshot.hasChild('stripe/linkedID');
              if (hasLinkedID === false) {
                const link = await linkCustomerID(
                  userID,
                  snapshot.val().stripe.customerID,
                );
                if (link.success === true) {
                  //update DB
                  var updateRef = firebase
                    .database()
                    .ref('users/' + userID + '/plan/stripe');
                  updateRef.update({
                    linkedID: true,
                  });
                }
              }
            }
          } else {
            setProductName('Free Plan');
            setUseTransition(true);
          }
        } else {
          setProductName('Free Plan');
          setUseTransition(true);
        }
      },
      function (errorObject) {},
    );
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        //setUseTransition(false)
        console.log('HERE');
        const signedUpDate = userInfo.metadata.creationTime;

        setUserID(userInfo.uid);
        getUserGallery(userInfo.uid, signedUpDate);
        getActiveProduct(firebase.auth().currentUser.uid);
        checkIfShip30Member(firebase.auth().currentUser.uid);
      } else {
        setUserID(null);
        setUseTransition(true);
      }
    });
    return function cleanup() {
      if (firebase.auth().currentUser) {
        var ref = firebase
          .database()
          .ref('users/' + firebase.auth().currentUser.uid + '/gallery/')
          .child('username');
        var ref2 = firebase
          .database()
          .ref('users/' + firebase.auth().currentUser.uid + '/plan');
        ref.off('value');
        ref2.off('value');
      }
    };
  }, []);

  function getUserGallery(uid, signedUpDate) {
    var ref = firebase
      .database()
      .ref('users/' + uid + '/gallery/')
      .child('username');
    ref.once('value', (querySnapshot) => {
      if (querySnapshot.exists()) {
        createCust(querySnapshot.val(), uid, signedUpDate);
        Sentry.setUser({ username: querySnapshot.val(), id: uid });
      } else {
        //setPhotoUrl(firebase.auth().currentUser.photoURL);
        //log user out cause they have no gallery - redirect to onboarding /welcome
        //this runs right after a new user signs up - and it shouldnt - so im commenting it out - this may create a bug
        //router.push("/library")
      }
    });
  }

  const checkIfShip30Member = (uid) => {
    var ref = firebase
      .database()
      .ref('users/' + uid + '/meta')
      .child('connectedGroups');
    ref.once('value', function (snap) {
      if (snap.hasChild('ship30aug')) {
        setShip30(true);
      } else {
        setShip30(false);
      }
    });
  };

  //MAIN APP
  const firebaseConfig = {
    apiKey: 'AIzaSyA64Cm0bTMY9Rinvha_z3ENAUhkJssFCO8',
    authDomain: 'auth.typeshare.co',
    projectId: 'typeshare-2027d',
    storageBucket: 'typeshare-2027d.appspot.com',
    databaseURL: 'https://typeshare-2027d-default-rtdb.firebaseio.com/',
    messagingSenderId: '305996917305',
    appId: '1:305996917305:web:704a11e8f876840e87bbe8',
    measurementId: 'G-LX68JP4ZZK',
  };

  //TEST APP
  const firebaseConfigStaging = {
    apiKey: 'AIzaSyD5pDmQa1lIAYiW1qd7Swez2gaXG-ggWtc',
    authDomain: 'test-88a5a.firebaseapp.com',
    databaseURL: 'https://test-88a5a-default-rtdb.firebaseio.com',
    projectId: 'test-88a5a',
    storageBucket: 'test-88a5a.appspot.com',
    messagingSenderId: '952460692987',
    appId: '1:952460692987:web:0b0eb7f65c0e3f6767cf5e',
  };

  if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }
  } else {
    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfigStaging);
    }
  }

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
      firebase.analytics();
    }
  }, []);

  async function createCust(slug, uid, signedUpDate) {
    if (uid) {
      const exists = await cio.fetchCustomer(uid);

      if (exists?.exists) {
        //console.log("customer exists")
        const data = exists.data;
        const hasStatus = data?.customer?.attributes?.status ? true : false;
        const hasEmail = data?.customer?.attributes?.email ? true : false;

        if (hasStatus && hasEmail) {
          return;
        } else {
          if (hasStatus === false) {
            getActivePlan(uid, async function (plan) {
              var planName = plan.name.toLocaleLowerCase();
              var status = plan.status ? plan.status : 'free';

              if (planName === 'premier') {
                status = status + '_premier';
              }

              const cust = await cio.updateCustomer(uid, {
                status: status,
              });

              if (cust) {
                console.log('status updated');
              } else {
                console.log('status not updated');
              }
            });
          }
        }

        if (hasEmail === false) {
          const emailRef = await firebase
            .database()
            .ref('users/' + uid + '/meta/')
            .child('email')
            .once('value');
          var email = emailRef?.val();

          if (!email) {
            email = firebase?.auth()?.currentUser?.email;
          }
          const cust = await cio.updateCustomer(uid, {
            email: email,
          });

          if (cust) {
            console.log('email updated');
          } else {
            console.log('email not updated');
          }
        }

        return;
      } else {
        // console.log("customer does not exist")

        const ref = await firebase
          .database()
          .ref('gallery/' + slug.toLocaleLowerCase() + '/' + uid)
          .child('meta')
          .once('value');
        if (ref.exists() && ref.hasChild('displayName')) {
          //new meta format

          //get firebase auth user email

          const integrationsRef = await firebase
            .database()
            .ref('users/' + uid)
            .child('integrations')
            .once('value');

          const hasMedium = integrationsRef.hasChild('medium') ? true : '';
          const hasLinkedin = integrationsRef.hasChild('linkedin') ? true : '';

          const emailRef = await firebase
            .database()
            .ref('users/' + uid + '/meta/')
            .child('email')
            .once('value');
          var email = emailRef?.val();

          if (!email) {
            email = firebase?.auth()?.currentUser?.email;
          }

          const name = ref.val()?.displayName;
          const twitterUrl = ref.val()?.twitterURL;

          getActivePlan(uid, async function (plan) {
            var planName = plan.name.toLocaleLowerCase();

            var status = plan.status ? plan.status : 'free';

            if (planName === 'premier') {
              status = status + '_premier';
            }

            if (planName === 'free plan') {
              planName = 'free';
            } else if (planName === 'expert') {
              planName = 'pro';
            } else if (planName === 'expert lifetime') {
              planName = 'lifetime';
            }

            const cust = await cio.updateCustomer(uid, {
              email: email,
              name: name,
              username: slug,
              url: 'https://typeshare.co/' + slug,
              twitterHandle: twitterUrl,
              hasMedium: hasMedium,
              hasLinkedIn: hasLinkedin,
              created_at: new Date(signedUpDate).getTime() / 1000,
              plan: planName,
              status: status,
            });

            if (cust) {
              console.log('customer created from app');
              return;
            } else {
              console.log('customer not created');
              return;
            }
          });
        } else {
          const ref2 = await firebase
            .database()
            .ref('gallery/' + slug.toLocaleLowerCase() + '/' + uid)
            .once('value');
          if (ref2.exists()) {
            const emailRef = await firebase
              .database()
              .ref('users/' + uid + '/meta/')
              .child('email')
              .once('value');

            const integrationsRef = await firebase
              .database()
              .ref('users/' + uid)
              .child('integrations')
              .once('value');

            const hasMedium = integrationsRef.hasChild('medium') ? true : '';
            const hasLinkedin = integrationsRef.hasChild('linkedin')
              ? true
              : '';

            var email = emailRef?.val();

            if (!email) {
              email = firebase?.auth()?.currentUser?.email;
            }

            const name = ref2.val()?.displayName;
            const twitterUrl = ref2.val()?.twitterURL;

            getActivePlan(uid, async function (plan) {
              var planName = plan.name.toLocaleLowerCase();

              var status = plan.status ? plan.status : 'free';

              if (planName === 'premier') {
                status = status + '_premier';
              }

              if (planName === 'free plan') {
                planName = 'free';
              } else if (planName === 'expert') {
                planName = 'pro';
              } else if (planName === 'expert lifetime') {
                planName = 'lifetime';
              }

              const cust = await cio.updateCustomer(uid, {
                email: email,
                name: name,
                username: slug,
                url: 'https://typeshare.co/' + slug,
                twitterHandle: twitterUrl,
                hasLinkedIn: hasLinkedin,
                hasMedium: hasMedium,
                created_at: new Date(signedUpDate).getTime() / 1000,
                plan: planName,
                status: status,
              });

              if (cust) {
                console.log('customer created from app');
                return;
              } else {
                console.log('customer not created');
                return;
              }
            });
          } else {
            console.log('no customer meta exists');
            return;
          }
        }
      }
    }
  }

  //Customer.io Track Page View
  useEffect(() => {
    if (router.pathname && userID) {
      cio.trackPageView({
        pageName: router.pathname,
      });
    }
  }, [router.pathname, userID]);

  return (
    <>
      {/* <img src={"/beams.png"} className={` ${router.query.ref === "welcome" ? "flex" : "hidden"} object-cover absolute w-full h-full opacity-50 z-0`} /> */}
      <Head>
        <script src="https://js.stripe.com/v3/"></script>
        <link
          crossOrigin="anonymous"
          rel="preconnect"
          href="https://fonts.gstatic.com"
        />
        <link
          crossOrigin="anonymous"
          href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          crossOrigin="anonymous"
          href="https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap"
          rel="stylesheet"
        />
        <link
          crossOrigin="anonymous"
          href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <link
          crossOrigin="anonymous"
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          crossOrigin="anonymous"
          href="https://fonts.googleapis.com/css2?family=Amiri:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          crossOrigin="anonymous"
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="/fonts.css"
          type="text/css"
          charSet="utf-8"
        />
        <link
          crossOrigin="anonymous"
          href="https://fonts.googleapis.com/css2?family=Mali:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Permanent+Marker&display=swap"
          rel="stylesheet"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
          }}
        />
        <script async src="https://r.wdfl.co/rw.js" data-rewardful="9781a4" />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <meta name="application-name" content="Typeshare" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-title" content="Typeshare" />
        <meta
          name="description"
          content="Templates so you never stare at a blank page again. One-click publishing to millions of readers. Analytics to track performance."
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        {/* <meta name='msapplication-config' content='/icons/browserconfig.xml' /> */}
        <meta name="msapplication-TileColor" content="#3B82F6" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#fafaf9" />

        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.ico" />
        {/* 
      <link rel='icon' type='image/png' sizes='32x32' href='/pwa/icons/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/pwa/icons/favicon-16x16.png' /> */}

        <link rel="manifest" href="/manifest.json" />
        <link rel="mask-icon" href="/pwa/icons/icon.svg" color="#3B82F6" />
        <link rel="shortcut icon" href="/favicon.ico" />
        {/*       <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500' />
         */}
        {/*   <meta name='twitter:card' content='summary' />
      <meta name='twitter:url' content='https://typeshare.co' />
      <meta name='twitter:title' content='Typeshare | Everything you need to start writing online.' />
      <meta name='twitter:description' content='Templates so you never stare at a blank page again. One-click publishing to millions of readers. Analytics to track performance.' />
      <meta name="twitter:image" content="https://typeshare.co/ts2-og.png" />
      <meta name='twitter:creator' content='@typeshare_co' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content='Typeshare' />
      <meta property='og:description' content='Templates so you never stare at a blank page again. One-click publishing to millions of readers. Analytics to track performance.' />
      <meta property='og:site_name' content='Typeshare | Everything you need to start writing online.' />
      <meta property='og:url' content='https://typeshare.co' />
      <meta property='og:image' content='https://typeshare.co/icons/apple-touch-icon.png' /> */}

        <link
          rel="apple-touch-startup-image"
          href="/pwa/startup-images/apple_splash_2048.png"
          sizes="2048x2732"
        />
        <link
          rel="apple-touch-startup-image"
          href="/pwa/startup-images/apple_splash_1668.png"
          sizes="1668x2224"
        />
        <link
          rel="apple-touch-startup-image"
          href="/pwa/startup-images/apple_splash_1536.png"
          sizes="1536x2048"
        />
        <link
          rel="apple-touch-startup-image"
          href="/pwa/startup-images/apple_splash_1125.png"
          sizes="1125x2436"
        />
        <link
          rel="apple-touch-startup-image"
          href="/pwa/startup-images/apple_splash_1242.png"
          sizes="1242x2208"
        />
        <link
          rel="apple-touch-startup-image"
          href="/pwa/startup-images/apple_splash_750.png"
          sizes="750x1334"
        />
        <link
          rel="apple-touch-startup-image"
          href="/pwa/startup-images/apple_splash_640.png"
          sizes="640x1136"
        />

        {process.env.NEXT_PUBLIC_APP_ENV === 'production' ? (
          <>
            <link
              rel="apple-touch-icon"
              href="/pwa/icons/touch-icon-iphone.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/pwa/icons/touch-icon-ipad.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/pwa/icons/touch-icon-iphone-retina.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="167x167"
              href="/pwa/icons/touch-icon-ipad-retina.png"
            />
          </>
        ) : (
          <>
            <link
              rel="apple-touch-icon"
              href="/pwa/icons-staging/touch-icon-iphone.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="152x152"
              href="/pwa/icons-staging/touch-icon-ipad.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/pwa/icons-staging/touch-icon-iphone-retina.png"
            />
            <link
              rel="apple-touch-icon"
              sizes="167x167"
              href="/pwa/icons-staging/touch-icon-ipad-retina.png"
            />
          </>
        )}
      </Head>

      {noHeader.includes(router.pathname) ? (
        <div className={`z-20`}>
          <Component {...pageProps} productName={productName} userID={userID} />
        </div>
      ) : router.pathname === '/academy' ||
        router.pathname === '/academy/[slug]' ? (
        <AcademyLayout>
          <Component {...pageProps} productName={productName} userID={userID} />
        </AcademyLayout>
      ) : (
        <NavContainerSidebar
          isShip30={isShip30}
          productName={productName}
          STRIPE_PK={STRIPE_PK}
        >
          <Component {...pageProps} productName={productName} userID={userID} />
        </NavContainerSidebar>
      )}

      {/* <div className={`h-screen w-screen overflow-y-scroll ${transparentHeader.includes(router.pathname) ? "bg-gray-100" : "bg-gray-100"}`}>




       {justBanner.includes(router.pathname) ?

        <div className={`${justBanner.includes(router.pathname) ? "flex flex-col w-full" : "hidden"} z-30 ${transparentHeader.includes(router.pathname) ? "bg-transparent" : "bg-gray-100"}`}>

          <div className={`bg-gradient-to-br from-orange-500 to-orange-600 py-4 px-3 sm:px-6 lg:px-8 w-full z-30 flex font-primary antialiased`}>


            <Transition
              appear={true}
              show={true}
              enter="transition-all duration-1000 delay-200 transform"
              enterFrom="opacity-0 translate-y-[24px]"
              enterTo="opacity-100 translate-y-0 "
              leave="transition-all duration-1000 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-[-24px]"
              className="max-w-7xl mx-auto"
            >

              <div className=" text-center sm:px-16">
                <p className="font-medium text-white">
                  <span className="md:hidden">Publishing threads to Twitter is temporarily offline.</span>
                  <span className="hidden md:inline">Posting threads is temporarily offline. We're working hard to restore it!</span>

                </p>
              </div>

            </Transition>


          </div>
        </div>

        : null}


      {router.query.ref === "welcome" ?
        <Transition
          appear={true}
          show={useTransition}
          enter="transition-all duration-1000 delay-100 transform"
          enterFrom="opacity-0  scale-50"
          enterTo="opacity-100 scale-100"
          leave="transition-all duration-1000 transform"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-[-1000px]"
          className={`z-10 relative min-h-screen ${transparentHeader.includes(router.pathname) ? "bg-transparent" : "bg-gray-100"}`}
        >
          <div className={` flex flex-col antialiased z-10 ${transparentHeader.includes(router.pathname) ? "bg-transparent" : "bg-gray-100"} min-h-screen w-screen`}>


            <div className={`${noHeader.includes(router.pathname) ? "hidden" : "flex flex-col w-full"} z-30 ${transparentHeader.includes(router.pathname) ? "bg-transparent" : "bg-gray-100"}`}>



               <div className={`bg-gradient-to-br from-orange-500 to-orange-600 py-4 px-3 sm:px-6 lg:px-8 w-full font-primary z-30 flex`}>


                <Transition
                  appear={true}
                  show={true}
                  enter="transition-all duration-1000 delay-200 transform"
                  enterFrom="opacity-0 translate-y-[24px]"
                  enterTo="opacity-100 translate-y-0 "
                  leave="transition-all duration-1000 transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-[-24px]"
                  className="max-w-7xl mx-auto"
                >

                  <div className=" text-center sm:px-16">
                    <p className="font-medium text-white">
                      <span className="md:hidden">Posting to Twitter is temporarily offline.</span>
                      <span className="hidden md:inline">Posting to Twitter is temporarily offline. We're working hard to restore it!</span>

                    </p>
                  </div>

                </Transition>


              </div>

              <div className={`bg-gradient-to-br from-gray-800 to-gray-600 py-4 px-3 sm:px-6 lg:px-8 w-full font-primary z-30 ${noHeader.includes(router.pathname) ? "hidden" : productName === null ? "hidden" : productName.includes("Pro") || productName.includes("Expert") ? "hidden" : "flex items-center justify-center"} `}>


                <Transition
                  appear={true}
                  show={true}
                  enter="transition-all duration-1000 delay-200 transform"
                  enterFrom="opacity-0 translate-y-[24px]"
                  enterTo="opacity-100 translate-y-0 "
                  leave="transition-all duration-1000 transform"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-[-24px]"
                  className="max-w-7xl mx-auto"
                >

                  <div onClick={() => router.push("/pro")} className=" text-center sm:px-16">
                    <p className="font-medium text-white">
                      <span className="md:hidden">Join Typeshare Pro</span>
                      <span className="hidden md:inline">{isShip30 ? "As a Ship 30 member, you have FREE access to Typeshare Pro throughout the duration of the course!" : "Join Typeshare Pro to unlock more customization, templates, and analytics"}</span>
                      <span className="hidden md:inline ml-4 text-gray-500">|</span>
                      <span className="block sm:ml-4 sm:inline-block">
                        <button style={{ background: "linear-gradient(180deg, #5B9AFF 0%, #3B82F6 100%)" }} onClick={() => router.push("/pro")} className="text-white lg:flex hidden font-semibold px-3 py-1.5 rounded-full text-sm hover:text-gray-200 transition duration-200">
                          {' '}
                          {isShip30 ? "Claim Free Month" : "Get a Free 7 Day Trial"} <span aria-hidden="true">&rarr;</span>
                        </button>
                      </span>
                    </p>
                  </div>

                </Transition>


              </div>
              <div className={"z-30 px-4 xl:px-0"}>
                <div className={" mt-8 mb-8"}>
                  <Topbar ready={useTransition} />
                
                </div>

              </div>
            </div>
            <div className={`z-20`}>
              <Component {...pageProps} productName={productName} userID={userID} />
            </div>

          </div>
        </Transition> :

        <div className={`flex-col ${useTransition ? "flex" : "hidden"}  antialiased z-10 ${transparentHeader.includes(router.pathname) ? "bg-transparent" : "bg-gray-100"} h-screen w-screen`}>




          <div className={`${noHeader.includes(router.pathname) ? "hidden" : productName === null ? "hidden" : "flex flex-col w-full"} z-30 ${transparentHeader.includes(router.pathname) ? "bg-transparent" : "bg-gray-100"}`}>

           

           <div className={`bg-gradient-to-br from-orange-500 to-orange-600 py-4 px-3 sm:px-6 lg:px-8 w-full font-primary z-30 flex`}>


              <Transition
                appear={true}
                show={true}
                enter="transition-all duration-1000 delay-200 transform"
                enterFrom="opacity-0 translate-y-[24px]"
                enterTo="opacity-100 translate-y-0 "
                leave="transition-all duration-1000 transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-[-24px]"
                className="max-w-7xl mx-auto"
              >

                <div className=" text-center sm:px-16">
                  <p className="font-medium text-white">
                    <span className="md:hidden">Posting to Twitter is temporarily offline.</span>
                    <span className="hidden md:inline">Posting to Twitter is temporarily offline. We're working hard to restore it!</span>

                  </p>
                </div>

              </Transition>



            </div>



            <div className={`bg-gradient-to-br from-gray-800 to-gray-600 py-4 px-3 sm:px-6 lg:px-8 w-full font-primary z-30 ${noHeader.includes(router.pathname) ? "hidden" : productName === null ? "hidden" : productName.includes("Pro") || productName.includes("Expert") ? "hidden" : "flex items-center justify-center"} `}>


              <Transition
                appear={true}
                show={true}
                enter="transition-all duration-1000 delay-200 transform"
                enterFrom="opacity-0 translate-y-[24px]"
                enterTo="opacity-100 translate-y-0 "
                leave="transition-all duration-1000 transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-[-24px]"
                className="max-w-7xl mx-auto"
              >

                <div onClick={() => router.push("/pro")} className=" text-center sm:px-16">
                  <p className="font-medium text-white">
                    <span className="md:hidden">Join Typeshare Pro</span>
                    <span className="hidden md:inline">{isShip30 ? "As a Ship 30 member, you have FREE access to Typeshare Pro throughout the duration of the course!" : "Join Typeshare Pro to unlock more customization, templates, and analytics"}</span>
                    <span className="hidden md:inline ml-4 text-gray-500">|</span>
                    <span className="block sm:ml-4 sm:inline-block">
                      <button style={{ background: "linear-gradient(180deg, #5B9AFF 0%, #3B82F6 100%)" }} onClick={() => router.push("/pro")} className="text-white font-semibold lg:flex hidden px-3 py-1.5 rounded-full text-sm hover:text-gray-200 transition duration-200">
                        {' '}
                        {isShip30 ? "Claim Free Month" : "Get a Free 7 Day Trial"} <span aria-hidden="true">&rarr;</span>
                      </button>
                    </span>
                  </p>
                </div>

              </Transition>



            </div>

            <div className={"z-30 px-4 xl:px-0"}>
              <div className={" mt-8 mb-8"}>
                <Topbar ready={useTransition} />
              </div>

            </div>
          </div>



           <div className={`z-20 ${router.pathname === "/theme" ? "xl:overflow-hidden overflow-auto flex-1 flex h-full" : null} `}>
            <Component {...pageProps} productName={productName} userID={userID} />
          </div> 



        </div>
      }
    </div> */}
    </>
  );
}

export default MyApp;
