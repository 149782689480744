export default function CollectionsIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_30_1194)">
        <path
          d="M7.125 1.8125C9.78191 1.8125 11.9375 2.57055 11.9375 3.49999V3.62498C11.9375 4.55445 9.78191 5.3125 7.125 5.3125C4.46809 5.3125 2.3125 4.55445 2.3125 3.62501V3.50002C2.3125 2.57055 4.46809 1.8125 7.125 1.8125ZM11.9375 5.5044V6.22625C11.9375 7.15569 9.78191 7.91374 7.125 7.91374C4.46809 7.91374 2.3125 7.15569 2.3125 6.22625V5.5044C3.34645 6.26904 5.23886 6.625 7.125 6.625C9.01114 6.625 10.9036 6.26904 11.9375 5.5044ZM11.9375 8.1294V8.86314C11.9375 9.79258 9.78191 10.5506 7.125 10.5506C4.46809 10.5506 2.3125 9.79255 2.3125 8.86311V8.12937C3.34645 8.89404 5.23886 9.25 7.125 9.25C9.01114 9.25 10.9036 8.89404 11.9375 8.1294ZM11.9375 10.7544V11.5C11.9375 12.4294 9.78191 13.1875 7.125 13.1875C4.46809 13.1875 2.3125 12.4294 2.3125 11.5V10.7544C3.34645 11.519 5.23886 11.875 7.125 11.875C9.01114 11.875 10.9036 11.519 11.9375 10.7544ZM7.125 0.5C4.74752 0.5 1 1.13602 1 3.49999V11.5C1 13.8645 4.74691 14.5 7.125 14.5C9.50248 14.5 13.25 13.864 13.25 11.5V3.49999C13.25 1.13552 9.50309 0.5 7.125 0.5Z"
          fill="fillCurrent"
        />
      </g>
      <defs>
        <clipPath id="clip0_30_1194">
          <rect
            width="12.25"
            height="14"
            fill="white"
            transform="translate(1 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
