import Link from 'next/link';
import { useRouter } from 'next/router';
import ButtonTip from '../Tooltip/ButtonTip';

export default function NavItem({
  isNew = false,
  key = null,
  children,
  href = null,
  icon,
  variant,
  suffixSRC = null,
  click = null,
  noActive = false,
  ping = false,
  customActive = false,
  suffix = null,
  target = '_self',
  passHref = false,
  shortcut = null,
}) {
  const router = useRouter();

  const active = customActive
    ? customActive
    : href && router.pathname === href
    ? true
    : false;

  return click ? (
    <button href={href} onClick={() => click()} className={'w-full'}>
      <a
        className={`${
          variant === 'small' || variant === 'small-alt'
            ? 'px-2 py-1'
            : 'px-2 py-1.5'
        } flex w-full transform cursor-pointer flex-row items-center justify-between rounded-[10px] border duration-200 active:scale-[.98] ${
          !noActive && active
            ? `text-blue-500 ${variant === 'small' ? 'text-[13px]' : 'text-sm'}`
            : `${
                variant === 'small'
                  ? 'text-[13px] text-gray-900'
                  : variant === 'small-alt'
                  ? 'text-[13px] text-gray-900'
                  : 'text-sm text-gray-900'
              } `
        }  ${
          variant === 'search'
            ? 'border-gray-200 bg-gray-100 text-gray-600 hover:bg-gray-100 hover:text-gray-900'
            : 'border-transparent hover:bg-gray-100'
        }`}
      >
        <div className="flex flex-row items-center justify-start space-x-3 ">
          {icon}
          <p
            className={`${
              variant === 'search' ? 'font-regular' : 'font-medium'
            }`}
          >
            {children}
          </p>
        </div>
        {suffix
          ? suffix
          : suffixSRC && (
              <img src={suffixSRC} className={'h-[14px] w-[14px]'} />
            )}
      </a>
    </button>
  ) : (
    <Link
      key={key}
      href={href}
      target={target}
      className={`${
        variant === 'small' || variant === 'small-alt'
          ? 'px-2 py-1'
          : 'px-2 py-1.5'
      } flex w-full transform cursor-pointer flex-row items-center justify-between rounded-[10px] border duration-200 active:scale-[.98] ${
        !noActive && active
          ? `text-blue-500 ${variant === 'small' ? 'text-[13px]' : 'text-sm'}`
          : `${
              variant === 'small'
                ? 'text-[13px] text-gray-900'
                : variant === 'small-alt'
                ? 'text-[13px] text-gray-900'
                : 'text-sm text-gray-900'
            } `
      }  ${
        variant === 'search'
          ? 'border-gray-200 bg-gray-100 text-gray-600 hover:bg-gray-100 hover:text-gray-900'
          : 'border-transparent hover:bg-gray-100'
      }`}
    >
      <div className="flex flex-row items-center justify-start space-x-3 ">
        {icon}
        <p
          className={`${variant === 'search' ? 'font-regular' : 'font-medium'}`}
        >
          {children}
        </p>
      </div>

      {isNew && (
        <ButtonTip text={'New Feature'}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-3 w-3 animate-pulse"
          >
            <path d="M15.98 1.804a1 1 0 00-1.96 0l-.24 1.192a1 1 0 01-.784.785l-1.192.238a1 1 0 000 1.962l1.192.238a1 1 0 01.785.785l.238 1.192a1 1 0 001.962 0l.238-1.192a1 1 0 01.785-.785l1.192-.238a1 1 0 000-1.962l-1.192-.238a1 1 0 01-.785-.785l-.238-1.192zM6.949 5.684a1 1 0 00-1.898 0l-.683 2.051a1 1 0 01-.633.633l-2.051.683a1 1 0 000 1.898l2.051.684a1 1 0 01.633.632l.683 2.051a1 1 0 001.898 0l.683-2.051a1 1 0 01.633-.633l2.051-.683a1 1 0 000-1.898l-2.051-.683a1 1 0 01-.633-.633L6.95 5.684zM13.949 13.684a1 1 0 00-1.898 0l-.184.551a1 1 0 01-.632.633l-.551.183a1 1 0 000 1.898l.551.183a1 1 0 01.633.633l.183.551a1 1 0 001.898 0l.184-.551a1 1 0 01.632-.633l.551-.183a1 1 0 000-1.898l-.551-.184a1 1 0 01-.633-.632l-.183-.551z" />
          </svg>
        </ButtonTip>
      )}

      {/* {
                    shortcut &&
                    <div className={`w-4 h-4 flex items-center justify-center text-center rounded-[4px] font-semibold font-mono text-[10px] text-gray-500 bg-gray-100 uppercase`}>

                        {shortcut}

                    </div>
                } */}
      {/* {ping ?
                        <div className="flex items-center justify-center">
                            <div className="w-2 h-2 rounded-full bg-gradient-to-b from-blue-400 to-indigo-600 absolute z-10" />
                            <div className="w-3 h-3 rounded-full bg-gradient-to-b from-blue-100 to-indigo-200 animate-ping" />
                        </div>
                        : suffixSRC && <img src={suffixSRC} className={"w-[14px] h-[14px]"} />} */}
    </Link>
  );
}
