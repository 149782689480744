import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { client } from './http';

//use like: getActivePlan(userID, function (plan) { setProductName(plan.name) });

export async function getUserMeta(userID, callback) {
  var meta = {
    name: '',
    email: '',
    username: '',
    image: '',
  };

  const usernameRef = await firebase
    .database()
    .ref('users/' + userID + '/gallery/')
    .child('username')
    .once('value');

  var emailRef = await firebase
    .database()
    .ref('users/' + userID)
    .child('meta')
    .child('email')
    .once('value');

  if (emailRef.val() != null) {
    meta.email = emailRef.val();
  }

  if (usernameRef.exists()) {
    const username = usernameRef.val();
    meta.username = username;

    const blogMetaRef = await firebase
      .database()
      .ref('gallery')
      .child(username)
      .child(userID)
      .child('meta')
      .once('value');

    if (blogMetaRef.hasChild('displayName')) {
      meta.name = blogMetaRef.val().displayName;
      meta.image = blogMetaRef.val().image;

      callback(meta);
    } else {
      const blogMetaRef2 = await firebase
        .database()
        .ref('gallery')
        .child(username)
        .child(userID)
        .once('value');

      meta.name = blogMetaRef2.val().displayName;
      meta.image = blogMetaRef2.val().image;

      callback(meta);
    }
  } else {
    //no gallery
    callback(meta);
  }
}

export async function getActivePlan(userID, callback) {
  var plan = {
    name: 'Free Plan',
    customerID: null,
    subscriptionID: null,
    priceID: null,
    status: null,
  };

  var ref = firebase.database().ref('users/' + userID + '/plan');
  ref.on('value', async function (snapshot) {
    if (snapshot.hasChild('status')) {
      plan.status = snapshot.val().status;
    }

    if (snapshot.hasChild('stripe/customerID')) {
      plan.customerID = snapshot.val().stripe.customerID;
    }

    if (snapshot.hasChild('stripe/subscriptionID')) {
      plan.subscriptionID = snapshot.val().stripe.subscriptionID;
    }

    if (snapshot.hasChild('priceID')) {
      plan.priceID = snapshot.val().priceID;
    }

    if (snapshot.hasChild('productName')) {
      if (
        snapshot.val().productName.includes('Pro') ||
        snapshot.val().productName.includes('pro') ||
        snapshot.val().productName.includes('Expert')
      ) {
        //check the period
        if (snapshot.val().productName === 'Expert Lifetime') {
          plan.name = 'Premier';
          callback(plan);
        } else {
          if (snapshot.hasChild('stripe/subscriptionID')) {
            const period = await getBillingPeriod(
              snapshot.val().stripe.subscriptionID,
            );
            plan.name = period;
            callback(plan);
          } else {
            plan.name = snapshot.val().productName;
            callback(plan);
          }
        }
      } else {
        plan.name = snapshot.val().productName;
        callback(plan);
      }
    } else {
      plan.name = 'Free Plan';
      callback(plan);
    }
  });
}

export async function getBillingPeriod(subID) {
  try {
    const { data } = await client.post('/api/stripe/read/billing-period', {
      subscriptionID: subID,
    });
    if (!data.success) {
      throw new Error('failed to fetch billing period');
    }
    if (data.interval === 'month') {
      return 'Pro';
    }
    if (data.interval === 'year') {
      return 'Premier';
    }
  } catch (err) {
    console.error(err);
  }
  return false;
}
