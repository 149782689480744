import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import firebase from 'firebase';
import React, { Fragment, useEffect, useState } from 'react';
import Button from '../../Button';

type TemplateMeta = {
  title: string;
  slug: string;
  description: string;
  icon: string;
  type: string;
};

type TemplateContent = {
  title: string;
  content: string;
};

export default function TemplatePreviewModal({
  slug,
  open,
  setOpen,
  openTemplate,
}) {
  const [templateMeta, setTemplateMeta] = useState<TemplateMeta>();
  const [templateContent, setTemplateContent] = useState<TemplateContent>();

  useEffect(() => {
    slug && open ? getTemplateFromFirebase(slug) : null;
  }, [slug, open]);

  const getTemplateFromFirebase = async (slug: string) => {
    const templateRef = await firebase
      .database()
      .ref('templates2')
      .child('templates')
      .child(slug)
      .once('value');
    const template = templateRef.val();

    const title = template.data?.content?.titleText;
    var content = template.data?.content?.bodyText;

    //replace exactly </p><p></p><p></p><p> with a divider
    content = content.replace(/<\/p><p><\/p><p><\/p><p>/g, '<hr></hr>');

    setTemplateMeta(template.meta);
    setTemplateContent({ title, content });
  };

  function closeModal() {
    setOpen(false);
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          className="fixed inset-0 z-40 overflow-hidden"
          onClose={closeModal}
        >
          <div className="min-h-screen text-center sm:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300 transform"
              enterFrom="opacity-0 scale-90 translate-y-96"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 translate-y-96 scale-90"
            >
              <div
                className={` relative inline-block w-full max-w-xl transform overflow-hidden rounded-3xl bg-gray-50 text-left align-middle antialiased shadow-xl transition-all`}
              >
                <div className="sticky top-1 z-10 w-full">
                  <button
                    onClick={() => closeModal()}
                    className="absolute top-2 right-2 rounded-full bg-gray-100 p-2 text-gray-500 duration-200 hover:bg-gray-200 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                <div className="flex h-[600px] w-full flex-col justify-between overflow-y-auto">
                  <div>
                    <div className="sticky top-0 flex flex-col items-start justify-start space-y-0.5 border-b border-gray-100 bg-gray-50/50 px-6 py-3 backdrop-blur-sm">
                      <p className="text-xs font-medium uppercase text-gray-500">
                        Previewing:
                      </p>
                      <div className="flex flex-row items-center justify-center space-x-2 text-sm font-medium">
                        <p className="text-xl">{templateMeta?.icon}</p>
                        <div className="flex flex-row flex-wrap items-center justify-start">
                          <p>{templateMeta?.title}</p>
                          <div className="mx-2 h-1 w-1 rounded-full bg-gray-300" />
                          <p>
                            {templateMeta?.type === 'atomic-essay'
                              ? 'Atomic Essay'
                              : templateMeta?.type === 'tweet'
                              ? 'Tweet'
                              : templateMeta?.type === 'thread'
                              ? 'Thread'
                              : templateMeta?.type === 'linkedin-post'
                              ? 'LinkedIn Post'
                              : templateMeta?.type === 'subatomic-essay'
                              ? 'Subatomic Essay'
                              : 'Template'}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="flex  flex-col space-y-2 overflow-auto px-8">
                      {templateContent?.title && (
                        <h1 className="mt-4 font-sans text-2xl font-bold tracking-tight text-slate-900">
                          {templateContent?.title}
                        </h1>
                      )}
                      <div
                        className='lg-blog'
                        dangerouslySetInnerHTML={{
                          __html: templateContent?.content || '',
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className="sticky bottom-0 bg-gray-50/50 py-3 px-6 backdrop-blur-sm">
                    {/* @ts-ignore */}
                    <Button
                      click={() => openTemplate()}
                      size={'mini'}
                      width={'full'}
                    >
                      Open in Editor
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
