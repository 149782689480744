import { ChevronLeftIcon, XIcon } from '@heroicons/react/solid';
import { Popover, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import Button from '../Button';
import clsx from 'clsx';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { cio } from '../../utils/customer-io';

export default function OnboardingPopover({
  disableClick = false,
  round = false,
  overlayClassName = null,
  close = null,
  show,
  children,
  isOpen,
  setIsOpen,
  placement = 'right',
  title,
  step,
  totalSteps,
  type = 'action',
  offset = null,
  next = null,
  className = null,
  nextText = "Ok, I'm Done!",
  back = null,
  showBack = false,
}) {
  const [progress, setProgress] = useState(0);
  const [confirmClose, setConfirmClose] = useState(false);
  const [userID, setUserID] = useState(null);
  const [showReward, setShowReward] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setUserID(userInfo.uid);
      } else {
        setUserID(null);
      }
    });
  }, []);

  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      {
        name: 'computeStyles',
        options: {
          adaptive: false, // true by default
        },
      },
      /* { name: 'arrow', options: { element: arrowElement } }, */
      { name: 'offset', options: { offset: offset ? offset : [0, 24] } },
    ],
  });

  useEffect(() => {
    if (step && totalSteps) {
      //setProgress type sting with % at the end
      setProgress(`${Math.round((step / totalSteps) * 100)}%`);
    }
  }, [step, totalSteps]);

  const [closeAttempt, setCloseAttempt] = useState(false);

  function clickOutside() {
    setCloseAttempt(true);
    setTimeout(() => {
      setCloseAttempt(false);
    }, 100);
  }

  const controls = useAnimation();

  useEffect(() => {
    if (confirmClose) {
      controls.start({
        opacity: 0,
        y: -40,
        transition: { duration: 0.2 },
      });
    } else {
      controls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.2, delay: 0.1 },
      });
    }
  }, [confirmClose]);

  const closePop = () => {
    setIsOpen(false);
    close && close();
    //update firebase
    if (userID) {
      firebase
        .database()
        .ref(`users/${userID}/modals`)
        .update({
          onboarding: true,
        })
        .then(() => {
          cio.trackEvent(userID, {
            name: 'exit_tour',
            data: {
              step: step,
            },
          });
        });
    }
  };

  return show ? (
    <Popover className={clsx(`w-full`, className)}>
      <>
        <div
          onClick={() => disableClick && clickOutside()}
          ref={setReferenceElement}
          className={`${isOpen ? 'z-[200]' : null} relative ${
            type === 'action' ? 'group' : null
          } w-full`}
        >
          <div className="relative z-10">{children}</div>
          {type === 'action' && (
            <div className="absolute top-0 z-0 h-full w-full rounded-full ring-4 ring-white duration-200 group-hover:scale-x-[1.04] group-hover:scale-y-[1.10] group-active:scale-x-[1.02] group-active:scale-y-[1.05]" />
          )}
        </div>

        <Transition
          appear={true}
          show={isOpen}
          className={'fixed inset-0 z-[100]'}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          onClick={() => clickOutside()}
        >
          <Popover.Overlay
            className={clsx(
              `fixed inset-0 bg-gray-900 opacity-50 ${round && 'rounded-2xl'}`,
              overlayClassName,
            )}
          />
        </Transition>
        <Portal>
          <Popover.Panel static className={`relative z-[300]`}>
            <AnimatePresence>
              {isOpen && (
                <div
                  ref={setPopperElement}
                  style={styles.popper}
                  {...attributes.popper}
                  className="relative z-50"
                >
                  <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0, duration: 0.1 }}
                    exit={{ opacity: 0, x: -100, duration: 0.1 }}
                  >
                    <div
                      className={`over relative flex w-full max-w-xs flex-col items-center justify-center rounded-xl bg-gray-900 p-6 text-white shadow-xl shadow-gray-500/20 duration-150 ${
                        closeAttempt && 'scale-95'
                      }`}
                    >
                      <div className="absolute top-0 h-0.5 w-full rounded-full bg-gradient-to-r from-transparent via-blue-500 to-transparent" />

                      {!confirmClose && (
                        <button
                          onClick={() => setConfirmClose(!confirmClose)}
                          className="absolute top-2 right-2 z-10 rounded-full bg-gray-800 p-1.5 text-gray-300 duration-150 hover:bg-gray-700 hover:text-gray-200"
                        >
                          <XIcon className="h-4 w-4" />
                        </button>
                      )}

                      {showBack && !confirmClose ? (
                        <button
                          onClick={() => back()}
                          className="absolute top-2 left-2 rounded-full bg-gray-800 p-1.5 text-gray-300 duration-150 hover:bg-gray-700 hover:text-gray-200"
                        >
                          <ChevronLeftIcon className="h-4 w-4" />
                        </button>
                      ) : null}

                      <svg
                        className={`${
                          placement === 'right'
                            ? '-left-4 top-1/2 -translate-y-1/2 -rotate-90'
                            : placement === 'bottom'
                            ? '-top-2 left-1/2 -translate-x-1/2'
                            : placement === 'left'
                            ? '-right-4 top-1/2 -translate-y-1/2 rotate-90'
                            : placement === 'bottom-end'
                            ? '-top-2 right-14'
                            : '-bottom-2 left-1/2 -translate-x-1/2 rotate-180'
                        } absolute w-6 fill-current stroke-current text-gray-900`}
                        viewBox="0 0 22 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.78018 2.4418C9.97046 1.1325 12.0295 1.13249 13.2198 2.4418L21 11H1L8.78018 2.4418Z"
                          fill="var(--bg0)"
                        ></path>
                        <path d="M21 11L13.2198 2.4418C12.0295 1.13249 9.97046 1.1325 8.78018 2.4418L1 11"></path>
                      </svg>

                      <AnimatePresence>
                        {confirmClose && (
                          <motion.div
                            initial={{ opacity: 0, y: 40 }}
                            animate={{ opacity: 1, y: 0, duration: 0.2 }}
                            exit={{ opacity: 0, y: 40, duration: 0.2 }}
                            className={
                              'absolute top-0 left-0 z-10 flex h-full w-full flex-col items-center justify-center p-6'
                            }
                          >
                            <h2 className="mt-2 text-center text-base font-medium leading-tight tracking-tightish">
                              Are you sure you want to exit?
                            </h2>
                            <div className="mt-4 flex w-full flex-row items-center justify-center space-x-2">
                              <button
                                onClick={() => setConfirmClose(false)}
                                className="w-full rounded-lg p-2 text-sm font-medium text-blue-500 duration-150 hover:bg-blue-500/10"
                              >
                                No, continue
                              </button>

                              <div className="h-6 w-1 rounded-full bg-gray-700" />

                              <button
                                onClick={() => closePop()}
                                className="w-full rounded-lg p-2 text-sm font-medium text-red-500 duration-150 hover:bg-red-500/10"
                              >
                                Yes, close
                              </button>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>

                      <motion.div
                        animate={controls}
                        className={
                          'flex w-full flex-col items-center justify-center'
                        }
                      >
                        <p className="text-sm font-medium text-gray-500">
                          {step} of {totalSteps}
                        </p>
                        <h2 className="mt-3 text-center text-base font-medium leading-tight tracking-tightish">
                          {title}
                        </h2>
                        {type == 'tip' && (
                          <div className="mt-3 w-full">
                            <Button
                              click={() => next()}
                              width={'full'}
                              size={'mini'}
                              variant={'primary'}
                            >
                              {nextText}
                            </Button>
                          </div>
                        )}

                        {showReward ? (
                          <div className="relative mt-6 flex h-1 w-full flex-row items-center justify-end rounded-full bg-gray-700">
                            <div
                              style={{ width: progress }}
                              className={`absolute left-0 flex h-1 flex-row items-center justify-end rounded-full bg-green-500`}
                            >
                              <div className="flex h-4 w-4 items-center justify-center rounded-full bg-gray-700">
                                😀
                              </div>
                            </div>

                            <div className="flex h-4 w-4 items-center justify-center rounded-full bg-gray-700">
                              🎁
                            </div>
                          </div>
                        ) : (
                          <div className="relative mt-6 flex h-1 w-full flex-row items-center justify-end rounded-full bg-gray-700">
                            <div
                              style={{ width: progress }}
                              className={`absolute left-0 flex h-1 flex-row items-center justify-end rounded-full bg-gradient-to-r from-transparent to-green-500`}
                            ></div>
                          </div>
                        )}
                      </motion.div>
                    </div>
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </Popover.Panel>
        </Portal>
      </>
    </Popover>
  ) : (
    children
  );
}
