import LibraryIcon from '../Icons/library';
import CollectionsIcon from '../Icons/collection-alt';
import TemplatesIconAlt from '../Icons/templates-alt';
import AnalyticsAltIcon from '../Icons/analytics-alt';
import SettingsAlt from '../Icons/settings-alt';
import HelpIcon from '../Icons/help';
import NavItem from './nav-item';

import { useRouter } from 'next/router';

import TSSimple from '../Icons/ts-simple';
import Button from '../Button';

import Image from 'next/image';
import OnboardingPopover from '../OnboardingPopover';
import { useEffect, useState } from 'react';
import QueueIcon from '../Icons/queue';
import PublishedPostIcon from '../Icons/published-icon';
import ScheduledPostIcon from '../Icons/scheduled-icon';
import DraftIcon from '../Icons/draft';
import { SearchIcon } from '@heroicons/react/solid';

export default function Sidebar({
  galleryName,
  popoverButtonRef,
  photoUrl,
  openCreateModal,
  setHelpModalOpen,
  isPopoverOpen,
  setIsPopoverOpen,
  tour,
  setShowTour,
}) {
  const router = useRouter();

  const closeTour = () => {
    setIsPopoverOpen(false);
    setTimeout(() => {
      setShowTour(false);
    }, 200);
  };
  /* 
    const [queueClicked, setQueueClicked] = useState(true);

    useEffect(() => {
        if (router.pathname === "/library/queue") {
            setQueueClicked(true);
        } else {
            setQueueClicked(false);
        }
    }, [router.pathname]) */

  return (
    <div className="flex h-full w-full flex-col items-start justify-between">
      <div className="flex w-full flex-col items-start justify-start space-y-8">
        <div className="flex w-full flex-row items-center justify-between space-x-1">
          <TSSimple className="h-6 w-6" />
        </div>

        {/*  <div className="w-full bg-gray-50 border border-gray-100 rounded-lg  px-1 py-1 flex flex-row items-center justify-between group hover:bg-gray-100/70 duration-150 cursor-pointer">
                    <div className="flex flex-row space-x-2 items-center justify-start">
                        <SearchIcon className="h-4 w-4 text-gray-400 group-hover:text-gray-500  duration-150" />
                        <p className="text-sm text-gray-500  group-hover:text-gray-600">Find anything</p>
                    </div>

                    <kbd className="text-gray-400  group-hover:text-gray-500 bg-gray-100 border border-gray-200 rounded-md w-5 text-xs flex items-center justify-center font-semibold h-5  duration-150">
                        /
                    </kbd>

                </div> */}

        <div className="flex w-full flex-col space-y-2">
          <NavItem
            shortcut={1}
            customActive={
              router.pathname === '/[slug]' && galleryName === router.query.slug
            }
            href={`/${encodeURIComponent(galleryName)}`}
            /* icon={<SocialBlogIconAlt className={"w-[16px] h-[16px] fill-current"} />} */ icon={
              photoUrl ? (
                <Image
                  width={18}
                  height={18}
                  className="-mr-1 h-[18px] w-[18px] rounded-full"
                  src={photoUrl}
                />
              ) : (
                <div className="h-[18px] w-[18px] animate-pulse rounded-full bg-gray-200" />
              )
            }
          >
            My Social Blog
          </NavItem>
        </div>

        <div className="flex w-full flex-col space-y-2">
          <div className="flex flex-row  text-gray-500">
            <p className="font-mono text-xs font-semibold uppercase">Library</p>
          </div>

          <div className="flex w-full flex-col space-y-0.5">
            <NavItem
              href={'/library'}
              /* noActive={true} */ icon={
                <PublishedPostIcon className={'h-[16px] w-[16px]'} />
              }
            >
              Published
            </NavItem>
            <NavItem
              href={'/library/queue'}
              /* noActive={true} */ icon={
                <ScheduledPostIcon className={'h-[16px] w-[16px]'} />
              }
              isNew={new Date().getTime() < new Date('2022-09-26').getTime()}
            >
              Queue
            </NavItem>
            <NavItem
              href={'/library/drafts'}
              /* noActive={true} */ icon={
                <DraftIcon className={'h-[16px] w-[16px]'} />
              }
            >
              Drafts
            </NavItem>
          </div>
        </div>

        <div className="flex w-full flex-col space-y-2">
          <div className="flex flex-row  text-gray-500">
            <p className="font-mono text-xs font-semibold uppercase">Create</p>
          </div>

          <div className="flex w-full flex-col space-y-0.5">
            <NavItem
              shortcut={3}
              href={
                galleryName
                  ? `/${encodeURIComponent(galleryName)}/collections`
                  : '/'
              }
              customActive={
                router.pathname === '/[slug]/collections' ? true : false
              }
              icon={
                <CollectionsIcon className={'h-[16px] w-[16px] fill-current'} />
              }
            >
              Collections
            </NavItem>
            <NavItem
              shortcut={4}
              href={'/templates'}
              customActive={
                router.pathname.includes('/templates') ? true : false
              }
              icon={
                <TemplatesIconAlt
                  className={'h-[16px] w-[16px] fill-current'}
                />
              }
            >
              Templates
            </NavItem>
          </div>
        </div>

        <div className="flex w-full flex-col space-y-2">
          <div className="flex flex-row items-center justify-between font-mono uppercase text-gray-500">
            <p className="text-xs font-semibold">Grow</p>
          </div>

          <div className="flex w-full flex-col space-y-0.5">
            <NavItem
              shortcut={5}
              href={'/analytics'}
              icon={
                <AnalyticsAltIcon
                  className={'h-[16px] w-[16px] fill-current'}
                />
              }
            >
              Analytics
            </NavItem>
          </div>
        </div>

        {/* <OnboardingPopover id={"create-new-button"} open={isPopoverOpen}>


                    <Button id={"create-new-button"} click={() => openCreateModal()} size={"mini"} width={"full"} prefix={<svg className={"w-[16px] h-[16px] -mr-1 text-white fill-current"} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_30_1265)">
                            <path d="M10.5 7.0625V7.9375C10.5 8.11797 10.3523 8.26562 10.1719 8.26562H7.76562V10.6719C7.76562 10.8523 7.61797 11 7.4375 11H6.5625C6.38203 11 6.23438 10.8523 6.23438 10.6719V8.26562H3.82812C3.64766 8.26562 3.5 8.11797 3.5 7.9375V7.0625C3.5 6.88203 3.64766 6.73438 3.82812 6.73438H6.23438V4.32812C6.23438 4.14766 6.38203 4 6.5625 4H7.4375C7.61797 4 7.76562 4.14766 7.76562 4.32812V6.73438H10.1719C10.3523 6.73438 10.5 6.88203 10.5 7.0625ZM13.7812 7.5C13.7812 11.2461 10.7461 14.2812 7 14.2812C3.25391 14.2812 0.21875 11.2461 0.21875 7.5C0.21875 3.75391 3.25391 0.71875 7 0.71875C10.7461 0.71875 13.7812 3.75391 13.7812 7.5ZM12.4688 7.5C12.4688 4.47852 10.0215 2.03125 7 2.03125C3.97852 2.03125 1.53125 4.47852 1.53125 7.5C1.53125 10.5215 3.97852 12.9688 7 12.9688C10.0215 12.9688 12.4688 10.5215 12.4688 7.5Z" fill="currentColor" />
                        </g>
                        <defs>
                            <clipPath id="clip0_30_1265">
                                <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                        </defs>
                    </svg>}>
                        New Post
                    </Button>

                </OnboardingPopover>
*/}

        <OnboardingPopover
          show={tour}
          step={1}
          totalSteps={8}
          title={"Click the 'New Post' button to create your first post."}
          placement={'right'}
          isOpen={isPopoverOpen}
          setIsOpen={setIsPopoverOpen}
          close={() => closeTour()}
        >
          <Button
            click={() => openCreateModal()}
            size={'mini'}
            width={'full'}
            prefix={
              <svg
                className={'-mr-1 h-[16px] w-[16px] fill-current text-white'}
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_30_1265)">
                  <path
                    d="M10.5 7.0625V7.9375C10.5 8.11797 10.3523 8.26562 10.1719 8.26562H7.76562V10.6719C7.76562 10.8523 7.61797 11 7.4375 11H6.5625C6.38203 11 6.23438 10.8523 6.23438 10.6719V8.26562H3.82812C3.64766 8.26562 3.5 8.11797 3.5 7.9375V7.0625C3.5 6.88203 3.64766 6.73438 3.82812 6.73438H6.23438V4.32812C6.23438 4.14766 6.38203 4 6.5625 4H7.4375C7.61797 4 7.76562 4.14766 7.76562 4.32812V6.73438H10.1719C10.3523 6.73438 10.5 6.88203 10.5 7.0625ZM13.7812 7.5C13.7812 11.2461 10.7461 14.2812 7 14.2812C3.25391 14.2812 0.21875 11.2461 0.21875 7.5C0.21875 3.75391 3.25391 0.71875 7 0.71875C10.7461 0.71875 13.7812 3.75391 13.7812 7.5ZM12.4688 7.5C12.4688 4.47852 10.0215 2.03125 7 2.03125C3.97852 2.03125 1.53125 4.47852 1.53125 7.5C1.53125 10.5215 3.97852 12.9688 7 12.9688C10.0215 12.9688 12.4688 10.5215 12.4688 7.5Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_30_1265">
                    <rect
                      width="14"
                      height="14"
                      fill="white"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            }
          >
            New Post
          </Button>
        </OnboardingPopover>
      </div>

      <div className="mt-8 flex w-full flex-col space-y-0.5">
        <NavItem
          variant={'small-alt'}
          href={'/settings'}
          icon={
            <SettingsAlt className={'-mr-1 h-[16px] w-[16px] fill-current'} />
          }
        >
          Settings
        </NavItem>
        <NavItem
          variant={'small-alt'}
          click={() => setHelpModalOpen(true)}
          icon={<HelpIcon className={'-mr-1 h-[16px] w-[16px] fill-current'} />}
        >
          {'Help & Support'}
        </NavItem>
        {/* <NavItem variant={"small-alt"} href={"https://intercom.help/typeshare-co/en"} target={"_blank"} passHref={true} icon={<HelpIcon className={"w-[16px] h-[16px] fill-current -mr-1"} />}>{"Help & Support"}</NavItem> */}
        {/* <button onClick={() => router.push({ pathname: "/[slug]", query: { slug: galleryName } })} className="flex-row mt-4 items-center justify-start space-x-2 hidden sm:flex group rounded-[10px] hover:bg-gray-200 hover:bg-opacity-50 duration-200 p-2 w-full overflow-hidden">
{photoUrl ? <img className="w-8 h-8 rounded-full" src={photoUrl} /> : <div className="animate-pulse bg-gray-200 h-8 rounded-full w-8" />}
<div>
    <div className="flex flex-row space-x-1 items-center justify-start">
        {name ? <p className="font-sans text-sm font-semibold text-gray-900 text-left duration-200">{name}</p> : <div className="animate-pulse bg-gray-200 h-6 rounded-md w-16 mb-1" />}
    </div>
    <div className="flex flex-row space-x-2 items-center justify-start">
        {galleryName ? <p className="font-sans font-medium text-gray-500 text-xs text-left">/{galleryName}</p> : <div className="animate-pulse bg-gray-200 h-3 rounded-md w-full truncate" />}


    </div>

</div>
</button>*/}
      </div>
    </div>
  );
}
