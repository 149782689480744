import {
  DocumentIcon,
  DocumentTextIcon,
  DuplicateIcon,
} from '@heroicons/react/outline';
import {
  CheckCircleIcon,
  ChevronRightIcon,
  LockClosedIcon,
  LockOpenIcon,
} from '@heroicons/react/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tippy';

import Button from '../Button';
import TemplatePreviewModal from '../NewModals/TemplatePreview';
import {
  EssayIconColor,
  LinkedInIconColor,
  SubatomicIconColor,
  ThreadIconColor,
  TweetIconColor,
} from './Icons';

export default function PackHorizontalCards({
  templates,
  createTypeshare,
  modal,
  locked = false,
  unlocksAt = null,
  plan = 'free',
  upgradeClicked,
  isLoggedIn = true,
  openLoginModal,
}) {
  const router = useRouter();

  const [dateString, setDateString] = useState(null);
  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    setIsLocked(locked);
  }, [locked]);

  useEffect(() => {
    //onvert unlockedAt (YYYY-MM-DD) to format July 10, 2020 using replace

    if (unlocksAt) {
      if (unlocksAt.includes('-')) {
        var day = unlocksAt.split('-')[2];
        //if first character is 0, remove it
        if (day.charAt(0) === '0') {
          day = day.substring(1);
        }

        const monthNum = unlocksAt.split('-')[1];
        const year = unlocksAt.split('-')[0];
        //get month name
        const unlockedAt = new Date(year, monthNum - 1, day);
        const month = unlockedAt.toLocaleString('default', { month: 'long' });

        var dateString2 = `${month} ${day}, ${year}`;

        if (year === `${new Date().getFullYear()}`) {
          dateString2 = `${month} ${day}`;
        }

        //if less than a week from now, show the date as number of days away
        const daysAway = Math.ceil(
          (unlockedAt - new Date()) / (1000 * 60 * 60 * 24),
        );
        if (daysAway < 7) {
          //if one day or less say tomorrow
          if (daysAway === 1) {
            dateString2 = 'tomorrow';
          } else if (daysAway === 0) {
            dateString2 = 'today';
          } else if (daysAway < 0) {
            dateString2 = `${month} ${day}`;
          } else {
            dateString2 = `in ${daysAway} days`;
          }
        }

        console.log(dateString2);

        setDateString(dateString2);
      }
    }
  }, [unlocksAt]);

  const HorizontalCard = ({ meta, key, locked, typeshare, isLoggedIn }) => {
    const [templatePreviewModal, setTemplatePreviewModal] = useState(false);

    return (
      <div
        key={key}
        className="flex w-full flex-row items-center justify-between rounded-xl border border-gray-100 bg-white p-3"
      >
        <TemplatePreviewModal
          open={templatePreviewModal}
          setOpen={setTemplatePreviewModal}
          slug={meta.slug}
          openTemplate={() => createTypeshare(typeshare)}
        />

        <div className="flex flex-row items-center space-x-2">
          {meta.type === 'atomic-essay' ? (
            <EssayIconColor />
          ) : meta.type === 'tweet' ? (
            <TweetIconColor />
          ) : meta.type === 'thread' ? (
            <ThreadIconColor />
          ) : meta.type === 'linkedin-post' ? (
            <LinkedInIconColor />
          ) : (
            <SubatomicIconColor />
          )}

          <div className="flex flex-col -space-y-0.5">
            <p className="text-base font-medium tracking-tightish text-gray-800">
              {meta.formatTitle}
            </p>

            {/*  <Tooltip
                            html={(
                                <div className="text-gray-600 flex flex-row space-x-3 justify-start items-center font-semibold uppercase bg-white font-mono antialiased m-0 text-xs p-0 rounded-md w-full">
                                    <p>{meta.uses > 999 ? (Math.round((meta.uses / 100)) / 10) + "K" : meta.uses} uses</p>
                                </div>
                            )}
                            theme="light"
                            size='small'
                            position="top"
                            arrow={true}
                            effect={'solid'}
                            trigger="mouseenter"
                            offset={2}
                        >
                            <div className="flex flex-row space-x-0.5 items-center justify-start text-gray-500 text-sm font-medium">
                                <DuplicateIcon className="w-4 h-4" />
                                <p>
                                    {meta.uses > 999 ? (Math.round((meta.uses / 100)) / 10) + "K" : meta.uses}
                                </p>
                            </div>
                        </Tooltip> */}
          </div>
        </div>

        <div className="flex flex-row items-center justify-center space-x-2">
          {/* {meta.example && <a href={meta.example} target={"_blank"}>
                        <Button size={"mini"} width={"full"} variant={"outline"}>
                            <p className="font-sans">View Example</p>
                        </Button>
                    </a>} */}

          {!locked && isLoggedIn ? (
            <button
              onClick={() => setTemplatePreviewModal(true)}
              className="flex flex-row items-center justify-center space-x-0 text-sm font-medium text-blue-500 duration-150 hover:text-blue-700"
            >
              <p>Preview</p>
            </button>
          ) : null}

          {isLoggedIn && (
            <Button
              click={() =>
                locked ? router.push('/plan') : createTypeshare(typeshare)
              }
              size={'mini'}
              width={'full'}
              prefix={locked && <LockClosedIcon className="-mr-2 h-4 w-4" />}
            >
              <p className="font-sans">
                {locked ? 'Unlock Now' : 'Use Template'}
              </p>
            </Button>
          )}
        </div>
      </div>
    );
  };

  return isLocked && isLoggedIn ? (
    (plan && plan.toLocaleLowerCase().includes('free')) || !dateString ? (
      <div className={`flex flex-col rounded-xl bg-black p-8 text-white`}>
        <div className="flex flex-row items-center justify-start space-x-2">
          <div className="mb-2 flex h-10 w-10 items-center justify-center rounded-full bg-white text-black">
            <LockOpenIcon className="h-6 w-6" />
          </div>
          <p className="mb-2 text-2xl font-semibold tracking-tight">
            Upgrade to unlock this pack
          </p>
        </div>

        <p className=" font-medium leading-snug tracking-tight">
          Upgrade to our Pro or Premier plans to access this and other template
          packs.
        </p>

        <div className="mt-8 flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2 rounded-lg bg-gray-900 p-2.5 text-sm font-medium tracking-tight">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Pro: Monthly access to new Template Packs</p>
          </div>
          <div className="flex flex-row items-center space-x-2 rounded-lg bg-gray-900 p-2.5 text-sm font-medium tracking-tight">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Premier: Instant access to ALL 1st Edition Template Packs</p>
          </div>
        </div>

        <p className="mt-4 text-sm font-medium tracking-tight">
          Each plan also includes:
        </p>
        <div className="mb-8 mt-2 flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2 text-sm font-medium tracking-tight ">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Analytics built for digital writers</p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm font-medium tracking-tight ">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Create a custom theme for your posts</p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm font-medium tracking-tight ">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Add unlimited cards to your Social Blog</p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm font-medium tracking-tight ">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Create unlimited collections</p>
          </div>
        </div>
        <Button
          click={() => upgradeClicked()}
          color={'bg-white'}
          width={'full'}
          size={'small'}
          customText={'text-black'}
        >
          Upgrade Plan
        </Button>
      </div>
    ) : (
      <div className="flex flex-col bg-black p-8 text-white sm:rounded-xl">
        <div className="flex flex-row items-center justify-start space-x-2">
          <div className="mb-2 flex h-10 w-10 items-center justify-center rounded-full bg-white text-black">
            <LockOpenIcon className="h-6 w-6" />
          </div>
          <p className="mb-2 text-2xl font-semibold tracking-tight">
            Unlocks {dateString}
          </p>
        </div>

        <p className=" font-medium leading-snug tracking-tight">
          Want it now? Upgrade to Premier to unlock this template and all others
          instantly.
        </p>

        <div className="my-8 flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2 rounded-lg bg-gray-900 p-2.5 text-sm font-medium tracking-tight">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Access to this Template Pack now</p>
          </div>
          <div className="flex flex-row items-center space-x-2 rounded-lg bg-gray-900 p-2.5 text-sm font-medium tracking-tight">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Instant access to ALL 1st Edition Template Packs</p>
          </div>
          <div className="flex flex-row items-center space-x-2 rounded-lg bg-gray-900 p-2.5 text-sm font-medium tracking-tight">
            <CheckCircleIcon className="h-5 w-5" />
            <p>Save 20% on your subscription</p>
          </div>
        </div>

        <Button
          click={() => upgradeClicked()}
          color={'bg-white'}
          width={'full'}
          size={'small'}
          customText={'text-black'}
        >
          Upgrade to Premier
        </Button>
      </div>
    )
  ) : (
    templates && (
      <div className={`flex w-full flex-col space-y-4 ${modal && 'px-3'}`}>
        <div className="relative flex w-full items-center justify-center">
          <div className="absolute top-1/2 h-[1px] w-full -translate-y-1/2 bg-gray-200 bg-opacity-50" />

          <div
            className={` ${
              modal ? 'hidden bg-white sm:flex' : 'flex bg-gray-50'
            } relative z-10 items-center  justify-center px-3 text-sm font-medium uppercase text-gray-500`}
          >
            <p>This pack contains {Object.keys(templates).length} templates</p>
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          {templates.map((template) => (
            <HorizontalCard
              isLoggedIn={isLoggedIn}
              key={template.meta.type}
              meta={template.meta}
              typeshare={template}
              locked={isLocked}
            />
          ))}
        </div>
      </div>
    )
  );
}
