import { Fragment, useEffect, useState, useRef } from 'react';
import {
  BriefcaseIcon,
  DotsVerticalIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  SearchIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import {
  CashIcon,
  ChartSquareBarIcon,
  CollectionIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  PlusIcon,
  XIcon,
} from '@heroicons/react/solid';
import { ChartBarIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { Dialog, Transition } from '@headlessui/react';

import Button from '../Button';

import toast, { Toaster } from 'react-hot-toast';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { NextSeo } from 'next-seo';
import LogInModal from '../NewModals/log-in';
import router from 'next/router';
import { CreditCardIcon, TrashIcon } from '@heroicons/react/outline';
import DeleteAccountModal from '../NewModals/delete-account';
import ConfirmRemove from '../Modal/confirm-remove';

import Checkout from '../Checkout';
import { addons } from '../Checkout/addons';
import SpinnerIcon from '../Icons/spinner';
import AlertBanner from '../Alert';
import ConfirmCancel from '../Modal/confirm-cancel';
import { PricingPlans } from '../../pages/plan';
import TSSimple from '../Icons/ts-simple';
import { getActivePlan } from '../../utils/helpers';
import { client } from '../../utils/http';
import axios from 'axios';

const proPlans = [
  /*     "Hobby", */
  'Expert',
  'Pro',
  'Expert Lifetime',
  'Premier',
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function UpgradePlanModal({
  isOpen,
  setIsOpen,
  STRIPE_PK,
  affiliate,
  passShip30 = null,
}) {
  const PRO_PRICE = process.env.NEXT_PUBLIC_PRO_PRICE;
  const PREMIER_PRICE = process.env.NEXT_PUBLIC_PREMIER_PRICE;

  const cancelButtonRef = useRef();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const [isOnboarding, setOnboarding] = useState(false);
  const [userID, setUserID] = useState(null);
  const [productName, setProductName] = useState('Free Plan');
  const [customerID, setCustomerID] = useState(null);

  const [pastCustomer, setPastCustomer] = useState(false);

  const [buttonLoading, setButtonLoading] = useState(false);

  const [removeAddonModal, setRemoveAddonModal] = useState(false);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [addonName, setAddonName] = useState(null);

  const [currentPriceID, setCurrentPriceID] = useState(null);

  const [billingDate, setBillingDate] = useState(null);

  const [email, setEmail] = useState('');

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const [subscriptionID, setSubscriptionID] = useState(null);

  const [status, setStatus] = useState('');
  const [activeProduct, setActiveProduct] = useState(null);

  const [existingItems, setExistingItems] = useState([]);
  const [hasAddons, setHasAddons] = useState(false);

  const [activeAddons, setActiveAddons] = useState([]);
  const [billingPeriod, setBillingPeriod] = useState(null);
  const [hasCanceledAddons, setHasCanceledAddons] = useState(false);
  const [invoiceLink, setInvoiceLink] = useState(null);

  const [periodTotal, setPeriodTotal] = useState('$0');

  const [renewing, setRenewing] = useState(false);

  const [newBillingPeriod, setNewBillingPeriod] = useState('month');
  const [checkoutModal, setCheckoutModal] = useState(false);

  const [cartItems, setCartItems] = useState([]);

  const [calculating, setCalculating] = useState(false);

  const [checkout, setCheckout] = useState(null);

  const [loading, setLoading] = useState(true);

  const [cancelModal, setCancelModal] = useState(false);

  const [ship30, setShip30] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setUserID(userInfo.uid);
        setOnboarding(false);
      } else {
        setOnboarding(true);
      }
    });
  }, []);

  useEffect(() => {
    if (userID) {
      getActivePlan(userID, function (plan) {
        setProductName(plan.name);

        if (plan.customerID) {
          setCustomerID(plan.customerID);
          setPastCustomer(true);
        }
        setStatus(plan.status);
        setSubscriptionID(plan.subscriptionID);
        setCurrentPriceID(plan.priceID);
      });
      checkIfShip30Member(userID);
      getEmail(userID);
    }
  }, [userID]);

  useEffect(() => {
    if (passShip30) {
      setShip30(true);
    }
  }, [passShip30]);

  const getEmail = (uid) => {
    var ref = firebase
      .database()
      .ref('users/' + uid)
      .child('meta')
      .child('email');
    ref.once('value', function (snap) {
      if (snap.exists()) {
        setEmail(snap.val());
      } else {
        setEmail(null);
      }
    });
  };

  const successToast = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      iconTheme: {
        primary: '#fff',
        secondary: '#10B981',
      },
      style: {
        borderRadius: '10px',
        background: '#10B981',
        color: '#fff',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: 'error',
      /* icon: '🪄', */
      iconTheme: {
        primary: '#fff',
        secondary: '#ef4444',
      },
      style: {
        borderRadius: '10px',
        background: '#ef4444',
        color: '#fff',
      },
    });

  useEffect(() => {
    customerID && subscriptionID ? getItems() : null;
  }, [customerID, subscriptionID]);

  async function getItems() {
    try {
      const { data } = await client.post(
        '/api/stripe/read/subscription-items',
        { subscriptionID },
      );
      if (!data.success) {
        throw new Error('failed to fetch subscription items');
      }
      var nextDate = data.nextBillingDate;
      var nextPaymentDate = new Date(nextDate * 1000);
      var nextPaymentDateString = nextPaymentDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      });
      setBillingDate(nextPaymentDateString);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  const getPrice = (p) => {
    //format price
    var formattedTotal = (p / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    //round to 0 decimal places
    formattedTotal = formattedTotal.split('.')[0];

    return formattedTotal;
  };

  const getPriceFromID = (id) => {
    if (id) {
      var item = existingItems.find((item) => item.id === id);
      if (item) {
        return getPrice(item.amount);
      }
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (existingItems.length > 0) {
      getMonthlyTotal();
    }
  }, [existingItems]);

  useEffect(() => {
    isOpen && setCheckoutModal(false);
  }, [isOpen]);

  const getMonthlyTotal = () => {
    var total = 0;

    existingItems.forEach((item) => {
      total += item.amount;
    });

    setPeriodTotal(getPrice(total));
  };

  const paidPlans = [
    'Hobby',
    'Expert',
    'Pro',
    'pro',
    // "Expert Lifetime",
  ];

  const success = (text) =>
    toast.success(text, {
      id: text,
      iconTheme: {
        primary: '#3B82F6',
        secondary: '#fff',
      },
      /* icon: '🪄', */
      style: {
        borderRadius: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 20px 0px',
        fontWeight: '600',
        padding: '12px',
        fontSize: '18px',
        paddingRight: '8px',
        background: '#fff',
        color: '#181818',
      },
    });

  const problem = (text) =>
    toast.error(text, {
      id: text,
      iconTheme: {
        primary: '#ef4444',
        secondary: '#fff',
      },
      /* icon: '🪄', */
      style: {
        borderRadius: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 10px 20px 0px',
        fontWeight: '600',
        padding: '12px',
        fontSize: '18px',
        paddingRight: '8px',
        background: '#fff',
        color: '#181818',
      },
    });

  const changePeriod = (period) => {
    if (customerID) {
      /* if (proPlans.includes(productName)) { */
      setNewBillingPeriod(period);
      setCheckoutModal(true);
      /* } else {
                setNewBillingPeriod(period)
                setBillingPeriod(period)
            } */
    } else {
      period === 'month' ? goPro() : goPremier();
    }
  };

  async function renewPlan() {
    setRenewing(true);
    try {
      const { data } = await client.post('/api/stripe/reactivate', {
        subID: subscriptionID,
      });
      if (!data.success) {
        throw new Error('failed to reactivate');
      }
      setTimeout(() => {
        successToast('Subscription reactivated!');
        setRenewing(false);
      }, 3000);
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  const checkoutComplete = () => {
    setRenewing(true);
    setTimeout(() => {
      if (activeProduct === 'Premier') {
        setCheckoutModal(false);
        setRenewing(false);
        setIsOpen(false);
        router.push('/library?upgrade=premier');
      } else {
        setCheckoutModal(false);
        setRenewing(false);
        setIsOpen(false);
        router.push('/library?upgrade=pro');
        /*                 setRenewing(false)
                                getActivePlan(userID, function (plan) { setProductName(plan.name) });
                                setCheckoutModal(false)
                                successToast("Plan Updated!") */
        //maybe: redirect to success modal with template selector button - and check in that modal if all schedules have dates
      }
      //redirect here based on what we want to do  - note: this is only for switching between paid plans
    }, 4000);
  };

  useEffect(() => {
    if (!checkoutModal) {
      setCheckout(null);
    }
  }, [checkoutModal]);

  async function createProCheckoutSession(
    price,
    email,
    uid,
    productName,
    trial,
  ) {
    const refID = await getClientReferenceId();
    try {
      const { data } = await client.post('/api/stripe-pro', {
        price,
        email,
        uid,
        productName,
        trial,
        welcome: false,
        refID,
        customerID: customerID,
      });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return err.response.data;
      }
      return {};
    }
  }

  async function createProCheckoutSessionCoupon(
    price,
    email,
    uid,
    productName,
    trial,
  ) {
    const refID = await getClientReferenceId();
    try {
      const { data } = await client.post('/api/stripe-pro-offer-affiliate', {
        price,
        email,
        uid,
        productName,
        trial,
        welcome: true,
        refID,
        coupon: 'O2VtU6Ic',
        customerID: customerID,
      });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return err.response.data;
      }
      return {};
    }
  }

  async function createPremierCheckoutSession(price, email, uid, productName) {
    const refID = await getClientReferenceId();
    try {
      const { data } = await client.post('/api/stripe-premier', {
        price,
        email,
        uid,
        productName,
        welcome: false,
        refID,
        customerID: customerID,
      });
      return data;
    } catch (err) {
      if (err.isAxiosError(err)) {
        return err.response.data;
      }
      return {};
    }
  }

  const checkIfShip30Member = (uid) => {
    var ref = firebase
      .database()
      .ref('users/' + uid + '/meta')
      .child('connectedGroups');
    ref.once('value', function (snap) {
      if (snap.hasChild('ship30aug')) {
        setShip30(true);
      } else {
        setShip30(false);
      }
    });
  };

  async function getClientReferenceId() {
    return (
      (window.Rewardful && window.Rewardful.referral) ||
      'checkout_' + new Date().getTime()
    );
  }

  const goPro = () => {
    const trialDays = ship30 ? 35 : 30;

    if (userID) {
      const stripe = window.Stripe(STRIPE_PK);
      const planName = 'Pro';

      setButtonLoading('pro');
      // You'll have to define PRICE_ID as a price ID before this code block
      if (affiliate) {
        createProCheckoutSessionCoupon(
          PRO_PRICE,
          email,
          userID,
          planName,
          trialDays,
        )
          .then(function (data) {
            // Call Stripe.js method to redirect to the new Checkout page

            stripe
              .redirectToCheckout({
                sessionId: data.sessionId,
              })
              .then(setIsCheckingOut(false));
          })
          .catch(function (err) {
            problemToast('Something went wrong.');
            console.log(err);
            setButtonLoading(false);
          });
      } else {
        createProCheckoutSession(PRO_PRICE, email, userID, planName, trialDays)
          .then(function (data) {
            // Call Stripe.js method to redirect to the new Checkout page

            stripe
              .redirectToCheckout({
                sessionId: data.sessionId,
              })
              .then(setButtonLoading(false));
          })
          .catch(function (err) {
            problemToast('Something went wrong.');
            console.log(err);
            setButtonLoading(false);
          });
      }
    } else {
    }
  };

  const goProNoTrial = () => {
    if (userID) {
      const stripe = window.Stripe(STRIPE_PK);
      const planName = 'Pro';

      setButtonLoading('pro');
      // You'll have to define PRICE_ID as a price ID before this code block
      if (affiliate) {
        createProCheckoutSessionCoupon(PRO_PRICE, email, userID, planName)
          .then(function (data) {
            // Call Stripe.js method to redirect to the new Checkout page

            stripe
              .redirectToCheckout({
                sessionId: data.sessionId,
              })
              .then(setIsCheckingOut(false));
          })
          .catch(function (err) {
            problemToast('Something went wrong.');
            console.log(err);
            setButtonLoading(false);
          });
      } else {
        createProCheckoutSession(PRO_PRICE, email, userID, planName)
          .then(function (data) {
            // Call Stripe.js method to redirect to the new Checkout page

            stripe
              .redirectToCheckout({
                sessionId: data.sessionId,
              })
              .then(setButtonLoading(false));
          })
          .catch(function (err) {
            problemToast('Something went wrong.');
            console.log(err);
            setButtonLoading(false);
          });
      }
    } else {
    }
  };

  const goPremier = () => {
    if (userID) {
      const stripe = window.Stripe(STRIPE_PK);
      const planName = 'Premier';

      setButtonLoading('premier');
      // You'll have to define PRICE_ID as a price ID before this code block
      createPremierCheckoutSession(PREMIER_PRICE, email, userID, planName)
        .then(function (data) {
          // Call Stripe.js method to redirect to the new Checkout page

          stripe
            .redirectToCheckout({
              sessionId: data.sessionId,
            })
            .then(setButtonLoading(false));
        })
        .catch(function (err) {
          problemToast('Something went wrong.');
          console.log(err);
          setButtonLoading(false);
        });
    } else {
    }
  };

  const changeTo = (newProduct) => {
    setActiveProduct(newProduct);

    if (newProduct === 'Pro') {
      changePeriod('month');
    } else {
      changePeriod('year');
    }
  };

  async function openBillingPortalUpgrade(subscriptionID, newPriceID) {
    const returnLink = router.asPath;
    try {
      const { data } = await client.post(
        '/api/create-customer-portal-session',
        { customerID: customerID, returnLink: returnLink },
      );
      if (data.success === true) {
        router.push(
          (status !== 'canceled_trial' || status !== 'canceled_plan') &&
            subscriptionID
            ? `${data.url}/subscriptions/${subscriptionID}/preview/${newPriceID}`
            : `${data.url}`,
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  const premierUpgrade = () => {
    if (
      status !== 'churned_plan' &&
      status !== 'churned_trial' &&
      subscriptionID &&
      customerID
    ) {
      openBillingPortalUpgrade(subscriptionID, PREMIER_PRICE);
    } else {
      goPremier();
    }
  };

  const proUpgrade = () => {
    if (
      status !== 'churned_plan' &&
      status !== 'churned_trial' &&
      subscriptionID &&
      customerID
    ) {
      openBillingPortalUpgrade(subscriptionID, PRO_PRICE);
    } else {
      if (customerID) {
        goProNoTrial();
      } else {
        goPro();
      }
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={setIsOpen}
      >
        <Transition.Root
          initialFocus={cancelButtonRef}
          show={checkoutModal}
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={setCheckoutModal}
          >
            <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center font-sans antialiased sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:h-screen sm:align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block w-full transform overflow-hidden rounded-xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-md sm:align-middle">
                  {/*                           <div className='w-full sticky top-1 z-10'>
                                    <button ref={cancelButtonRef} onClick={() => setCheckoutModal(false)} className='bg-gray-100 focus:outline-none rounded-full p-2 absolute top-2 right-2 text-gray-500 hover:bg-gray-200 duration-200'>
                                        <XIcon className='w-5 h-5' />
                                    </button>
                                </div> */}
                  <Checkout
                    header={!proPlans.includes(productName) ? 'pro' : null}
                    checkoutComplete={() => checkoutComplete()}
                    status={status}
                    createSub={
                      proPlans.includes(productName)
                        ? false
                        : productName === 'Hobby'
                        ? false
                        : true
                    }
                    productName={productName}
                    currentPriceID={currentPriceID}
                    hasCanceledAddons={
                      hasCanceledAddons && proPlans.includes(productName)
                    }
                    inModal={true}
                    replaceItems={true}
                    subscriptionID={subscriptionID}
                    customerID={customerID}
                    cartItems={cartItems}
                    existingItems={[]}
                    billingDate={billingDate}
                    billingPeriod={newBillingPeriod}
                    checkout={checkout}
                    setCheckout={setCheckout}
                    setPassCalulating={setCalculating}
                  />
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="min-h-screen text-center sm:px-4">
          <Transition.Child
            show={isOpen}
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-bottom"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            show={isOpen}
            as={Fragment}
            enter="ease-out duration-300 transform"
            enterFrom="opacity-0 scale-90 translate-y-96"
            enterTo="opacity-100 scale-100 translate-y-0"
            leave="ease-in duration-300 transform"
            leaveFrom="opacity-100 scale-100 translate-y-0"
            leaveTo="opacity-0 translate-y-96 scale-90"
          >
            <div className="relative mt-16 inline-block w-full max-w-5xl transform rounded-t-3xl bg-gray-50 text-left align-bottom antialiased shadow-xl transition-all">
              <div className="sticky top-1 z-10 w-full">
                <button
                  onClick={() => closeModal()}
                  className="absolute top-2 right-2 rounded-full bg-gray-200 p-2 text-gray-500 duration-200 hover:bg-gray-300 focus:outline-none"
                >
                  <XIcon className="h-5 w-5" />
                </button>
              </div>

              <div className="flex flex-col space-y-8 p-4 pt-8 sm:p-8 md:p-12">
                <div className="flex flex-col items-center space-y-2">
                  <div className="flex flex-row items-center space-x-2">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-br from-blue-500 to-indigo-500 text-white">
                      <LightningBoltIcon className="h-5 w-5" />
                    </div>
                    <h1 className="text-2xl font-bold tracking-tight md:text-3xl md:tracking-tighter">
                      Upgrade Your Account
                    </h1>
                  </div>
                  <p className="text-center tracking-tightish text-gray-900">
                    Choose the plan that meets your needs. Change plans or
                    cancel anytime.
                  </p>
                </div>

                {status === 'canceled_plan' || status === 'canceled_trial' ? (
                  <AlertBanner
                    type={'warn'}
                    title={
                      <p>
                        You need to re-activate your subscription before
                        upgrading your account (you won't be charged yet).{' '}
                        <button
                          disabled={renewing}
                          onClick={() => renewPlan()}
                          className="font-semibold text-blue-500 underline hover:text-blue-600 disabled:text-gray-500"
                        >
                          Click here to reactivate now
                        </button>
                      </p>
                    }
                  />
                ) : null}

                <PricingPlans
                  ship30={ship30}
                  disable={
                    status === 'canceled_plan' || status === 'canceled_trial'
                  }
                  affiliate={affiliate}
                  pastCustomer={pastCustomer}
                  buttonLoading={buttonLoading}
                  showFree={false}
                  productName={productName}
                  premierClick={() => premierUpgrade()}
                  proClick={() => proUpgrade()}
                />

                <div
                  className={
                    'relative col-span-6 my-8 flex w-full items-center justify-center'
                  }
                >
                  <div
                    className={`absolute top-1/2 h-[2px] w-full -translate-y-1/2 transform bg-gradient-to-r from-gray-50 via-gray-300 to-gray-50`}
                  />
                  <div
                    className={
                      'z-10 flex flex-row items-center justify-center bg-gray-50 px-4 font-sans'
                    }
                  >
                    <p className="text-base font-medium uppercase tracking-tightish text-gray-700">
                      View Features
                    </p>
                  </div>
                </div>

                <div className="my-8 grid grid-cols-1 gap-0 overflow-hidden rounded-2xl sm:grid-cols-3">
                  <div className="col-span-1 flex flex-col items-start border-gray-100 p-6 duration-150  hover:bg-blue-500 hover:bg-opacity-5 sm:border-b">
                    <div className="mb-3 flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-b from-blue-400 to-blue-500">
                      <ChartBarIcon className="h-5 w-5 text-white" />
                    </div>
                    <p className="mb-1  font-semibold">Analytics</p>
                    <p className="text-left text-sm text-gray-500">
                      Grow your audience with Analytics built for Digital
                      Writers.
                    </p>
                  </div>

                  <div className="col-span-1 flex flex-col items-start border-gray-100 p-6 duration-150 hover:bg-orange-500 hover:bg-opacity-5 sm:border-b sm:border-l  md:border-r">
                    <div className="mb-3 flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-b from-orange-400 to-orange-500">
                      <DocumentTextIcon className="h-5 w-5 text-white" />
                    </div>
                    <p className="mb-1  font-semibold">Templates</p>
                    <p className="text-left text-sm text-gray-500">
                      Beat writers block with Digital Writing templates.
                    </p>
                  </div>

                  <div className="col-span-1 flex flex-col items-start border-gray-100 p-6 duration-150 hover:bg-yellow-500 hover:bg-opacity-5  sm:border-b">
                    <div className="mb-3 flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-b from-yellow-400 to-yellow-500">
                      <GlobeAltIcon className="h-5 w-5 text-white" />
                    </div>
                    <p className="mb-1  font-semibold">Unlimited Blog Cards</p>
                    <p className="text-left text-sm text-gray-500">
                      Remove the limit on the # of Social Blog cards you can
                      have.
                    </p>
                  </div>

                  <div className="col-span-1 flex flex-col items-start border-l-0 border-b-0 border-gray-100  p-6 duration-150 hover:bg-green-500 hover:bg-opacity-5 sm:border-l sm:border-b md:border-l-0 md:border-b-0">
                    <div className="mb-3 flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-b from-green-400 to-green-500">
                      <CollectionIcon className="h-5 w-5 text-white" />
                    </div>
                    <p className="mb-1  font-semibold">Unlimited Collections</p>
                    <p className="text-left text-sm text-gray-500">
                      Remove the limit on the # of Collections you can create.
                    </p>
                  </div>

                  <div className="col-span-1 flex flex-col items-start p-6 duration-150 hover:bg-indigo-500 hover:bg-opacity-5 sm:border-gray-100 md:border-r md:border-l">
                    <div className="mb-3 flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-b from-indigo-400 to-indigo-500">
                      <LightningBoltIcon className="h-5 w-5 text-white" />
                    </div>
                    <p className="mb-1  font-semibold">Custom Themes</p>
                    <p className="text-left text-sm text-gray-500">
                      Get access to premium themes and even create your own!
                    </p>
                  </div>

                  <div className="col-span-1 flex flex-col items-start border-0 border-gray-100 p-6 duration-150 hover:bg-red-500 hover:bg-opacity-5 sm:border-l md:border-0">
                    <div className="mb-3 flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-b from-red-400 to-red-500">
                      <CashIcon className="h-5 w-5 text-white" />
                    </div>
                    <p className="mb-1  font-semibold">Affiliate Program</p>
                    <p className="text-left text-sm text-gray-500">
                      Become a Typeshare affiliate to earn a 30% monthly
                      commission!
                    </p>
                  </div>
                </div>

                <div className="mx-auto flex flex-row items-center justify-center space-x-3 text-gray-300 duration-1000 hover:text-blue-500">
                  <TSSimple className={'h-6 w-6 '} />
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
